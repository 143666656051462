import React, { Component } from "react";
import PropTypes from "prop-types";
import Chart  from 'react-apexcharts'
import i18n from "../../i18n";
import { formatNumber } from "./Class/FormatNumber";

class ChartForm extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    data: PropTypes.any,
    chart: PropTypes.bool, 
  };

  static defaultProps = {
    isProcessing: false,
    data: [],
    chart:false,

  };
  constructor(props) {
    super(props);
    this.state = {
      dataSeries :[],
      dataSeriesSales:[],
      dataSeriesSelling:[44, 55, 41, 17, 15],
      detail : [],
      range:[],
      label:[],
      dataList:[],
      expandAll: false,
      data: this.props.data,
      link: null,
      columns: this.props.column,
      tools: this.props.tools,
      filterSearch: this.props.filterSearch,
      search: {},
      page: null,
      expandTr: [],
      currentPage: 1,
      totalData: 0,
      sort:null,
      first:0
    };
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
  
  }


  _onBlur() {
    this.state.search["page"] = 1;
    this.runSearch();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.state.search["page"] = 1;
      this.runSearch();
    }
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.state[key] = value;
    if (value != null) {
      value = moment(value).format("YYYY-MM-DD");
    }
    this.state.search[key] = value;
    this.runSearch();
  }

  handlerDateTimeBetween(e,key) {
    var value = e;
    var date = [];
    console.log('ket : ',value.length)
    value.map((item,i)=>{
      if (item != null) {
        date.push(moment(item).format("YYYY-MM-DD"));
        if(i != 0){
          this.state.search[key] = date;
          this.runSearch();
        }
      }else if(i==0&&item==null){
        this.state.search[key] = [];
        this.runSearch();
      }
      
    })
  }


  renderChartBar(data){
    if(data != null && data != undefined){
      this.state.dataSeries = [{
          name : "Daily Sales",
          data : data.chart
      }];
      this.state.range = data.date;
    }

      const options = {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: {
            show: false, // Hides the toolbar (menu and buttons)
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 5,
            borderRadiusApplication: 'end'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
       
        legend:{
            show :false
        },
        xaxis: {
            categories: this.state.range != null?this.state.range:[],
            // labels: {
            //   show: false,
            // },
            // axisBorder: {
            //   show: false, // Hides the border line
            // },
            // axisTicks: {
            //   show: false, // Hides the ticks
            // },
          },
          yaxis: {
            labels: {
              show: false,
              formatter: function (value) {
                return formatNumber(value)
                 
              } // Hides the labels // Hides the labels
            },
            axisBorder: {
              show: false, // Hides the border line
            },
            axisTicks: {
              show: false, // Hides the ticks
            },
          },
          grid: {
            show: false, // Optional: Hides the grid lines
          },
        fill: {
          opacity: 1
        },
      };

      return (
          <div>
            <div id="chart">
              <Chart options={options} series={this.state.dataSeries!=null?this.state.dataSeries:[]} type="bar" height={150} />
            </div>
          </div>
        );
    
  }

  renderChartArea(data,height){
      if(data != null && data != undefined){
        this.state.dataSeriesSales = [{
            name : i18n.t(data.hover),
            data : data.chart
        }];
        this.state.range = data.date;
      }
      console.log(height)
      var options = {
        chart: {
          // type: 'bar',
          // height: "300px",
          toolbar: {
            show: false, // Hides the toolbar (menu and buttons)
          },
        },
        
        plotOptions: {

        },
        // legend: {
        //     show: false
        // },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 80, 100]
            }
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3,
            colors: ["#47be7d"],
        },
        xaxis: {
            categories:this.state.range ,
            // categories: ['', 'Apr 02', 'Apr 03', 'Apr 04', 'Apr 05', 'Apr 06', 'Apr 07', 'Apr 08', 'Apr 09', 'Apr 10', 'Apr 11', 'Apr 12', 'Apr 13', 'Apr 14', 'Apr 15', 'Apr 16', 'Apr 17', 'Apr 18', ''],

            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            tickAmount: 6,
            labels: {
                rotate: 0,
                rotateAlways: true,
                style: {
                    colors: "#B5B5C3",
                    fontSize: '12px'
                }
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    color: "#47be7d",
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px'
                }
            }
        },
        yaxis: {
            tickAmount: 4,
            // max: 24,
                min: 0,
            labels: {
                style: {
                    colors: "#B5B5C3",
                    fontSize: '12px'
                },
                formatter: function (val) {
                  const value = val/1000;

                  if(val == 0){
                    return 0;
                  }else if(val < 100000){
                    // value = value * 1000;
                   return val; 
                  }else{
                    return formatNumber(value) + 'K'
                  }
              }
            }
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        toolbar:{
          show:false
        },
        tooltip: {
            style: {
                fontSize: '12px'
            },
            y: {
                formatter: function (val) {
                    return formatNumber(val) 
                }
            }
        },
        colors: ["#47be7d"],
        grid: {
            borderColor: "#B5B5C3",
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            strokeColor: ["#47be7d"],
            strokeWidth: 3
        }
    };
      return (
          <div>
            <div id="chart-area">
              <Chart options={options} series={this.state.dataSeriesSales!=null?this.state.dataSeriesSales:[]} type="area" height={height}/>
            </div>
          </div>
        );
    
  }

  renderChartDonut(data){
    let arr = [];

    if(data != null && data != undefined){
      console.log('masuklah',data)
      data.revenue.map((item,i) => {
        arr.push((item));
      })
      console.log(arr)
      this.state.dataSeriesSelling = arr
      this.state.label = data.name
    }
    const options = {
        chart: {
          // width: 380,
          type: 'donut',
        },
        labels:this.state.label,
        plotOptions: {
          pie: {
            donut: {
              size: '70%',
            },
            startAngle: -90,
            endAngle: 270
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (val, opts) {
            return formatNumber(opts.w.globals.series[opts.seriesIndex]); // Show the raw value
          },
          // forceShow: true
        },
        fill: {
          type: 'gradient',
        },
        noData: {
          text: 'Tidak Ada Penjualan', // Custom message for empty charts
          align: 'center',
          verticalAlign: 'middle',
          style: {
              color: '#000',
              fontSize: '14px'
          }
        },
        legend: {
          formatter: function(val, opts) {
            return val + " - Rp. " + formatNumber(opts.w.globals.series[opts.seriesIndex])
            // return val;
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return 'Rp. ' + formatNumber(val); // Customize tooltip label
            },
          },
        },
        // title: {
        //   text: 'Gradient Donut with custom Start-angle'
        // },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
    }
        
    return (
      <div>
        <div id="chart">
            <Chart options={options} series={this.state.dataSeriesSelling} type="donut" width={350} height={300}/>
          </div>
        <div id="html-dist"></div>
      </div>
    );
      
  }

  render() {
    const {dataList,height,type} = this.props;

    if(dataList != null && type != null){
        this.state.type = type;
        this.state.dataList = dataList;
    }
   
    console.log(this.props)
    
    switch(this.state.type){
        case 'bar' :
            return  (this.renderChartBar(this.state.dataList));
        break;
        case 'line':
            return '2';
        break;
        case 'area':
            return  (this.renderChartArea(this.state.dataList,height));
        break;
        case 'donut':
            return  (this.renderChartDonut(this.state.dataList));
        break;
    }
    
  }
}

export default ChartForm;
