import { useParams } from "react-router-dom";
import React from "react";
import SellingViewContainer from "../../../containers/modules/sales/selling/view";
export const ViewSelling = (props)=>{
    let params=useParams();
    let id=params.id;
    document.getElementById("root").setAttribute("style","overflow:hidden");
    console.log(document)
    return (
        <SellingViewContainer id={id}/>
        
    )
}

export default ViewSelling;