/*
    Color Avaiable
    - success
    - warning
    - info
    - primary
    - secondary
    - light
    - dark
*/

import i18n from "../../../i18n";

export const transactionCode = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 1,
    label: i18n.t("Waiting For Approval"),
    color: "warning",
  },
  {
    key: 2,
    label: i18n.t("Procurement"),
    color: "success",
  },
  {
    key: 3,
    label: i18n.t("PR Rejected"),
    color: "danger",
  },
  {
    key: 4,
    label: i18n.t("Waiting Central Approval"),
    color: "warning",
  },
  {
    key: 5,
    label: i18n.t("Waiting Receiving Item"),
    color: "info",
  },
  {
    key: 6,
    label: i18n.t("PO Rejected"),
    color: "danger",
  },
  {
    key: 7,
    label: i18n.t("Waiting For Payment"),
    color: "info",
  },
  {
    key: 8,
    label: i18n.t("Done"),
    color: "success",
  },
  {
    key: 9,
    label: i18n.t("Warehouse Transfer"),
    color: "info",
  },
  {
    key: 10,
    label: i18n.t("PO Closed"),
    color: "danger",
  },
];

export const typeCoa4 = [
  {
    key: "",
    label: i18n.t("All"),
  },
  {
    key: "Customer",
    label: i18n.t("Customer"),
  },
  {
    key: "Supplier",
    label: i18n.t("Supplier"),
  },
  {
    key: "Item",
    label: i18n.t("Item"),
  },
];

export const transactionCodePO = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 11,
    label: i18n.t("Select All"),
  },
  {
    key: 1,
    label: i18n.t("Waiting For Approval"),
    color: "warning",
  },
  {
    key: 2,
    label: i18n.t("Procurement"),
    color: "success",
  },
  {
    key: 3,
    label: i18n.t("PR Rejected"),
    color: "danger",
  },
  {
    key: 4,
    label: i18n.t("Waiting Central Approval"),
    color: "warning",
  },
  {
    key: 5,
    label: i18n.t("Waiting Receiving Item"),
    color: "info",
  },
  {
    key: 6,
    label: i18n.t("PO Rejected"),
    color: "danger",
  },
  {
    key: 7,
    label: i18n.t("Waiting For Payment"),
    color: "info",
  },
  {
    key: 8,
    label: i18n.t("Done"),
    color: "success",
  },
  {
    key: 9,
    label: i18n.t("Warehouse Transfer"),
    color: "info",
  },
  {
    key: 10,
    label: i18n.t("PO Closed"),
    color: "danger",
  },
];

export const transactionWH = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 0,
    label: i18n.t("Waiting HO Approval"),
    color: "warning",
  },
  {
    key: 1,
    label: i18n.t("Waiting For GR"),
    color: "info",
  },
  {
    key: 2,
    label: i18n.t("Done"),
    color: "success",
  },
  {
    key: 3,
    label: i18n.t("Canceled"),
    color: "danger",
  },
];

export const transactionSelling = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 1,
    label: i18n.t("Waiting For Payment"),
    color: "info",
  },
  {
    key: 2,
    label: i18n.t("Done"),
    color: "success",
  },
  {
    key: 0,
    label: i18n.t("Void"),
    color: "danger",
  },
  {
    key: 3,
    label: i18n.t("Billing"),
    color: "info",
  },
  {
    key: 4,
    label: i18n.t("Temporary"),
    color: "info",
  },
  {
    key: 5,
    label: i18n.t("Settlement"),
    color: "success",
  },
];

export const paymentType = [
  {
    key: "",
    label: i18n.t("Select Payment Type"),
  },
  {
    key: "Credit",
    label: i18n.t("Credit"),
    color: "info",
  },
  {
    key: "Cash",
    label: i18n.t("Cash"),
    color: "info",
  },
  {
    key: "BG",
    label: i18n.t("BG"),
    color: "info",
  },
];

export const customerApproval = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 1,
    label: i18n.t("Waiting for Approval"),
    color: "info",
  },
  {
    key: 2,
    label: i18n.t("Approved"),
    color: "success",
  },
  {
    key: 3,
    label: i18n.t("Rejected"),
    color: "danger",
  },
];

export const Approval = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 1,
    label: i18n.t("Waiting for Approval"),
    color: "info",
  },
  {
    key: 2,
    label: i18n.t("Approved"),
    color: "success",
  },
  {
    key: 3,
    label: i18n.t("Rejected"),
    color: "danger",
  },
];

export const logApproval = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 1,
    label: i18n.t("Waiting for Approval"),
    color: "info",
  },
  {
    key: 2,
    label: i18n.t("Approve"),
    color: "success",
  },
  {
    key: 3,
    label: i18n.t("Close"),
    color: "success",
  },
  {
    key: 0,
    label: i18n.t("Rejected"),
    color: "danger",
  },
];

export const transactionSales = [
  {
    key: "",
    label: i18n.t("Select Status"),
  },
  {
    key: 1,
    label: i18n.t("Active"),
    color: "success",
  },
  {
    key: 2,
    label: i18n.t("Delivery Stage"),
    color: "info",
  },
  {
    key: 3,
    label: i18n.t("On Process"),
    color: "info",
  },
  {
    key: 4,
    label: i18n.t("Cancel"),
    color: "danger",
  },
];

  export const transactionBilling = [
    {
      key: "",
      label: i18n.t("Select Status"),
    },
    {
      key: 1,
      label: i18n.t("Active"),
      color: "success",
    },
    {
      key: 2,
      label: i18n.t("Done"),
      color: "info",
    },
    {
      key: 0,
      label: i18n.t("Close"),
      color: "danger",
    },
  ];

  export const transactionBG = [
    {
      key: "",
      label: i18n.t("Select Status"),
    },
    {
      key: 1,
      label: i18n.t("Active"),
      color: "success",
    },
    {
      key: 2,
      label: i18n.t("Done"),
      color: "info",
    },
    {
      key: 3,
      label: i18n.t("Canceled"),
      color: "danger",
    },
    {
      key: 0,
      label: i18n.t("Not Paid"),
      color: "danger",
    },
  ];

    export const takingGoods = [
      {
        key: "",
        label: i18n.t("Select Status"),
      },
      {
        key: 1,
        label: i18n.t("Waiting For Approval"),
        color: "success",
      },
      {
        key: 2,
        label: i18n.t("Active"),
        color: "info",
      },
      {
        key: 3,
        label: i18n.t("Rejected"),
        color: "danger",
      },
      {
        key: 4,
        label: i18n.t("Done"),
        color: "danger",
      },
    ];


    export const stockReport = [
      {
        key: "",
        label: i18n.t("Select Status"),
      },
      {
        key: 3,
        label: i18n.t("In Stock"),
        color: "success",
      },
      {
        key: 2,
        label: i18n.t("Low Stock"),
        color: "info",
      },
      // {
      //   key: 1,
      //   label: i18n.t("Out Of Stock"),
      //   color: "danger",
      // },
    ];

      export const transactionTypeDP = [
        {
          key: "",
          label: i18n.t("Select Status"),
        },
        {
          key: '1',
          label: i18n.t("Active"),
          // color: "",
        },
        {
          key: '0',
          label: i18n.t("Void"),
          // color: "success",
        },

];
