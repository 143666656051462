import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import PhotoGallery from "../../../utilities/PhotoGallery";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
  }
}

render() {
    const {data}=this.props
    return (
      <form>
       {(data!=null)? <React.Fragment>
        <div className="row">
            <div className="col-12">
              <Table >
                <thead>
                </thead>
                <tbody>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Customer Code")}</td>
                  <td>:</td><td>{(data.code)?data.code:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Branch Name")}</td>
                  <td>:</td><td>{(data.branch_name)?data.branch_name:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Store Name")}</td>
                  <td>:</td><td>{(data.store)?data.store:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Customer Type")}</td>
                  <td>:</td><td>{(data.type)?data.type:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Payment Type")}</td>
                  <td>:</td><td>{(data.paymentType)?data.paymentType:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Address")}</td>
                  <td>:</td><td>{(data.address)?data.address:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Province")}</td>
                  <td>:</td><td>{(data.province)?data.province:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("City")}</td>
                  <td>:</td><td>{(data.city)?data.city:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("District")}</td>
                  <td>:</td><td>{(data.district)?data.district:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Sub District")}</td>
                  <td>:</td><td>{(data.sub_district)?data.sub_district:'-'}</td></tr>
                  {(data.paymentType == 'Credit')?
                  <Fragment>
                   <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("TOP")}</td>
                   <td>:</td><td>{(data.top)?data.top:'-'}{' '}{data.top_label}</td></tr>
                   <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Invoice Limit")}</td>
                   <td>:</td><td>{(data.invoice_limit)?data.invoice_limit:'-'}{' '}{data.invoice_label}</td></tr>
                   <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Credit Limit")}</td>
                   <td>:</td><td>{(data.credit_limit_format)}</td></tr>
                  </Fragment>
                   :''}
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Phone")}</td>
                  <td>:</td><td>{(data.phone)?data.phone:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("ID No.")}</td>
                  <td>:</td><td>{(data.id_number)?data.id_number:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("E-Mail")}</td>
                  <td>:</td><td>{(data.email)?data.email:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Contact Person")}</td>
                  <td>:</td><td>{(data.contact_person)?data.contact_person:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Contact Person Phone")}</td>
                  <td>:</td><td>{(data.contact_person_phone)?data.contact_person_phone:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Status Tax")}</td>
                  <td>:</td><td>{(data.tax_status == 0)?'NON-PKP':'PKP'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("NPWP")}</td>
                  <td>:</td><td>{(data.npwp)?data.npwp:'-'}</td></tr>
                  {data.image.length > 0 ? (
                      <tr style={{ fontSize: "15px", fontWeight: "bold" }}>
                        <td>{i18n.t("Image")}</td>
                        <td>:</td>
                        <td>
                          <PhotoGallery images={data.image}></PhotoGallery>
                        </td>
                      </tr>
                    ) : null}
                </tbody>
              </Table>
            </div>
          </div>
          <div className="row">
            <div className="d-flex flex-end">
              <div className="col-4">
              </div>
            </div>
          </div>
       </React.Fragment> :''}
      </form>
    );
  }
}

export default Form;
