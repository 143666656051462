import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button,  Collapse,  Table } from "react-bootstrap";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import InputCustom from "../../../utilities/Form/InputCustom";

class TableSettlementSales extends Component {
  static propTypes = {
    data: PropTypes.any,
    expandAble: PropTypes.bool,
    checkAble: PropTypes.bool,
    numberAble: PropTypes.bool,
    customExpand: PropTypes.bool,
    componentExpand: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    expandAble: false,
    checkAble: false,
    numberAble: true,
    customExpand: false,
    componentExpand: () => {
      <div></div>;
  }
}
  constructor(props) {
    super(props);
    this.state = {
      expandTr: [],
      expandAll: false,
      data: [],
      link: null,
      checkTd:[],
      columns: [],
      search: {
        limit: 10,
      },
      fulldata: {
        branch: "",
      },
      page: null,
      currentPage: 1,
      totalData: 0,
      load: 0,
      isProcessing: false,
    };
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.expandAll = this.expandAll.bind(this);

  }

  runSearch() {
    let data = this.state.search;
    let url = process.env.REST_URL + "stock-card/search";
    this.setState({ isProcessing: true });
    const token = cookieManager.getUserToken();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: url,
      responseType: "json",
      data: data,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          var detail = response.data.result;
          const arr = {
            link: detail.links,
            data: detail.data,
            currentPage: detail.current_page,
            totalData: detail.total,
            page: detail.current_page,
            isProcessing: false,
          };
          this.setState(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  tdNumber(dataRow, i) {
    const { data } = this.props;
    let page = 1;
    let limit = 10;
    if (data.current_page != undefined) {
      page = data.current_page;
    }
    if (this.state.search.limit != undefined) {
      if (this.state.search.limit != -1) {
        limit = this.state.search.limit;
      } else {
        limit = 1;
      }
    }
    let plus = 0;
    if (page > 1) {
      plus = limit * (page - 1);
    }
    return <td  style={{paddingLeft:'10px'}} key={i.toString()}>{i + 1 + plus}</td>;
  }
  updateSearch(value, key) {
    if (value == "") {
      value = null;
    }
    this.state.search[key] = value;
  }

  _onBlur() {
    this.state.search["page"] = 1;
    this.runSearch();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.state.search["page"] = 1;
      this.runSearch();
    }
  }
  changePage(event, url,i) {
    if (url != null && this.state.search.limit != -1) {
      var urlParams = new URLSearchParams(url);
      this.state.search["page"] = i;

      this.runSearch();
    }
  }
  changeLimit(event) {
    if (event.target.value == -1) {
      this.state.search["page"] = 1;
    } else {
      var pageAvailable = this.state.totalData / event.target.value;
      if (this.state.search["page"] > pageAvailable) {
        this.state.search["page"] = Math.ceil(pageAvailable);
      }
    }
    this.state.search["limit"] = event.target.value;
    this.runSearch();
  }
  changeSelect(e, name) {
    let value = e.target.value;

    if (value == "") {
      value = null;
    }
    this.state.search[name] = value;
    this.runSearch();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;

    if (value != null) {
      value = moment(value).format("YYYY-MM-DD");
    }
    this.state.search[key] = value;
    this.setState({ [key]: e }, () => this.runSearch());
  }
  renderTd(column, data, i = 0) {
    var value = data[column.key];
    var type = column.type;
    switch (type) {
      case "text":
        return <td key={i.toString()}>{value}</td>;
      case "numberTd":
        return this.tdNumber(value, i);
      case "expand":
        return this.tdExpand(data, i);
      case "check":
        return this.tdCheck(i);  
      default:
        return <td key={i.toString()}>{value}</td>;
    }
  }

  tdCheck(i) {
    const { data } = this.props;
    let detail = []
    if (data.data != undefined) {
       detail = data.data
    }
    return (
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid" style={{paddingRight:'10px'}}>
        <InputCustom
          type={"checkbox"}
          className={
              "form-check-input form-check form-check-custom form-check-solid border-check"
          }
          id={"settle"+i}
          value={detail[i].check}
          checked={detail[i].check}
          name={"settle"}
          labelfield={"false"}
          onChange={async (e) => {
             await this.checkOne(e,i,'check');
          }
      }
      />
          {/* <input
            onChange={(e) => {
              this.checkOne(e,i);
            }}
            className="form-check-input"
            type="checkbox"
            checked={detail[i].check}

          /> */}
        </div>
      </td>
    );
  }

  tdExpand(i) {
    return (
      <td className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <Button
            variant="none"
            onClick={(e) => this.setExpand(i)}
            aria-controls={"table" + i}
            aria-expanded={this.state.expandTr[i]}
          >
            {" "}
            {this.state.expandTr[i] ? "-" : "+"}
          </Button>
        </div>
      </td>
    );
  }
  expandAll() {
    const { data } = this.props;
    if (data.data != undefined) {
      let obj = {};
      let all = !this.state.expandAll;
      for (var i = 0; i < data.data.length; i++) {
        let value = data.data[i].id;
        obj = Object.assign(obj, { [value]: all });
      }
      this.setState({ expandTr: obj, expandAll: all });
    }
  }

  checkOne(e,i,key){
    const { data } = this.props;
    if (data.data != undefined) {
      const arr = data.data;
      const objectss = arr[i];
      objectss[key] = !objectss[key];
      this.setState(arr)

    }
  }

  checkAll() {
    const { data } = this.props;
    if (data.data != undefined) {
      // let obj = {};
      // let all = !this.state.expandAll;
      for (var i = 0; i < data.data.length; i++) {
        
      //   let value = data.data[i].id;
      //   obj = Object.assign(obj, { [value]: all });
      }
      // this.setState({ expandTr: obj, expandAll: all });
    }
  }
  setExpand(i) {
    this.setState({
      ...this.state,
      expandTr: {
        ...this.state.expandTr,
        [i]: !this.state.expandTr[i],
      },
    });
  }

  renderExpand() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <Button
            variant="none"
            onClick={() => this.expandAll()}
            className="btn btn-none pt-0 pb-0"
          >
            {" "}
            {this.state.expandAll ? "-" : "+"}
          </Button>
        </div>
      </th>
    );
  }

  renderTrExpand(data, column, totalColumn) {
    const { checkAble, numberAble, customExpand, componentExpand } = this.props;
    const dataExpand = customExpand ? data : data[column.key];
    const columnExpand =  customExpand ? null : column.columns;
    if (dataExpand != undefined) {
      return (
        <tr
          key={"expand" + data.id}
          className={!this.state.expandTr[data["id"]] ? "d-none" : ""}
        >
          {numberAble ? <td></td> : ""}
          <td colSpan={totalColumn + 1 + (checkAble ? 1 : 0)}>
            <Collapse
              key={"Collapse" + data["id"]}
              in={this.state.expandTr[data["id"]]}
            >
              {!customExpand ? (
                <div id={"table" + data["id"]}>
                  <table className="table table-responsive table-row-dashed table-row-gray-500 gy-5 gs-5 mb-0">
                    <thead>
                      <tr className="fw-semibold fs-6 text-gray-800 bg-aquamarine">
                        {columnExpand.map((header, i) => (
                          <th
                            key={"expandHeader" + i}
                            scope="col"
                            className={
                              header.thClass != undefined
                                ? header.thClass
                                : null
                            }
                          >
                            {header.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataExpand.map((data, i) => (
                        <tr key={"expandBody" + i}>
                          {columnExpand.map((fill, i) => {
                            if (i == 0) {
                              return (
                                <td key={"expandFill" + i} scope="row">
                                  {data[fill.key]?? i18n.t("Not Have Band Number")}
                                </td>
                              );
                            } else {
                              if (fill.type == "action") {
                                return (
                                  <td
                                    key={"expandFill" + i}
                                    className={
                                      fill.tdClass != undefined
                                        ? fill.tdClass
                                        : null
                                    }
                                  >
                                    {fill.renderFunc(data[fill.key])}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={"expandFill" + i}
                                    className={
                                      fill.tdClass != undefined
                                        ? fill.tdClass
                                        : null
                                    }
                                  >
                                    {data[fill.key]}
                                  </td>
                                );
                              }
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                componentExpand(data)
              )}
              {/* </Fade> */}
            </Collapse>
          </td>
        </tr>
      );
    }
  }

  renderFilter(column, filter) {
    if (filter.active) {
      switch (filter.type) {
        case "Text":
          return (
            <input
              onKeyPress={this._onEnter}
              onBlur={this._onBlur}
              onChange={(e) => {
                this.updateSearch(e.target.value, column.key);
              }}
              type="text"
              className="form-control form-control-solid form-control-sm"
              placeholder={column.title}
              name={column.key}
            />
          );
          break;
        case "Select":
          return (
            <select
              onChange={(e) => this.changeSelect(e, column.key)}
              className="form-select form-select-sm form-select-solid"
            >
              {filter.data.map((list, i) => (
                <option key={column.key + i} value={list.key}>
                  {list.label}
                </option>
              ))}
            </select>
          );
          break;
        case "Date":
          return (
            <DatePickerCustom
              name={column.key}
              title={column.title}
              required={false}
              selected={this.state[column.key]}
              onChange={(e) => this.handlerDateTime(e, column.key)}
              startDay={0}
              isClearable={this.state[column.key] != null ? true : false}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm form-control-solid"
              labelfield={"false"}
            />
          );
          break;
      }
    } else {
      return "";
    }
  }
  renderCheck() {
    return (
      <th className="w-20px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          {/* <input
            onChange={(e) => {
              this.checkAll();
            }}
            className="form-check-input"
            type="checkbox"
            value="1"
          /> */}
        </div>
      </th>
    );
  }
  renderNumber() {
    return (
      <th className="w-10px pe-5">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3" style={{paddingLeft:'10px'}}>
              #
        </div>
      </th>
    );
  }
  resetTable() {
    const { data, columns } = this.props;
    var detail = data;
    const arr = {
      link: detail.links,
      data: detail.data,
      fulldata: data,
      search: data,
      currentPage: detail.current_page,
      totalData: detail.total,
      page: detail.current_page,
      columns: columns,
    };
    this.setState(arr);
  }
  renderTitle() {
    return (
      <React.Fragment>
        <Table borderless>
          <thead></thead>
          <tbody>
            <tr style={{fontWeight:'bold',fontSize:'16px'}}>
              <td>{i18n.t("Item Name")}</td>
              <td> : </td>
              <td>
                {this.state.fulldata.name_item != null &&
                this.state.fulldata.name_item != undefined
                  ? this.state.fulldata.name_item
                  : ""}
              </td>
              <td colSpan={4}></td>
            </tr>
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
  render() {
    const { data, columns, expandAble, theExpand, checkAble  } = this.props;
    if (data != undefined ) {
      var detail = data;
      this.state.link = detail.links;
      this.state.data = detail.data;
      this.state.fulldata = data;
      this.state.search = data;
      this.state.currentPage = detail.current_page;
      this.state.totalData = detail.total;
      this.state.page = detail.current_page;
      this.state.columns = columns;
      this.state.search.limit = 10;
      this.state.load = 1;
    }
    return (
      <React.Fragment>
        <div className="card mt-4">
          {/* <div className="card-header border-0 pt-3">
            <div className="d-flex align-items-center position-relative my-1">
              {this.renderTitle()}
            </div>
            <div className="card-toolbar align-items-end">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <Button
                  type="button"
                  className="btn btn-warning m-1"
                  onClick={() => this.resetTable()}
                >
                  <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                </Button>
                <Button type="button" className="btn btn-primary m-1">
                  {i18n.t("Print")}
                </Button>
              </div>
            </div>
          </div> */}
          <div className="card-body pt-0">
            <div className={this.state.isProcessing ? " table-loading" : ""}>
              {this.state.isProcessing ? (
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              ) : (
                ""
              )}

              <table className="table align-middle table-row-dashed table-striped fs-6 gy-5">
                <thead>
                  <tr 
                    key={"head"}
                    className="text-start fw-bold fs-12 text-uppercase gs-0 table-primary"
                  >
                    {this.renderNumber()}
                    {expandAble ? this.renderExpand() : ""}

                    {this.state.columns.map((column, i) => (
                      <th key={i.toString()} className={column.tHclass}>
                        {column.title}
                      </th>
                    ))}
                    {checkAble ? this.renderCheck() : ""}

                  </tr>
                  {
                    <tr key={"filter"}>
                      {expandAble ? <th></th> : ""}
                      {<th></th>}
                      {this.state.columns.map((column, i) => (
                        <th key={i.toString()} className={column.tHclass}>
                          {this.renderFilter(column, column.filter)}
                        </th>
                      ))}
                       {checkAble ? <th></th> : ""}
                    </tr>
                  }
                </thead>
                <tbody className="fw-semibold text-gray-600">
                  {this.state.data != undefined &&
                  this.state.data.length > 0 ? (
                    this.state.data.map((data, p) => (
                      <React.Fragment key={"empty" + p}>
                        <tr key={p} >
                          {this.renderTd({ type: "numberTd" }, data["id"], p)}
                          {expandAble && data != undefined
                            ? this.renderTd({ type: "expand" }, data["id"], p)
                            : ""}
                          {this.state.columns.map((column, i) =>
                            this.renderTd(column, data, i)
                          )}
                          {checkAble
                          ? this.renderTd({ type: "check" }, data["id"], p)
                          : ""}
                        </tr>
                        {expandAble && data != undefined
                          ? this.renderTrExpand(
                              data,
                              theExpand,
                              this.state.columns.length
                            )
                          : ""}

                      </React.Fragment>
                    ))
                  ) : (
                    <tr key={"notfound"}>
                      <td
                        className="text-center"
                        colSpan={
                          this.state.columns.length > 0
                            ? this.state.columns.length + 1 +  (expandAble ? 1 : 0) 
                            : 1
                        }
                      >
                        {i18n.t("No Data Found")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div className="dataTables_length">
                    <label>
                      <select
                        onChange={(e) => this.changeLimit(e)}
                        name="kt_customers_table_length"
                        aria-controls="kt_customers_table"
                        className="form-select form-select-sm form-select-solid"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="-1">{i18n.t("All")}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul className="pagination">
                      {this.state.link != null
                        ? this.state.link.map((data, i) => (
                            <li
                              className={
                                "paginate_button page-item" +
                                (i == 0 ? " previous" : "") +
                                (i == this.state.link.length - 1
                                  ? " next"
                                  : "") +
                                (data.url == null ? " disabled" : "") +
                                (data.active ? " active" : "")
                              }
                              key={i}
                            >
                              <button
                                key={i}
                                onClick={(e) => this.changePage(e, data.url,i)}
                                aria-controls="kt_customers_table"
                                data-dt-idx={i}
                                tabIndex="0"
                                className="page-link"
                              >
                                {i == 0 ? <i className="previous"></i> : ""}
                                {i != 0 && i != this.state.link.length - 1
                                  ? data["label"]
                                  : ""}
                                {i == this.state.link.length - 1 ? (
                                  <i className="next"></i>
                                ) : (
                                  ""
                                )}
                              </button>
                            </li>
                          ))
                        : ""}
                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TableSettlementSales;
