
import React from "react";
import i18n from "../../../i18n";

export default function SectionRecentOrders(props){
    const arr = props.data;
    return (
        <div className="col-xl-6 mb-xl-10">

        <div className="card h-md-100">

            <div className="card-header align-items-center border-0">

                <h3 className="fw-bold text-gray-900 m-0">{i18n.t("Recent Orders")}</h3>
                <button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                    <i className="ki-duotone ki-dots-square fs-1">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </i>
                </button>

                {/* <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

                    <div className="menu-item px-3">
                        <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
                    </div>


                    <div className="separator mb-3 opacity-75"></div>


                    <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">New Ticket</a>
                    </div>


                    <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">New Customer</a>
                    </div>


                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

                        <a href="#" className="menu-link px-3">
                            <span className="menu-title">New Group</span>
                            <span className="menu-arrow"></span>
                        </a>


                        <div className="menu-sub menu-sub-dropdown w-175px py-4">

                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">Admin Group</a>
                            </div>


                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">Staff Group</a>
                            </div>


                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">Member Group</a>
                            </div>

                        </div>

                    </div>


                    <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">New Contact</a>
                    </div>


                    <div className="separator mt-3 opacity-75"></div>


                    <div className="menu-item px-3">
                        <div className="menu-content px-3 py-3">
                            <a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                    </div>

                </div> */}


            </div>


            <div className="card-body pt-2">
                <ul className="nav nav-pills nav-pills-custom mb-3 ">
                    {arr.recentOrder != null ?
                        arr.recentOrder.brand.map((data,i)=>{
                            return (
                                <li className="nav-item mb-3 me-3 me-lg-6">
                                    <a className=
                                        {i===0?"nav-link d-flex flex-column justify-content-between flex-center overflow-hidden active w-80px h-85px py-4":
                                        "nav-link d-flex flex-column justify-content-between flex-center overflow-hidden w-80px h-85px py-4"}
                                         data-bs-toggle="pill" href={"#list"+i}>

                                        <span className="nav-icon" style={{textAlign:'center'}}> 
                                            <img alt="" src="assets/media/product/box.png" className=""  />
                                        </span>


                                        <span className="nav-text text-gray-700 fw-bold fs-6 lh-1" style={{textAlign:'center'}}>{data.name_brand}</span>


                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary" style={{textAlign:'center'}}></span>

                                        </a>
                                </li>
                            );
                        })
                    : null}
                </ul>

              <div className="tab-content justify-content-center">
                    {arr.recentOrder!=null?
                        arr.recentOrder.brand.map((index,i)=>{
                            return(
                                <div className={i===0?"tab-pane fade show active":"tab-pane fade"} id={"list"+i}>
                                    <div className="table-responsive">
                                        <table className="table table-row-dashed align-middle gs-0 gy-4 my-0">
                                            <thead>
                                                <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                                                    <th className="ps-0 w-50px"></th>
                                                    <th className="min-w-125">{i18n.t("ITEM NAME")}</th>
                                                    <th className="text-end min-w-100px">{i18n.t("QTY")}</th>
                                                    <th className="pe-0 text-end min-w-100px"></th>
                                                    <th className="pe-0 text-end min-w-100px"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {index.list_item.map((item,p)=>{
                                                    return(
                                                        <tr>
                                                            <td>
                                                                <img src="assets/media/product/pax.png" className="w-40px ms-n1" alt="" />
                                                            </td>
                                                            <td className="ps-0">
                                                                <span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6 text-start pe-0 ps-4" >{item.name_item}</span>
                                                                {/* <span className="text-gray-500 fw-semibold fs-7 d-block text-start ps-0">Item: #XDG-2347</span> */}
                                                            </td>
                                                            <td>
                                                                <span className="text-gray-800 fw-bold d-block fs-6 ps-0 text-end">{item.total_qty}</span>
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/* <span className="text-gray-800 fw-bold d-block fs-6">$72.00</span> */}
                                                            </td>
                                                            <td className="text-end pe-0">
                                                                {/* <span className="text-gray-800 fw-bold d-block fs-6">$126.00</span> */}
                                                            </td>
                                                        </tr>  
                                                        );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            );
                        })
                    :null}

                </div>

            </div>

        </div>

    </div>
    );
}



