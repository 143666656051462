import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Button, Table } from "react-bootstrap";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
const token = cookieManager.getUserToken();

export class ItemView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
  }
}

render() {
    const {dataUpdate,isProcessing}=this.props
    let arr = [];
    if(dataUpdate!=null && dataUpdate!=undefined){
      arr = dataUpdate;
    }
    return (
      <React.Fragment>
       
          <div id="Item">
            {/* <Breadcrumbs url={this.state.breadcrumbs} tools={false} /> */}
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-fluid"
            >
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-header d-flex justify-content-start border-0 pt-6">
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-start"
                        data-kt-customer-table-toolbar="base"
                      >
                        <Link
                            to={"/master/item"}
                            onClick={() => changeLocation("/master/item")}
                            >
                        <Button
                          type="button"
                          className="btn btn-danger m-1"
                          // onClick={(e) => this.runSearch(this.state.search)}
                        ><FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon>{i18n.t('  Back')}</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={isProcessing ? " table-loading" : ""}>
                    {!isProcessing ? (
                      <Fragment>
                      <div className="card-body pt10 pb0 d-flex flex-row">  

                        <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-325px" data-kt-drawer="true" data-kt-drawer-name="start-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '250px': '300px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_social_start_sidebar_toggle">
                          <div style={{marginRight:'50px', backgroundColor:'#DCDCDC'}} className="card mb-5 mb-xl-8">							
                              <div className="card-body pt-15 px-0">
                                  <div className="d-flex flex-column text-center mb-9 px-9">
                                      <img src={"/assets/media/product/pax.png"} className="" alt="" style={{ backgroundColor:'#DCDCDC'}}/>
                                      {/* <div className="symbol symbol-80px symbol-lg-150px mb-4">
                                          <
                                      </div> */}
                                      <div className="text-center">
                                          <a href="#" className="text-gray-800 fw-bold text-hover-primary fs-4">{}</a>
                                          <span className="text-muted d-block fw-semibold">{}</span>
                                      </div>
                                      
                                  </div>
                              </div>
                              
                          </div>
                        </div>

                        <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-325px" data-kt-drawer="true" data-kt-drawer-name="start-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '250px': '300px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_social_start_sidebar_toggle">
                        <div className="row">
                          <table >
                            <thead>
                            </thead>
                            <tbody style={{fontSize:'16px', fontWeight:'bold'}}>
                                <tr >
                                  <td>{i18n.t("Item Name")}</td>
                                  <td>:</td><td>{arr?.name||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Item Code")}</td>
                                  <td>:</td><td>{arr?.code||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Item Brand")}</td>
                                  <td>:</td><td>{arr?.brand||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Item Category")}</td>
                                  <td>:</td><td>{arr?.category||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Type")}</td>
                                  <td>:</td><td>{arr?.type||''}</td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      <div className="row pt-5">
                      <Table
                          className="table-responsive bordered"
                          >
                          <thead className="bg-success text-white" >
                              <td style={{paddingLeft:'10px'}}>{i18n.t("Unit")}</td>
                              <td>{i18n.t("Conversion")}</td>
                          </thead>
                          <tbody>
                            {arr.units?.map((unit, index) => {
                                return (
                                  <tr key={'convertion'+index} style={{fontSize:'14px', fontWeight:'bold'}}>
                                    <td style={{paddingLeft:'10px'}}>{unit.unitName}</td>
                                    <td>{unit.qty}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          </Table>
                        </div>
                        </div>
                      </div>
                      <div className="card-body pt10 pb0">  
                      </div>
                      </Fragment>
                        ):(
                          <div className="table-loading-message">
                            <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                            <div className="text-center">{i18n.t("Loading")}</div>
                          </div>
                        )}
                      </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default ItemView;
