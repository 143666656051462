import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
// components
import { TempCustomer } from '../../../../components/modules/master/customer-temp';
// middlewares
import TempCustomerMiddleware from '../../../../middleware/modules/master/customer-temp';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';

const mapStateToProps = ({ auth,tempcustomer }) => ({
  isProcessing: tempcustomer.isProcessing,
  isError: auth.isError,
  errorMessage: auth.errorMessage,
  datalist:tempcustomer.data,
  showModal:tempcustomer.showModal,
  authUser:auth.authUser
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    reset: () =>TempCustomerMiddleware.resetList(),
    create: (search) =>TempCustomerMiddleware.create(search),
    search: data =>TempCustomerMiddleware.list(data),
    update: data =>TempCustomerMiddleware.update(data),
    view: data =>TempCustomerMiddleware.getOne(data),
    approve: (id,search) => TempCustomerMiddleware.beforeApprove(id,search),
    reject: (id,search) =>TempCustomerMiddleware.beforeReject(id,search),
    //helpers
    getHelper: () =>TempCustomerMiddleware.getHelperFull(),
   
  }, dispatch);
};
class TempCustomerContainer extends Component {
  
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired
  }

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  }

  constructor(props){
    super(props)
    this.rejectItem=this.rejectItem.bind(this);
    this.approveItem=this.approveItem.bind(this);
    this.viewItem=this.viewItem.bind(this);
    this.createItem=this.createItem.bind(this);
    this.updateItem=this.updateItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
   this.props.reset();
   this.props.search(search);
  }

  rejectItem(id,search){
    this.props.reject(id,search)
  }
  approveItem(id,search){
    this.props.approve(id,search)
  }
  updateItem(id){
    this.props.update(id)
    this.props.getHelper()
  }
  viewItem(id){
    this.props.view(id)
  }
  createItem(search=null){
    this.props.create(search)
  }
  render() {
    const {isProcessing, search , datalist,showModal} = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Customer Temporary List")}
          </title>
        </Helmet>
      <TempCustomer
        { ...this.props}
        data={datalist}
        rejectItem={this.rejectItem}
        approveItem={this.approveItem}
        viewItem={this.viewItem}
        updateItem={this.updateItem}
        createData={this.createItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TempCustomerContainer);
