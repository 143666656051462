export default class ItemActions {

  static NEW_DATA = 'NEW_DATA';
  static UPDATE_DATA='UPDATE_DATA';
  static SHOW_DATA='SHOW_DATA';
  static VIEW_DATA='VIEW_DATA';
  static VIEW_DATA_ITEM='VIEW_DATA_ITEM';
  static DELETE_DATA='DELETE_DATA';
  static ON_SUBMIT='ON_SUBMIT';
  static ERROR_DATA='ERROR_DATA';
  static ERROR_SERVER='ERROR_SERVER';
  static PROCESS_DATA='PROCESS_DATA';
  static RESET_DATA='RESET_DATA';
  static GET_HELPER_ITEM='GET_HELPER_ITEM';
  static SUCCESS_DATA='SUCCESS_DATA';
  static RESET_LIST='RESET_LIST';

  static submit(){
    return {
      type: ItemActions.ON_SUBMIT
    };
  }

  static create(data) {
    return {
      type: ItemActions.NEW_DATA,
      payload: data
    };
  }

  static update(data) {
    return {
      type: ItemActions.UPDATE_DATA,
      payload: data
    };
  }

  static view(data) {
    return {
      type: ItemActions.VIEW_DATA,
      payload: data
    };
  }

  static viewItem(data) {
    return {
      type: ItemActions.VIEW_DATA_ITEM,
      payload: data
    };
  }

  static delete(data) {
    return {
      type: ItemActions.DELETE_DATA
    };
  }

  static list(data) {
    return {
      type: ItemActions.SHOW_DATA,
      payload: data
    };
  }
  static error(error){
    return {
      type: ItemActions.ERROR_DATA,
      payload: error
    };
  }
  static success(){
    return {
      type: ItemActions.SUCCESS_DATA,
    };
  }
  static process(){
    return {
      type: ItemActions.PROCESS_DATA,
    };
  }
  static reset(){
    return {
      type: ItemActions.RESET_DATA,
    };
  }
  static resetList(){
    return {
      type: ItemActions.RESET_LIST,
    };
  }
  static helper(data){
    return {
      type: ItemActions.GET_HELPER_ITEM,
      payload:data
    };
  }
  static errorInternal(error){
    return {
      type: ItemActions.ERROR_SERVER,
      payload: error
    };
  }
}