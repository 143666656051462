import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import moment from "moment/moment";
import i18n from "../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faMinus, faRefresh } from "@fortawesome/free-solid-svg-icons";
import Chart from "../utilities/chart";
import ChartForm from "../utilities/chart";
import { Button } from "react-bootstrap";
import LoadingFullScreen from "../layouts/LoadingFullScreen";
import SectionSalesThisMonth, { sectionSalesThisMonth } from "./section/sales-month";
import SectionDailySales from "./section/daily-sales";
import SectionExpectedEarning from "./section/expected-earnings";
import SectionOrderWeek from "./section/order-week";
import SectionCustomerSales from "./section/customer";
import SectionRecentOrders from "./section/recent-orders";
import SectionSalesDiscount from "./section/sales-discount";
import SectionProductOrders from "./section/product-orders";
import SectionStockReport from "./section/stock-report";
class Home extends Component {
	static propTypes = {
		isProcessing: PropTypes.bool,
		isError: PropTypes.bool,
		errorMessage: PropTypes.any,
		signin: PropTypes.func,
	};

	constructor(props) {
		super(props);
		this.state = {
			date: new Date()
		};
	}

	refresh() {
		this.props.refresh();
	}

	render() {
		console.log(this.props)
		const { data, isProcessing } = this.props
		let arr = [];
		if (data != undefined && data != null) {
			arr = data;
		}
		return (


			<React.Fragment>
				<Helmet>
					<title>Home</title>
				</Helmet>
				{/* Header */}
				<div className="toolbar py-3 py-lg-6" id="kt_toolbar">
					<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack flex-wrap gap-2">
						<div className="page-title d-flex flex-column align-items-start me-3 py-2 py-lg-0 gap-2">
							<h1 className="d-flex text-gray-900 fw-bold m-0 fs-3">Information Dashboard</h1>
						</div>
						<div className="d-flex align-items-center">
							<Button className="btn btn-icon btn-color-warning bg-body w-35px h-35px w-lg-40px h-lg-40px me-3" onClick={(e) => this.refresh()}>
								<FontAwesomeIcon icon={faRefresh} ></FontAwesomeIcon>
							</Button>
							<a href="#" className="btn btn-flex bg-body h-35px h-lg-40px px-5 me-3" id="kt_dashboard_daterangepicker" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-trigger="hover" >
								<span className="">
									{/* <span className="text-muted fw-semibold me-1" id="kt_dashboard_daterangepicker_title">Today</span> */}
									<span className="text-primary fw-bold" style={{ display: 'flex',justifyContent: "center" }} id="kt_dashboard_daterangepicker_date">Week {(arr.expectedEarning != null ? arr.expectedEarning.week : 0)}</span>
								</span>
								<i className="ki-duotone ki-down fs-4 m-0"></i>
							</a>
							<a href="#" className="btn btn-flex bg-body h-35px h-lg-40px px-5" id="kt_dashboard_daterangepicker" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-trigger="hover" >
								<span className="">
									{/* <span className="text-muted fw-semibold me-1" id="kt_dashboard_daterangepicker_title">Today</span> */}
									<span className="text-primary fw-bold" style={{ textAlign: 'center' }} id="kt_dashboard_daterangepicker_date">{moment(this.state.date).format("DD MMMM YYYY")}</span>
								</span>
								<i className="ki-duotone ki-down fs-4 m-0"></i>
							</a>
						</div>
					</div>
				</div>
				<div className={isProcessing ? " table-loading" : ""}>
					{!isProcessing ? (
						<div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
							<div className="content flex-row-fluid" id="kt_content">
								<div className="row gx-5 gx-xl-10 mb-xl-10">
									<div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-10">
										<SectionExpectedEarning 
											data={arr}
										/>
										<SectionOrderWeek
											data={arr}
										/>
									</div>
									<div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-10">
										<SectionDailySales
											data={arr}
										/>
										<SectionCustomerSales
											data={arr}
										/>
									</div>
									<div className="col-lg-12 col-xl-12 col-xxl-6 mb-5 mb-xl-0">
										<SectionSalesThisMonth
											data={arr}
										/>
									</div>
								</div>

								<div className="row gy-5 g-xl-10">
									<SectionRecentOrders
										data={arr}
									/>
									<SectionSalesDiscount
										data={arr}
									/>
								</div>

								
								<div className="row gy-5 g-xl-10">
									<div className="col-xl-12 mb-5 mb-xl-10">
										<SectionProductOrders
											data={arr}
										/>
									</div>
								</div>


								<div className="row gy-5 g-xl-10">

									{/* <div className="col-xl-4">

										<div className="card card-flush h-xl-100">

											<div className="card-header pt-7">

												<h3 className="card-title align-items-start flex-column">
													<span className="card-label fw-bold text-gray-900">Product Delivery</span>
													<span className="text-gray-500 mt-1 fw-semibold fs-6">1M Products Shipped so far</span>
												</h3>


												<div className="card-toolbar">
													<a href="apps/ecommerce/sales/details.html" className="btn btn-sm btn-light">Order Details</a>
												</div>

											</div>


											<div className="card-body">

												<div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: "415px" }}>

													<div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">

														<div className="d-flex flex-stack mb-3">

															<div className="me-3">

																<img src="assets/media/stock/ecommerce/210.gif" className="w-50px ms-n1 me-1" alt="" />


																<a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bold">Elephant 1802</a>

															</div>


															<div className="m-0">

																<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
																	<i className="ki-duotone ki-dots-square fs-1">
																		<span className="path1"></span>
																		<span className="path2"></span>
																		<span className="path3"></span>
																		<span className="path4"></span>
																	</i>
																</button>

																<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

																	<div className="menu-item px-3">
																		<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
																	</div>


																	<div className="separator mb-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Ticket</a>
																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Customer</a>
																	</div>


																	<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

																		<a href="#" className="menu-link px-3">
																			<span className="menu-title">New Group</span>
																			<span className="menu-arrow"></span>
																		</a>


																		<div className="menu-sub menu-sub-dropdown w-175px py-4">

																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Admin Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Staff Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Member Group</a>
																			</div>

																		</div>

																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Contact</a>
																	</div>


																	<div className="separator mt-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<div className="menu-content px-3 py-3">
																			<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																		</div>
																	</div>

																</div>


															</div>

														</div>


														<div className="d-flex flex-stack">

															<span className="text-gray-500 fw-bold">To:
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bold">Jason Bourne</a></span>


															<span className="badge badge-light-success">Delivered</span>

														</div>

													</div>


													<div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">

														<div className="d-flex flex-stack mb-3">

															<div className="me-3">

																<img src="assets/media/stock/ecommerce/209.gif" className="w-50px ms-n1 me-1" alt="" />


																<a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bold">RiseUP</a>

															</div>


															<div className="m-0">

																<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
																	<i className="ki-duotone ki-dots-square fs-1">
																		<span className="path1"></span>
																		<span className="path2"></span>
																		<span className="path3"></span>
																		<span className="path4"></span>
																	</i>
																</button>

																<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

																	<div className="menu-item px-3">
																		<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
																	</div>


																	<div className="separator mb-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Ticket</a>
																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Customer</a>
																	</div>


																	<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

																		<a href="#" className="menu-link px-3">
																			<span className="menu-title">New Group</span>
																			<span className="menu-arrow"></span>
																		</a>


																		<div className="menu-sub menu-sub-dropdown w-175px py-4">

																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Admin Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Staff Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Member Group</a>
																			</div>

																		</div>

																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Contact</a>
																	</div>


																	<div className="separator mt-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<div className="menu-content px-3 py-3">
																			<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																		</div>
																	</div>

																</div>


															</div>

														</div>


														<div className="d-flex flex-stack">

															<span className="text-gray-500 fw-bold">To:
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bold">Marie Durant</a></span>


															<span className="badge badge-light-primary">Shipping</span>

														</div>

													</div>


													<div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">

														<div className="d-flex flex-stack mb-3">

															<div className="me-3">

																<img src="assets/media/stock/ecommerce/214.gif" className="w-50px ms-n1 me-1" alt="" />


																<a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bold">Yellow Stone</a>

															</div>


															<div className="m-0">

																<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
																	<i className="ki-duotone ki-dots-square fs-1">
																		<span className="path1"></span>
																		<span className="path2"></span>
																		<span className="path3"></span>
																		<span className="path4"></span>
																	</i>
																</button>

																<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

																	<div className="menu-item px-3">
																		<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
																	</div>


																	<div className="separator mb-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Ticket</a>
																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Customer</a>
																	</div>


																	<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

																		<a href="#" className="menu-link px-3">
																			<span className="menu-title">New Group</span>
																			<span className="menu-arrow"></span>
																		</a>


																		<div className="menu-sub menu-sub-dropdown w-175px py-4">

																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Admin Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Staff Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Member Group</a>
																			</div>

																		</div>

																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Contact</a>
																	</div>


																	<div className="separator mt-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<div className="menu-content px-3 py-3">
																			<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																		</div>
																	</div>

																</div>


															</div>

														</div>


														<div className="d-flex flex-stack">

															<span className="text-gray-500 fw-bold">To:
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bold">Dan Wilson</a></span>


															<span className="badge badge-light-danger">Confirmed</span>

														</div>

													</div>


													<div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">

														<div className="d-flex flex-stack mb-3">

															<div className="me-3">

																<img src="assets/media/stock/ecommerce/211.gif" className="w-50px ms-n1 me-1" alt="" />


																<a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bold">Elephant 1802</a>

															</div>


															<div className="m-0">

																<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
																	<i className="ki-duotone ki-dots-square fs-1">
																		<span className="path1"></span>
																		<span className="path2"></span>
																		<span className="path3"></span>
																		<span className="path4"></span>
																	</i>
																</button>

																<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

																	<div className="menu-item px-3">
																		<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
																	</div>


																	<div className="separator mb-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Ticket</a>
																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Customer</a>
																	</div>


																	<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

																		<a href="#" className="menu-link px-3">
																			<span className="menu-title">New Group</span>
																			<span className="menu-arrow"></span>
																		</a>


																		<div className="menu-sub menu-sub-dropdown w-175px py-4">

																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Admin Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Staff Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Member Group</a>
																			</div>

																		</div>

																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Contact</a>
																	</div>


																	<div className="separator mt-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<div className="menu-content px-3 py-3">
																			<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																		</div>
																	</div>

																</div>


															</div>

														</div>


														<div className="d-flex flex-stack">

															<span className="text-gray-500 fw-bold">To:
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bold">Lebron Wayde</a></span>


															<span className="badge badge-light-success">Delivered</span>

														</div>

													</div>


													<div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">

														<div className="d-flex flex-stack mb-3">

															<div className="me-3">

																<img src="assets/media/stock/ecommerce/215.gif" className="w-50px ms-n1 me-1" alt="" />


																<a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bold">RiseUP</a>

															</div>


															<div className="m-0">

																<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
																	<i className="ki-duotone ki-dots-square fs-1">
																		<span className="path1"></span>
																		<span className="path2"></span>
																		<span className="path3"></span>
																		<span className="path4"></span>
																	</i>
																</button>

																<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

																	<div className="menu-item px-3">
																		<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
																	</div>


																	<div className="separator mb-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Ticket</a>
																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Customer</a>
																	</div>


																	<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

																		<a href="#" className="menu-link px-3">
																			<span className="menu-title">New Group</span>
																			<span className="menu-arrow"></span>
																		</a>


																		<div className="menu-sub menu-sub-dropdown w-175px py-4">

																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Admin Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Staff Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Member Group</a>
																			</div>

																		</div>

																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Contact</a>
																	</div>


																	<div className="separator mt-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<div className="menu-content px-3 py-3">
																			<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																		</div>
																	</div>

																</div>


															</div>

														</div>


														<div className="d-flex flex-stack">

															<span className="text-gray-500 fw-bold">To:
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bold">Ana Simmons</a></span>


															<span className="badge badge-light-primary">Shipping</span>

														</div>

													</div>


													<div className="border border-dashed border-gray-300 rounded px-7 py-3">

														<div className="d-flex flex-stack mb-3">

															<div className="me-3">

																<img src="assets/media/stock/ecommerce/192.gif" className="w-50px ms-n1 me-1" alt="" />


																<a href="apps/ecommerce/catalog/edit-product.html" className="text-gray-800 text-hover-primary fw-bold">Yellow Stone</a>

															</div>


															<div className="m-0">

																<button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
																	<i className="ki-duotone ki-dots-square fs-1">
																		<span className="path1"></span>
																		<span className="path2"></span>
																		<span className="path3"></span>
																		<span className="path4"></span>
																	</i>
																</button>

																<div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px" data-kt-menu="true">

																	<div className="menu-item px-3">
																		<div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick Actions</div>
																	</div>


																	<div className="separator mb-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Ticket</a>
																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Customer</a>
																	</div>


																	<div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">

																		<a href="#" className="menu-link px-3">
																			<span className="menu-title">New Group</span>
																			<span className="menu-arrow"></span>
																		</a>


																		<div className="menu-sub menu-sub-dropdown w-175px py-4">

																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Admin Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Staff Group</a>
																			</div>


																			<div className="menu-item px-3">
																				<a href="#" className="menu-link px-3">Member Group</a>
																			</div>

																		</div>

																	</div>


																	<div className="menu-item px-3">
																		<a href="#" className="menu-link px-3">New Contact</a>
																	</div>


																	<div className="separator mt-3 opacity-75"></div>


																	<div className="menu-item px-3">
																		<div className="menu-content px-3 py-3">
																			<a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
																		</div>
																	</div>

																</div>


															</div>

														</div>


														<div className="d-flex flex-stack">

															<span className="text-gray-500 fw-bold">To:
																<a href="apps/ecommerce/sales/details.html" className="text-gray-800 text-hover-primary fw-bold">Kevin Leonard</a></span>


															<span className="badge badge-light-danger">Confirmed</span>

														</div>

													</div>

												</div>

											</div>

										</div>

									</div> */}


									<div className="col-xl-12">

										<SectionStockReport 
											data={arr}
										/>

									</div>

								</div>

							</div>

						</div>
					) : (
						<LoadingFullScreen></LoadingFullScreen>
					)}
				</div>
			</React.Fragment>

		);
	}
}

export default Home;
