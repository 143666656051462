import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import SellingActions from "../../../../reducers/modules/sales/selling/actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Navigate } from "react-router-dom";

const MySwal = withReactContent(Swal);
const url = process.env.REST_URL + "sales/selling";

export default class SellingMiddleware {
  static create() {
    return (dispatch) => {
      dispatch(SellingActions.create());
    };
  }

  static update(params) {
    return (dispatch) => {
      dispatch(SellingActions.update());
      SellingMiddleware.showOne(dispatch, params);
    };
  }
  static beforeCancel(id, value, search) {
    const post = {
      id: id,
      value: value,
    };
      return (dispatch) => {
      dispatch(SellingActions.cancel());
      SellingMiddleware.saveCancel(dispatch, post, search);
    };
  }

  static list(params) {
    return (dispatch) => {
      dispatch(SellingActions.process());
      SellingMiddleware.showlist(dispatch, params);
    };
  }
  static reset() {
    return (dispatch) => {
      dispatch(SellingActions.reset());
    };
  }
  static getViewSelling(params) {
      return (dispatch) => {
        dispatch(SellingActions.process());
        SellingMiddleware.showOneSelling(dispatch, params);
      };
    }
  static resetList() {
    return (dispatch) => {
      dispatch(SellingActions.resetList());
    };
  }
  static getOne(params) {
    return (dispatch) => {
      dispatch(SellingActions.view());
      SellingMiddleware.showOne(dispatch, params);
    };
  }

  static beforeSaveNew(post, search) {
    return (dispatch) => {
      dispatch(SellingActions.submit());
      SellingMiddleware.saveNew(dispatch, post, search);
    };
  }
  static beforeSaveUpdate(params, post, search) {
    return (dispatch) => {
      dispatch(SellingActions.submit());
      SellingMiddleware.saveUpdate(dispatch, params, post, search);
    };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(SellingActions.beforePrint());
      SellingMiddleware.showPrint(dispatch, params);
    };
  }

  static beforeDelete(id, params) {
    return (dispatch) => {
      dispatch(SellingActions.delete());
      SellingMiddleware.delete(dispatch, params, id);
    };
  }

  static getHelper(params = null) {
    return (dispatch) => {
      SellingMiddleware.showHelper(dispatch, params);
    };
  }

  static getHelperFull(params = null) {
    return (dispatch) => {
      SellingMiddleware.showHelperFull(dispatch, params);
    };
  }

  static getToken() {
    return cookieManager.getUserToken();
  }

  static showHelperFull(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-helper-selling",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SellingActions.helperFull(response.data.result));
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }
  static showlist(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url,
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SellingActions.list(response.data.result));
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }
  static saveCancel(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url + "/destroy/" + post.id,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            SellingActions.process(),
            SellingMiddleware.showlist(dispatch, search)
          );
          dispatch(SellingActions.success());
          SellingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Void Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error, true);
      });
  }
  static showHelper(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: process.env.REST_URL + "helper/get-selling",
      responseType: "json",
      params: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SellingActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }
  static showPrint(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url + "/print/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SellingActions.print(response.data.result));
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }

  static showOne(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SellingActions.view(response.data.result));
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }

  static showOneSelling(dispatch, params) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SellingActions.viewSelling(response.data.result));
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }

  static delete(dispatch, params, id) {
    axios({
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url + "/" + id,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            SellingActions.process(),
            SellingMiddleware.showlist(dispatch, params)
          );
          SellingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Delete Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error, true);
      });
  }

  static saveNew(dispatch, post, search) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            SellingActions.process(),
            SellingMiddleware.showlist(dispatch, search)
          );
          dispatch(SellingActions.success());
          SellingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Create Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }

  static saveUpdate(dispatch, params, post, search) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${SellingMiddleware.getToken()}`,
      },
      url: url + "/" + params,
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            SellingActions.process(),
            SellingMiddleware.showlist(dispatch, search)
          );
          dispatch(SellingActions.success());
          SellingMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Update Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        SellingMiddleware.alertHendler(dispatch, error);
      });
  }
  static alertHendler(
    dispatch,
    result,
    isSwal = false,
    extraMessage = "",
    type = "error"
  ) {
    if (type == "error") {
      if (result.response) {
        const status = result.response.status;
        switch (status) {
          case 500:
            if (isSwal) {
              const data = result.response.data;
              MySwal.hideLoading();
              MySwal.update({
                title: "Delete Error",
                html: data.error,
                icon: "error",
                showConfirmButton: true,
              });
            } else {
              const data = result.response.data;
              dispatch(SellingActions.error(data.error));
            }
            break;
          case 403:
            console.log("Error 403");
            break;
          case 404:
            console.log("Error 404");
            break;
          case 401:
            const data = result.response.data;
            MySwal.fire({
              title: "Login Invalid",
              html: data.error,
              icon: "error",
              showConfirmButton: true,
            }).then((result) => {
              if (result.isConfirmed) {
                <Navigate to="/login"></Navigate>;
              }
            });
            break;
        }
      } else if (result.request) {
        MySwal.fire({
          title: "Internal Server Error",
          html: "Please info your Developer to fix this",
          icon: "error",
          showConfirmButton: true,
        });
        dispatch(SellingActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", result.message);
      }
    } else {
      MySwal.fire({
        title: "Success",
        html: extraMessage,
        icon: "success",
        showConfirmButton: true,
      });
    }
  }
}
