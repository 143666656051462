import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Collapse, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Fragment } from "react";
import LabelStock from "../../../utilities/Status/StatusStock";
import { stockReport, transactionCodePO } from "../../../utilities/Const/TranscationCode";

class TableStockCard extends Component {
  static propTypes = {
    data: PropTypes.any,
    expandAble: PropTypes.bool,
    checkAble: PropTypes.bool,
    numberAble: PropTypes.bool,
    customExpand: PropTypes.bool,
    componentExpand: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    expandAble: false,
    checkAble: false,
    numberAble: true,
    customExpand: false,
    componentExpand: () => {
      <div></div>;
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      expandTr: [],
      expandAll: false,
      data: [],
      link: null,
      setData: 0,
      columns: [],
      columnWH: [
        {
          key: "collapse",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t(""),
          type: "text",
          tHclass: "w-10px",
          tdClass: "",
          width: "10px"

        },
        {
          key: "name_wh",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Warehouse"),
          type: "min-w-125px",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "name_sw",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Sub Warehouse"),
          type: "min-w-125px",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "total_stock",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Stock"),
          type: "min-w-125px ",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "remark",
          filter: { active: true, type: "Select", data: stockReport},
          type: "component",
          title: i18n.t("Remark"),
          tHclass: "min-w-125px",
          style: {paddingLeft:'20px'},
          tdClass: "",
        },
      ],
      search: {
        limit: 10,
      },
      fulldata: {
        branch: "",
      },
      page: null,
      currentPage: 1,
      totalData: 0,
      load: 0,
      isProcessing: false,
    };
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
    this.resetTable = this.resetTable.bind(this);
    this.expandAll = this.expandAll.bind(this);
    this.setLoading = this.setLoading.bind(this);

  }

  searchData() {
    let data = this.state.search;
    this.props.runSearch(data);
  }

  runSearch() {
    let data = this.state.search;
    let url = process.env.REST_URL + "stock-card/search";
    this.setState({ isProcessing: true });
    const token = cookieManager.getUserToken();
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      url: url,
      responseType: "json",
      data: data,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          var detail = response.data.result;

          const arr = {
            link: detail.links,
            data: detail.data,
            currentPage: detail.current_page,
            totalData: detail.total,
            page: detail.current_page,
            isProcessing: false,
            fulldata: detail.data
          };
          this.setState(arr);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  tdNumber(dataRow, i, span) {
    const { data } = this.props;
    let page = 1;
    let limit = 10;
    if (data.current_page != undefined) {
      page = data.current_page;
    }
    if (this.state.search.limit != undefined) {
      if (this.state.search.limit != -1) {
        limit = this.state.search.limit;
      } else {
        limit = 1;
      }
    }
    let plus = 0;
    if (page > 1) {
      plus = limit * (page - 1);
    }
    return <td rowSpan={span} style={{ paddingLeft: '10px' }} key={i.toString()}>{i + 1 + plus}</td>;
  }
  updateSearch(value, key) {

    if (value == "") {
      value = null;
    }
    this.state.search[key] = value;
  }

  _onBlur() {
    this.state.search["page"] = 1;
    this.searchData();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.state.search["page"] = 1;
      this.searchData();
    }
  }
  changePage(event, url, i) {
    if (url != null && this.state.search.limit != -1) {
      var urlParams = new URLSearchParams(url);
      this.state.search["page"] = i;

      this.searchData();
    }
  }
  changeLimit(event) {
    if (event.target.value == -1) {
      this.state.search["page"] = 1;
    } else {
      var pageAvailable = this.state.totalData / event.target.value;
      if (this.state.search["page"] > pageAvailable) {
        this.state.search["page"] = Math.ceil(pageAvailable);
      }
    }
    this.state.search["limit"] = event.target.value;
    this.searchData();
  }
  changeSelect(e, name) {
    let value = e.target.value;

    if (value == "") {
      value = null;
    }
    this.state.search[name] = value;
    this.searchData();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;

    if (value != null) {
      value = moment(value).format("YYYY-MM-DD");
    }
    this.state.search[key] = value;
    this.setState({ [key]: e }, () => this.searchData());
  }

  setLoading(key) {
    return (
      key == true ?
        (
          <div className="table-loading-message">
            <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
            <div className="text-center">{i18n.t("Loading")}</div>
          </div>
        ) : (
          ""
        )
    )
  }

  renderTableExpand(data) {

    if (data != null && data != undefined) {
      return (
        <td colSpan={5}>
          <table className="table align-middle table-row-dashed table-striped fs-6 ">
            <thead>
              <tr
                key={"headhead"}
                className="text-start fw-bold fs-12 text-uppercase gs-0 table-primary"
              >
                <th style={{ textAlign: 'center' }}>{"No."}</th>
                <th style={{ textAlign: 'center' }}>{"No. Band"}</th>
                <th style={{ textAlign: 'center' }}>{"Stock"}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return (
                  <Fragment>
                    <tr>
                      <td style={{ paddingLeft: '10px', textAlign: 'center' }}>{i + 1}</td>
                      <td style={{ paddingLeft: '10px', textAlign: 'center' }}>{item.band}</td>
                      <td style={{ paddingLeft: '10px', textAlign: 'center' }}>{item.qty}</td>
                    </tr>

                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </td>
      )
    }
  }

  renderDataTable(dataTable) {
    let html = [];
    let no = 0;
    let index = 0;
    let show = false;
    let number = 0;
    for (var i = 0; i < dataTable.length; i++) {
      if (dataTable[i].warehouse != null && dataTable[i].warehouse != [] && dataTable[i].warehouse.length > 0) {
       
        show = true;
        let WH = dataTable[i].warehouse;
        number = WH.length > 0 ? number+1:number+0;
        for (var p = 0; p < WH.length; p++) {
          if (p === 0) {
            index = index + 1;
            html.push(
              <Fragment>
                <tr className="main-row" id={(index % 2 === 0 ? "odd":"even")} >
                  <th style={{ textAlign: 'center' }}>{number}</th>
                  <th>{dataTable[i].name_item}</th>
                  <td>{WH[p].detail != null ? this.renderExpand(WH[p].detail, no) : ""}</td>
                  <td>{WH[p].name_wh}</td>
                  <td>{WH[p].name_sub_wh}</td>
                  <td>{WH[p].total_stock}</td>
                  <td><LabelStock value={WH[p].remark} /> </td>
                </tr>
                <tr >
                  <Collapse
                    in={this.state.expandTr[no]}
                    timeout={1000}
                    onEntering={() => this.setLoading(true)}
                    onEntered={() => this.setLoading(false)}
                    onExiting={() => this.setLoading(true)}
                    onExited={() => this.setLoading(false)}
                  >

                    {this.renderTableExpand(WH[p].detail)}

                  </Collapse>
                </tr>
              </Fragment>
            );
          } else {
            index = index + 1;

            html.push(
              <Fragment>
                <tr className="main-row" id={(index % 2 === 0 ? "odd":"even")}>
                  <td></td>
                  <td></td>
                  <td style={{ paddingLeft: '10px' }}>{WH[p].detail != null ? this.renderExpand(WH[p].detail, no) : ""}</td>
                  <td>{WH[p].name_wh}</td>
                  <td>{WH[p].name_sub_wh}</td>
                  <td>{WH[p].total_stock}</td>
                  <td><LabelStock value={WH[p].remark} /> </td>

                </tr>
                <tr ></tr>
                <Collapse
                  in={this.state.expandTr[no]}
                  timeout={500}
                  onEntering={() => this.setLoading(true)}
                  onEntered={() => this.setLoading(false)}
                  onExiting={() => this.setLoading(true)}
                  onExited={() => this.setLoading(false)}
                >
                  <tr colSpan={5}>
                    {this.renderTableExpand(WH[p].detail)}
                  </tr>
                </Collapse>
              </Fragment>
            );
          }
          no++;
        }
      }else{
        // console.log('salah',i)
      }
    }

    if (show === false) {
      html.push(
        <tr key={"notfound"}>
          <td
            className="text-center"
            colSpan={6}
          >
            {i18n.t("No Data Found")}
          </td>
        </tr>
      );
    }
    return html;

  }

  renderExpand(data, i) {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <Button
            variant="none"
            onClick={(e) => this.setExpand(i)}
            aria-controls={"table" + i}
            aria-expanded={this.state.expandTr[i]}
          >
            {" "}
            {this.state.expandTr[i] ? "-" : "+"}
          </Button>

        </div>
      </th>
    );
  }

  renderTd(column, data, i = 0, extraData) {
    var value = data[column.key];
    var type = column.type;
    let span = 0;
    if (extraData != null && extraData != undefined) {
      span = extraData;

    }

    switch (type) {
      case "text":
        return <td rowSpan={span} key={i.toString()}>{value}</td>;
      case "numberTd":
        return this.tdNumber(value, i, span);
      case "expand":
        return this.tdExpand(data, i);
      default:
        return <td rowSpan={span} key={i.toString()}>{value}</td>;
    }
  }

  tdExpand(i) {
    return (
      <td className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <Button
            variant="none"
            onClick={(e) => this.setExpand(i)}
            aria-controls={"table" + i}
            aria-expanded={this.state.expandTr[i]}
          >
            {" "}
            {this.state.expandTr[i] ? "-" : "+"}
          </Button>

        </div>
        <Collapse in={this.state.expandTr[i]}>
          <div id={"table" + i}>
            Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus
            terry richardson ad squid. Nihil anim keffiyeh helvetica, craft beer
            labore wes anderson cred nesciunt sapiente ea proident.
          </div>
        </Collapse>
      </td>
    );
  }
  expandAll(data) {
    // const { data } = this.props;
    // if (data != undefined) {
    //   let obj = {};
    //   let all = !this.state.expandAll;
    //   for (var i = 0; i < data.length; i++) {
    //     let value = data[i].id;
    //     obj = Object.assign(obj, { [value]: all });
    //   }
    //   this.setState({ expandTr: obj, expandAll: all });
    // }
  }

  setExpand(i) {
    this.setState({
      ...this.state,
      expandTr: {
        ...this.state.expandTr,
        [i]: !this.state.expandTr[i],
      },
    });
  }


  renderTrExpand(data, column, totalColumn) {
    const { checkAble, numberAble, customExpand, componentExpand } = this.props;
    const dataExpand = customExpand ? data : data[column.key];
    const columnExpand = customExpand ? null : column.columns;
   

    if (dataExpand != undefined) {

      return (
        <tr
          key={"expand" + data.id}
          className={!this.state.expandTr[data["id"]] ? "d-none" : ""}
        >
          {numberAble ? <td></td> : ""}
          <td colSpan={totalColumn + 1 + (checkAble ? 1 : 0)}>
            <Collapse
              key={"Collapse" + data["id"]}
              in={this.state.expandTr[data["id"]]}
            >
              {!customExpand ? (
                <div id={"table" + data["id"]}>
                  <table className="table table-responsive table-row-gray-500  mb-0">
                    <thead>
                      <tr className="fw-semibold fs-6 text-gray-800 bg-aquamarine">
                        {columnExpand.map((header, i) => (
                          <th
                            key={"expandHeader" + i}
                            scope="col"
                            className={
                              header.thClass != undefined
                                ? header.thClass
                                : null
                            }
                            
                          >
                            {header.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {dataExpand.map((data, i) => (
                        <tr key={"expandBody" + i}>
                          {columnExpand.map((fill, i) => {
                            if (i == 0) {
                              return (
                                <td key={"expandFill" + i} scope="row">
                                  {data[fill.key] ?? i18n.t("Not Have Band Number")}
                                </td>
                              );
                            } else {
                              if (fill.type == "action") {
                                return (
                                  <td
                                    key={"expandFill" + i}
                                    className={
                                      fill.tdClass != undefined
                                        ? fill.tdClass
                                        : null
                                    }
                                  >
                                    {fill.renderFunc(data[fill.key])}
                                  </td>
                                );
                              } else {
                                return (
                                  <td
                                    key={"expandFill" + i}
                                    className={
                                      fill.tdClass != undefined
                                        ? fill.tdClass
                                        : null
                                    }
                                  >
                                    {data[fill.key]}
                                  </td>
                                );
                              }
                            }
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                componentExpand(data)
              )}
              {/* </Fade> */}
            </Collapse>
          </td>
        </tr>
      );
    }
  }

  renderFilter(column, filter) {
    if (filter.active) {
      switch (filter.type) {
        case "Text":
          return (
            <input
              onKeyPress={this._onEnter}
              onBlur={this._onBlur}
              onChange={(e) => {
                this.updateSearch(e.target.value, column.key);
              }}
              type="text"
              className="form-control form-control-solid form-control-sm"
              placeholder={column.title}
              name={column.key}
            />
          );
          break;
        case "Select":
          return (
            <select
              onChange={(e) => this.changeSelect(e, column.key)}
              className="form-select form-select-sm form-select-solid"
            >
              {filter.data.map((list, i) => (
                <option key={column.key + i} value={list.key}>
                  {list.label}
                </option>
              ))}
            </select>
          );
          break;
        case "Date":
          return (
            <DatePickerCustom
              name={column.key}
              title={column.title}
              required={false}
              selected={this.state[column.key]}
              onChange={(e) => this.handlerDateTime(e, column.key)}
              startDay={0}
              isClearable={this.state[column.key] != null ? true : false}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm form-control-solid"
              labelfield={"false"}
            />
          );
          break;
      }
    } else {
      return "";
    }
  }
  renderNumber() {
    return (
      <th className="w-10px pe-5">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3" style={{ paddingLeft: '10px' }}>
          #
        </div>
      </th>
    );
  }
  resetTable() {
    const { data, columns } = this.props;
    var detail = data;
    const arr = {
      link: detail.links,
      data: detail.data,
      fulldata: data,
      search: data,
      currentPage: detail.current_page,
      totalData: detail.total,
      page: detail.current_page,
      columns: columns,
    };
    this.setState(arr);
  }

  render() {
    const { data, columns, show, columnWH, expandAble, theExpand } = this.props;
    if (data != undefined && data.length > 0 && data != null) {
      var detail = data;
      this.state.link = detail.links;
      this.state.data = detail;
      this.state.fulldata = data;
      this.state.idBranch = data[0].id_branch;
      this.state.currentPage = detail.current_page;
      this.state.totalData = detail.total;
      this.state.page = detail.current_page;
      this.state.columns = columns;
      this.state.search.limit = 10;
      this.state.load = 1;
      this.state.search.idBranch = data[0].id_branch;
      this.state.setData = 1;
    }
    return (
      <React.Fragment>
        <div className="card ">
          <div className="card-header border-0 ">
            <div className="card-toolbar align-items-end">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <Button
                  type="button"
                  className="btn btn-warning m-1"
                  onClick={() => this.searchData()}
                >
                  <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className={this.state.isProcessing ? " table-loading" : ""}>
              {this.state.isProcessing ? (
                <div className="table-loading-message">
                  <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                  <div className="text-center">{i18n.t("Loading")}</div>
                </div>
              ) : (
                ""
              )}

              <table className="table align-middle table-row-dashed table-index  fs-6">
                <thead>
                  <tr
                    key={"head"}
                    className="text-start fw-bold fs-12 text-uppercase gs-0 table-primary"
                  >
                    {this.renderNumber()}
                    {/* {expandAble ? this.renderExpand() : ""} */}

                    {this.state.columns.map((column, i) => (
                      <th key={i.toString()} className={column.tHclass}>
                        {column.title}
                      </th>
                    ))}
                    {this.state.columnWH.map((column, i) => (
                      <th key={i.toString()} className={column.tHclass}>
                        {column.title}
                      </th>
                    ))}
                  </tr>
                  {
                    <tr key={"filter"}>
                      {expandAble ? <th></th> : ""}
                      {<th></th>}
                      {this.state.columns.map((column, i) => (
                        <th key={i.toString()} className={column.tHclass}>
                          {this.renderFilter(column, column.filter)}
                        </th>
                      ))}
                      {this.state.columnWH.map((column, i) => (
                        <th key={i.toString()} className={column.tHclass} style={{paddingRight:'20px'}}>
                          {this.renderFilter(column, column.filter)}
                        </th>
                      ))}
                    </tr>
                  }
                </thead>
                <tbody className="fw-semibold text-gray-600 main-body">
                  {this.state.data != undefined && this.state.data.length > 0 ? (
                    this.renderDataTable(this.state.data)
                  ) : (
                    <tr key={"notfound"}>
                      <td
                        className="text-center"
                        colSpan={6}
                      >
                        {i18n.t("No Data Found")}
                      </td>
                    </tr>


                  )}

                </tbody>
              </table>
              <div className="row">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div className="dataTables_length">
                    <label>
                      <select
                        onChange={(e) => this.changeLimit(e)}
                        name="kt_customers_table_length"
                        aria-controls="kt_customers_table"
                        className="form-select form-select-sm form-select-solid"
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="-1">{i18n.t("All")}</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul className="pagination">
                      {this.state.link != null
                        ? this.state.link.map((data, i) => (
                          <li
                            className={
                              "paginate_button page-item" +
                              (i == 0 ? " previous" : "") +
                              (i == this.state.link.length - 1
                                ? " next"
                                : "") +
                              (data.url == null ? " disabled" : "") +
                              (data.active ? " active" : "")
                            }
                            key={i}
                          >
                            <button
                              key={i}
                              onClick={(e) => this.changePage(e, data.url, i)}
                              aria-controls="kt_customers_table"
                              data-dt-idx={i}
                              tabIndex="0"
                              className="page-link"
                            >
                              {i == 0 ? <i className="previous"></i> : ""}
                              {i != 0 && i != this.state.link.length - 1
                                ? data["label"]
                                : ""}
                              {i == this.state.link.length - 1 ? (
                                <i className="next"></i>
                              ) : (
                                ""
                              )}
                            </button>
                          </li>
                        ))
                        : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TableStockCard;
