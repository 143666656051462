import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    page: {
        marginTop: '10px'
    },
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        // paddingTop: "10px",
        // border:'1px',
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px",
    },
    row: {
        flex: 1,
        flexDirection: 'row',
    },
    rowHeader: {
        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        borderLeft: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        borderBottom: '1px',
        flexDirection: 'row',
    },
    trHeader: {
        backgroundColor: 'rgba(255, 255, 128)',
        // borderBottom: '1px',
        flexDirection: 'row',
    },
    td: {
        // borderRight: '1px',
        borderLeft: '1px',
        flexDirection: 'column',
    },
    tdcol: {
        colspan: '2',
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    titleHeader: {
        flexDirection: 'row',
        marginBottom: '10px'
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },

    titleTranx: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'left',
        marginBottom: '5px'
    },
    title: {
        fontSize: "15px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center',
        marginBottom: '5px'
    },

    div: {
        width: '100%',
        height: 400,
        border: '1 solid black'
        // fontSize: "15px",
        // fontWeight: 700,
        // fontFamily: 'Times-Bold',
        // textAlign: 'center',
        // marginBottom:'5px'
    },

    tableTitle: {
        borderTop: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        // marginRight: '5px'
        // padding: '5px',
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        border: '2px'
    },

    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },
    //Dalam Table
    menuHeader: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "12px",
    },

    totalBottom: {
        textAlign: 'right',
        fontFamily: 'Times-Bold',
        paddingRight: '1px',
        paddingTop: '2px',
        fontSize: "8px",
    },


    fillText: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "10px",
        paddingTop: '1px'

    },

    fillTextType: {
        textAlign: 'center',
        paddingLeft: '1px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    fill: {
        textAlign: 'left',
        paddingLeft: '5px',
        fontSize: "10px",
        fontFamily: 'Times-Bold',
    },

    fillNumber: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
    },

    fillNumberBold: {
        textAlign: 'right',
        paddingRight: '2px',
        fontSize: "9px",
        fontFamily: 'Times-Bold',
    },

    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        top: 100,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class PrintBalanceSheet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: i18n.t("Laporan Neraca"),
            merchant: {
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address: []
            },
            logo: '/assets/media/logos/logo.jpg',
            in: [],
            out: [],
        };
    }
    renderMessage() {
        const { isProcessing, requestPrint } = this.props
        switch (true) {
            case isProcessing:
                return (
                    <div style={loader}>
                        <div className="spinner-border text-primary" style={{ width: '10rem', height: '10rem' }} role="status"></div><br />
                        <div style={{ fontSize: 36 }}>Getting Data From Server</div>
                    </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{ fontSize: 36 }}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={() => { this.props.getPrint(this.props.id) }}>Reload Page</Button></div>
                    </div>)
        }
    }

    renderMenu() {
        html.push(

        );
        return html;
    }
    renderHeader() {
            const { dataPrint } = this.props
            let html = [];
            let address = [];
            if (dataPrint != null && dataPrint != '') {
                this.state.merchant = dataPrint.merchant
                address = this.state.merchant?.address.split(/\r?\n/)||null
            }
    
            html.push(
                <Fragment>
                    <View fixed style={styles.titleHeader}>
                        <View style={styles.row}>
                            <View style={styles.col, { width: '90px' }} >
                                <Image src={(this.state.merchant != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                            </View>
                            <View style={styles.col} >
                                <Text style={styles.header}>{this.state.merchant?.name || ''}</Text>
                                {address?address.map((add, i) =>
                                    <Text key={'address' + i} style={styles.address}>{add}</Text>
                                ):null}
                            </View>
                        </View>
                    </View>
                </Fragment>
            );
            return html;
        }

    render() {
        const { dataPrint } = this.props
        let totalAktiva = 0
        let totalPasiva = 0
        let address = []
        if (dataPrint != null) {
            if (dataPrint.debt != null) {
                dataPrint.debt.map((dataDebt, i) =>
                    dataDebt.coa2.map((nominal, j) =>
                        totalAktiva += nominal.total_show_no
                    ))
            }

            if (dataPrint.credit != null) {
                dataPrint.credit.map((dataCredit, i) =>
                    dataCredit.coa2.map((nominal, j) =>
                        totalPasiva += nominal.total_show_no
                    ))
            }
            this.state.data = dataPrint.model
            // this.state.merchant = dataPrint.merchant
            // address = this.state.merchant.address.split(/\r?\n/)
            // total = this.state.data.journal_detail.map(item => parseFloat(item.debt) + parseFloat(item.credit)).reduce((accumulator, currentValue) => accumulator + currentValue , 0)
        }

        return (
            <React.Fragment>{(dataPrint != null) ?
                <PDFViewer width={"100%"} height={"100%"} >
                    <Document title={this.state.title}>
                        {/*DATA AKTIVA */}

                        <Page orientation={"landscape"} wrap size={"A4"} style={styles.page}>
                            <View style={styles.body}>
                            {this.renderHeader()}
                                {/* <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col, { width: '90px' }} >
                                            <Image src={(this.state.merchant.logo != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                                        </View>
                                        <View style={styles.col, { width: '300px' }} >
                                            <Text style={styles.header}>{this.state.merchant.name}</Text>
                                            {address.map((a, i) =>
                                                <Text key={'add' + i} style={styles.address} wrap={false}>{a}</Text>
                                            )}
                                        </View>
                                    </View>
                                </View> */}
                                <View style={styles.titleContainer}>
                                    <View style={styles.row}>
                                        <View style={styles.col} >
                                            <View style={styles.title}>
                                                <Text>{i18n.t("Laporan Neraca")}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.data.month + ' ' + dataPrint.data.year}</Text>
                                            </View>
                                            <View style={styles.title}>
                                                <Text>{dataPrint.data.branch}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={[styles.titleContainer, { marginTop: '10px' }]}>
                                    <View style={[styles.tableTitle, { width: '50%' }]}  >
                                        <View style={styles.trHeader}>
                                            <Fragment key={'menuHeader'}>
                                                <View style={[styles.td, { width: '60%' }]}>
                                                    <Text style={styles.menuHeader}>{"Aktiva"}</Text>
                                                </View>
                                                <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                    <Text style={styles.menuHeader}>{"Jumlah"}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                    <View style={[styles.tableTitle, { width: '50%' }]}  >
                                        <View style={styles.trHeader}>
                                            <Fragment key={'menuHeader'}>
                                                <View style={[styles.td, { width: '60%' }]}>
                                                    <Text style={styles.menuHeader}>{"Passiva"}</Text>
                                                </View>
                                                <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                    <Text style={styles.menuHeader}>{"Jumlah"}</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.titleContainer}>
                                    <View style={[styles.tableTitle, { width: '50%' }]}>
                                        {dataPrint.debt.map((awal, i) =>
                                            <Fragment>
                                                <View style={styles.tr}>
                                                    <View style={[styles.td, { width: '60%' }]}>
                                                        <Text style={styles.fill}>{awal.name_coa1}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                        <Text style={styles.fillNumberBold}></Text>
                                                    </View>
                                                </View>
                                                {awal.coa2.map((dataDebt, j) =>
                                                    <View style={styles.tr}>
                                                        <Fragment key={'aktiva' + j}>
                                                            <View style={[styles.td, { width: '60%' }]}>
                                                                <Text style={styles.fillText}>{dataDebt.name_coa2}</Text>
                                                            </View>
                                                        </Fragment>
                                                        <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                            <Text style={styles.fillNumber}>{dataDebt.total_show_format_no}</Text>
                                                        </View>
                                                    </View>
                                                )}

                                            </Fragment>
                                        )}
                                        <View style={styles.tr}>
                                            <Fragment key={'jumlahAktiva'}>
                                                <View style={[styles.td, { width: '60%' }]}>
                                                    <Text style={styles.fill}>{"Total Aktiva"}</Text>
                                                </View>
                                            </Fragment>
                                            <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                <Text style={styles.fillNumberBold}>{totalAktiva < 0 ?
                                                    '(' + (totalAktiva * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                    totalAktiva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                }</Text>
                                            </View>

                                        </View>
                                    </View>

                                    <View style={[styles.tableTitle, { width: '50%' }]}>
                                        {dataPrint.credit.map((awal, i) =>
                                            <Fragment>
                                                <View style={styles.tr}>
                                                    <Fragment key={'pasiva' + i}>
                                                        <View style={[styles.td, { width: '60%' }]}>
                                                            <Text style={styles.fill}>{awal.name_coa1}</Text>
                                                        </View>
                                                    </Fragment>
                                                    <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                        <Text style={styles.fillNumberBold}></Text>
                                                    </View>
                                                </View>
                                                {awal.coa2.map((dataCredit, j) =>
                                                    <View style={styles.tr}>
                                                        <Fragment key={'dataPasiva' + j}>
                                                            <View style={[styles.td, { width: '60%' }]}>
                                                                <Text style={styles.fillText}>{dataCredit.name_coa2}</Text>
                                                            </View>
                                                        </Fragment>
                                                        <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                            <Text style={styles.fillNumber}>{dataCredit.total_show_format_no}</Text>
                                                        </View>
                                                    </View>
                                                )}
                                            </Fragment>
                                        )}
                                        <View style={styles.tr}>
                                            <Fragment key={'jumlahPasiva'}>
                                                <View style={[styles.td, { width: '60%' }]}>
                                                    <Text style={styles.fill}>{"Total Pasiva"}</Text>
                                                </View>

                                                <View style={[styles.td, { width: '40%', borderRight: '1px' }]}>
                                                    <Text style={styles.fillNumberBold}>{totalPasiva < 0 ?
                                                        '(' + (totalPasiva * (-1)).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + ')' :
                                                        totalPasiva.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                                    }</Text>
                                                </View>
                                            </Fragment>
                                        </View>
                                    </View>
                                </View>

                            </View>
                        </Page>

                    </Document>
                </PDFViewer>
                :
                this.renderMessage()}

            </React.Fragment>

        )
    }
}

export default PrintBalanceSheet;

const loader = {
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}