import React, { Component, Fragment } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/master/branch/form";
import FormContainerView from "../../../../containers/modules/log/customer-approval/form-view";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCheck,
  faSearch,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import LogRequest from "../../../utilities/Status/LogRequest";
import i18n from "../../../../i18n";
import { StatusLogRequest } from "../../../utilities/Const/StatusActive";
import LabelApprove from "../../../utilities/Status/approve-customer";
import { customerApproval } from "../../../utilities/Const/TranscationCode";
const MySwal = withReactContent(Swal);
export class CustomerApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        {
          link: process.env.APP_URL,
          title: i18n.t("Home"),
          class: " text-gray-600",
        },
        { link: null, title: i18n.t("Log"), class: " text-gray-600" },
        { link: null, title: i18n.t("Customer Approval"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "code",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Code Transaction"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("User Request"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: customerApproval,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "text-left",
          component: (data, column) => {
            return <LabelApprove value={data[column.key]} />;
          },
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <div className="badge badge-light-success">
                  {data.created_by}
                </div>
                <br />
                <div className="badge badge-light-info">{data.created_at}</div>
              </Fragment>
            );
          },
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            return (
              <Fragment>
                <div className="badge badge-light-success">
                  {data.updated_by}
                </div>
                <br />
                <div className="badge badge-light-info">{data.updated_at}</div>
              </Fragment>
            );
          },
        },
        {
          key: "approveAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Approve / Reject At"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {
            const statusArr = [1, 2, 3];
            if (statusArr.includes(data.status)) {
              let classStatus = "badge badge-light-success";
              if (data.status == 2) {
                classStatus = "badge badge-light-danger";
              }
              return (
                <Fragment>
                  <div className={classStatus}>{data.approved_by}</div>
                  <br />
                  <div className="badge badge-light-info">
                    {data.approved_at}
                  </div>
                  <br />
                  {data.status == 2 ? (
                    <OverlayTrigger
                      placement="top"
                      key={data.id}
                      trigger="hover"
                      className="bg-dark"
                      overlay={
                        <Tooltip id={`tooltip-${data.id}`}>
                          {data.ifReject}
                        </Tooltip>
                      }
                    >
                      <Button variant="none" size="sm">
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </OverlayTrigger>
                  ) : null}
                </Fragment>
              );
            }
          },
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "component",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          component: (data, column) => {

            if (data.status == 1) {
              return (
                <Fragment>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => this.viewData(data.id)}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  {/* <Button
                    variant="danger"
                    size="sm"
                    onClick={() => this.rejectData(data.id)}
                  >
                    <FontAwesomeIcon icon={faBan} />
                  </Button> */}
                </Fragment>
              );
            }
          },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.approveData = this.approveData.bind(this);
    this.rejectData = this.rejectData.bind(this);
    this.viewData = this.viewData.bind(this);
  }

  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  viewData(id) {
    this.state.titleModal = i18n.t("Customer");
    this.props.viewItem(id);
  }

  approveData(id) {
    const html = (
      <>
        <div>{i18n.t("Approve this Request")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            this.props.ApproveData(id, this.state.search);
            MySwal.update({
              title: i18n.t("Approve Request"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  rejectData(id) {
    const html = (
      <>
        <div>{i18n.t("Reject This Request")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Request"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.RejectData(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Reject")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table 
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainerView
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default CustomerApproval;
