import {  faPersonCircleCheck, faPersonPraying, faPersonWalkingLuggage, faStore, faWalking } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React,{ useState } from "react"
// import ModalCustom from "./modal"
import { Button } from "react-bootstrap"
import TypeLogTx from "../../../utilities/Const/typeLogTx"
import i18n from "../../../../i18n"

export default function Customer(props){
  console.log('INI',props)
    return (
      <div className="timeline timeline-border-dashed">
         {props.data != null ? 
          <table >
            <thead>
            </thead>
          
              <tbody style={{fontSize:'16px', fontWeight:'bold'}}>
                <tr >
                  <td>{i18n.t("Customer Code")}</td>
                  <td>:</td><td>{props.data?.code||''}</td>
                </tr>
                <tr >
                  <td>{i18n.t("Customer Name")}</td>
                  <td>:</td><td>{props.data?.name||''}</td>
                </tr>
                <tr >
                  <td>{i18n.t("Address")}</td>
                  <td>:</td><td>{props.data?.address||''}</td>
                </tr>
                <tr >
                  <td>{i18n.t("Phone")}</td>
                  <td>:</td><td>{i18n.t(props.data?.phone||'')}</td>
                </tr>
            </tbody>
          </table>
          :null}
      </div>
    )
}