import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import SettlementSalesMiddleware from '../../../../middleware/modules/mutation/settlement-sales';
import i18n from '../../../../i18n';
import { Helmet } from 'react-helmet';
// import  FormSettlementSales from "../../../../components/modules/mutation/settlement-sales/form";
import DataSettlementSales from "../../../../components/modules/mutation/settlement-sales/data";
// middlewares
const mapStateToProps = ({ settlementSales, }) => ({
  isProcessing: settlementSales.isProcessing,
  isError: settlementSales.isError,
  errorMessage: settlementSales.errorMessage,
  helperList:settlementSales.helperSettlementSales,
  data: settlementSales.data,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getHelper: () => SettlementSalesMiddleware.getHelper(),
      getData: (data) => SettlementSalesMiddleware.getData(data),
      settlement: (data) => SettlementSalesMiddleware.beforeSaveSettlement(data),
    },
    dispatch
  );
};
class FormSettlementSalesContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      show: 0,
    };
    this.getData = this.getData.bind(this);
    this.settlement = this.settlement.bind(this);
  }

  getData() {
    this.props.getData();
  }

  settlement(data) {
    this.props.settlement(data);
  }

  componentWillMount() {
    this.props.getHelper();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {i18n.t("Stock Card List")}
          </title>
        </Helmet>
      <DataSettlementSales
        {...this.props}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormSettlementSalesContainer);
