import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18n from "../../../../i18n";
import Select from "react-select";
import TextArea from "../../../utilities/Form/TextArea";
import {
  faArrowDown,
  faArrowUp,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import ArrayMove from "../../../utilities/Class/ArrayMove";
export class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [{ user: null, remark: null }],
      reload: 0,
    };
    this.handlerSelectArray = this.handlerSelectArray.bind(this);
    this.handlerInputArray = this.handlerInputArray.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  handlerSelectArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    if (e == null) {
      objectss[name] = null;
    } else {
      objectss[name] = e;
    }
    this.setState(arr);
  }
  handlerInputArray(e, name, i, arrayname) {
    const arr = [...this.state[arrayname]];
    const objectss = arr[i];
    var value = e.target.value;
    objectss[name] = value;
    this.setState(arr);
  }

  renderBody(detail, i) {
    const { data, employeeList } = this.props;
    return (
      <React.Fragment key={"detail" + i}>
        <tr>
          <td align="center">
            <label>{i + 1}</label>
          </td>
          <td>
            <Select
              isClearable={true}
              name={"userSelect"}
              required={true}
              className={""}
              size={4}
              onChange={async (e) => {
                let check = [];
                if (e != null) {
                  check = this.state.data.filter((row, pp) => {
                    if (pp !== i && e.value == row.user.value) {
                      return row;
                    }
                  });
                }

                if (check.length == 0) {
                  this.handlerSelectArray(e, "user", i, "data");
                } else {
                  alert("Karyawan sudah di pilih");
                }
              }}
              options={employeeList}
              value={detail.user}
              placeholder={i18n.t("Employee Name")}
            />
          </td>
          <td>
            <textarea
              name={"description"}
              title={i18n.t("Note")}
              value={detail.remark}
              className={"form-control form-control-md"}
              onChange={(e) => this.handlerInputArray(e, "remark", i, "data")}
            ></textarea>
          </td>
          <td align="center">
            {i != 0 ? (
              <Button
                variant="secondary"
                className="btn btn-sm m-1"
                onClick={(e) => this.upArray(i, "data")}
              >
                <FontAwesomeIcon icon={faArrowUp}></FontAwesomeIcon>
              </Button>
            ) : null}
            {i != this.state.data.length - 1 ? (
              <Button
                variant="secondary"
                className="btn btn-sm m-1"
                onClick={(e) => this.downArray(i, "data")}
              >
                <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
              </Button>
            ) : null}

            <Button
              variant="danger"
              className="btn btn-sm m-1"
              onClick={(e) => this.removeArray(i, "data")}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </Button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
  addArray(value, key) {
    const newRow = value;
    const arr = [...this.state[key], newRow];
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  upArray(value, key) {
    let result = ArrayMove(this.state.data, value, value - 1);
    this.setState({
      ...this.state,
      [key]: result,
    });
  }
  downArray(value, key) {
    let result = ArrayMove(this.state.data, value, value + 1);
    this.setState({
      ...this.state,
      [key]: result,
    });
  }
  removeArray(i, key) {
    const arr = this.state[key].filter((obj, index) => {
      return index !== i;
    });
    this.setState({
      ...this.state,
      [key]: arr,
    });
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return value;
  }
  submitForm() {
    let idUser = [];
    let remark = [];
    let data = this.state.data;
    for (var p = 0; p < data.length; p++) {
      if (data[p].user != null) {
        idUser.push(data[p].user.value);
        remark.push(data[p].remark);
      }
    }
    const result = {
      idUser: idUser,
      remark: remark,
    };

    this.props.onChange(result);
  }
  render() {
    const { helperList, data, isProcessing } = this.props;
    if (data != null && this.state.reload == 0) {
      let dataList = [];
      for (var oo = 0; oo < data.length; oo++) {
        const items = data[oo];
        const objectUser = { value: items.idUser, label: items.name };
        dataList.push({ user: objectUser, remark: items.remark });
      }
      this.state.data = dataList;
      this.state.reload = 1;
    }
    console.log(this.state);
    return (
      <Fragment>
        <table className="table table-responsive table-hover table-bordered">
          <thead style={{backgroundColor:'#A4CABC',textAlign:'center'}}>
            <tr>
              <th className="text-center">{i18n.t("No")}</th>
              <th>{i18n.t("Employee Name")}</th>
              <th>{i18n.t("Remark")}</th>
              <th className="text-center">{i18n.t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((data, i) => this.renderBody(data, i))}
          </tbody>
        </table>
        <div className={(this.state.data.length >=4)?"d-flex justify-content-end":"d-flex justify-content-between"}>
          {(this.state.data.length >=4)?null:<Button
            variant="success"
            onClick={(e) =>
              this.addArray(
                {
                  user: null,
                  remark: null,
                },
                "data"
              )
            }
          >
            {i18n.t("Add User")}
          </Button>}
          

          <Button className="flex-end" variant="success" onClick={(e) => this.submitForm()}>
            {i18n.t("Save")}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default Table;
