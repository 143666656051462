import React, { Component } from "react";
import i18n from "../../../../i18n";
import Input from "../../../utilities/Form/input";
import Select2 from "../../../utilities/Form/Select2";
import TextArea from "../../../utilities/Form/TextArea";
import Radio from "../../../utilities/Form/radio";
import {
  StatusForm,
} from "../../../utilities/Const/StatusActive";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import SwitchButton from "../../../utilities/Form/SwitchButton";
import cookieManager from "../../../../utils/cookieManager";
import axios from "axios";
import genRandonString from "../../../utilities/Class/RandomString";
import InputImage from "../../../utilities/Form/InputImage";
export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        idBranch: null,
        idStore: null,
        idPosition: null,
        idSupervisor: null,
        code: "",
        address: "",
        idProvince: null,
        idCity: null,
        phone: "",
        email: "",
        idNumber: "",
        npwp: "",
        startDate: null,
        idCategory: null,
        startContract: null,
        endContract: null,
        idClass: null,
        bpjsNo: "",
        name: "",
        status: 1,
        createUser:false,
        avatar:null,
      },
      startDate:new Date(),
      startContract:new Date(),
      endContract:new Date(),
      code:null,
      createUser:false,
      coverageList: [],
      coverageCurrent:[],
      coverageText:i18n.t("Select Coverage Area"),
      supervisorList: [],
      supervisorCurrent: null,
      supervisorText: i18n.t("Select Supervisor"),
      provinceList: [],
      provinceCurrent: null,
      provinceText: i18n.t("Select Province"),
      cityList: [],
      cityCurrent: null,
      cityText: i18n.t("Select City"),
      categoryList: [],
      categoryCurrent: null,
      categoryText: i18n.t("Select Category"),
      classSalaryList: [],
      classSalaryCurrent: null,
      classSalaryText: i18n.t("Select Category"),
      branchList: [],
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      storeList: [],
      storeCurrent: null,
      storeText: i18n.t("Select Store"),
      positionList: [],
      positionCurrent: null,
      positionText: i18n.t("Select Position"),
      isUpdate: 0,
      avatarUpdate:0,
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerCurrency = this.handlerCurrency.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this)
    this.createUser = this.createUser.bind(this)
    this.runSubmit = this.runSubmit.bind(this);
  }

    createUser(e){
        var username=this.state.form.code;
        username=username.replace(/[^a-zA-Z0-9 ]/g, '');
        var password=genRandonString(8);
        this.setState({
          ...this.state,
          createUser:!this.state.createUser,
          form: {
            ...this.state.form,
            username: username,
            password: password,
          },
        });
    }
  handlerDateTime(e,name) {
    var key=name;
    var value=e
    this.setState({
        [key]:value
    })
}
  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  handlerCurrency(value, name) {
    var key = name;
    var value = value;
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [key]: value,
      },
    });
    this.setState({ [currentState]: e });
  }
  runSubmit() {
    this.state.form.coverage=this.state.coverageCurrent.map(cover=>cover.value)
    this.state.form.startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    this.state.form.startContract = moment(this.state.startContract).format('YYYY-MM-DD');
    this.state.form.endContract = moment(this.state.endContract).format('YYYY-MM-DD');
    this.state.form.createUser =this.state.createUser
    return this.state.form;
  }
  renderError(name) {
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage[name] != undefined) {
        value = errorMessage[name][0];
      }
    }
    return i18n.t(value);
  }

  checkErrorEmail(){
    const { isError, errorMessage } = this.props;
    var value = "";
    if (isError) {
      if (errorMessage.email != undefined) {
        value = errorMessage.email[0];
      }
      if (errorMessage.email_user != undefined) {
        value = errorMessage.email_user[0];
      }
    }
    return i18n.t(value);
  }
  changeData(keyFill,type=null,key='',array=null,arraykey=null,extraFunction=null){
    const token=cookieManager.getUserToken()
    let params=null
    switch(type){
        case 'getStore':
            var url=process.env.REST_URL +'helper/get-store'
            var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
            params={
                id:branch
            }
        break;
        case 'getCity':
            var url=process.env.REST_URL +'helper/get-city'
            var province=((this.state.provinceCurrent !=null)?this.state.provinceCurrent.value:null)
            params={
                id:province
            }
        break;
        case 'getSalary':
            var url=process.env.REST_URL +'helper/get-salary-class'
            var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
            params={
                branch:branch
            }
        break;
        case 'getCode':
            var url=process.env.REST_URL +'helper/get-code-employee'
            var date=((this.state.startDate !=undefined)?moment(this.state.startDate).format('YYYY-MM-DD') :null)
            var branch=((this.state.branchCurrent !=null)?this.state.branchCurrent.value:null)
            // var store=((this.state.storeCurrent !=null)?this.state.storeCurrent.value:null)
            params={
                date:date,
                branch:branch,
                // store:store
            }
        break;
        default:
            type=null
            break;
    }

    if(type !=null){
        axios({
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            },
            url:url,
            responseType: 'json',
            params:params
          })
          .then((response) => {
            if (response && response.status === 200 && response.data) {
                if(array!=null){
                    var arr = [...this.state[array]];
                    const objectss = arr[arraykey]
                    if(key==''){
                        objectss[keyFill] = response.data.result;
                    }else{
                        objectss[keyFill] = response.data.result[key];
                    }
                }else{
                    if(key==''){
                       var arr= {[keyFill]:response.data.result}
                    }else{
                        var arr= {[keyFill]:response.data.result[key]}
                    }
                }
                if(extraFunction!=null){
                    this.setState(arr,extraFunction)
                }else{
                    this.setState(arr)
                }
               
            }
          })
          .catch((error) => {
            console.log(error)
          });
    }

  }

  optionCoverage(defaultArea){
      let list=defaultArea.filter(item=>item.value !=this.state.form.idBranch)
      return list
  }

  render() {
    const { dataUpdate, isNewRecord, helperList } = this.props;
    // this.state.classSalaryList =
    //   helperList.salaryClass != undefined ? helperList.salaryClass : [];
    this.state.provinceList =
      helperList.province != undefined ? helperList.province : [];
    this.state.branchList =
      helperList.branch != undefined ? helperList.branch : [];
    this.state.supervisorList =
      helperList.supervisor != undefined ? helperList.supervisor : [];
    this.state.positionList =
      helperList.position != undefined ? helperList.position : [];
    this.state.categoryList =
      helperList.category != undefined ? helperList.category : [];
    if(helperList?.branch){
      let listCoverage=[{value:0,label:'ALL'}]
      this.state.coverageList=listCoverage.concat(helperList?.branch)
    }
    if (dataUpdate != null && !isNewRecord) {
      if (this.state.isUpdate == 0) {
        this.state.form.name = dataUpdate.name
        this.state.form.address = dataUpdate.name
        this.state.form.code = dataUpdate.code
        this.state.form.status = dataUpdate.status
        this.state.form.idBranch = dataUpdate.idBranch
        this.state.form.idStore = dataUpdate.idStore
        this.state.form.idPosition = dataUpdate.idPosition
        this.state.form.idSupervisor = dataUpdate.idSupervisor
        this.state.form.idCategory = dataUpdate.idCategory
        this.state.form.idClass = dataUpdate.idSalary
        this.state.startDate = new Date(dataUpdate.startDate)
        this.state.startContract = new Date(dataUpdate.startContract)
        this.state.endContract = new Date(dataUpdate.endContract)
        this.state.form.idProvince = dataUpdate.idProvince
        this.state.form.idCity = dataUpdate.idCity
        this.state.form.phone = dataUpdate.phone
        this.state.form.idNumber =dataUpdate.idNumber
        this.state.form.email = dataUpdate.email
        this.state.form.npwp = dataUpdate.npwp
        this.state.form.bpjsNo = dataUpdate.bpjsNo
        this.state.form.avatar = dataUpdate.avatar
        this.state.isUpdate = 1

        this.state.branchCurrent=null
        if(dataUpdate.idBranch!=null){
            this.state.branchCurrent={value:dataUpdate.idBranch,label:dataUpdate.branchName}
            this.changeData('storeList','getStore','');
            this.changeData('classSalaryList','getSalary','');
        }

        this.state.storeCurrent=null
        if(dataUpdate.idStore!=null){
            this.state.storeCurrent={value:dataUpdate.idStore,label:dataUpdate.storeName}
        }

        this.state.categoryCurrent=null
        if(dataUpdate.idCategory!=null){
            this.state.categoryCurrent={value:dataUpdate.idCategory,label:dataUpdate.categoryName}
        }

        this.state.classSalaryCurrent=null
        if(dataUpdate.idSalary!=null){
            this.state.classSalaryCurrent={value:dataUpdate.idSalary,label:dataUpdate.salaryName}
        }

        this.state.positionCurrent=null
        if(dataUpdate.idPosition!=null){
            this.state.positionCurrent={value:dataUpdate.idPosition,label:dataUpdate.positionName}
        }

        this.state.supervisorCurrent=null
        if(dataUpdate.idSupervisor!=null){
            this.state.supervisorCurrent={value:dataUpdate.idSupervisor,label:dataUpdate.supervisorName}
        }

        this.state.provinceCurrent=null
        if(dataUpdate.idProvince!=null){
            this.state.provinceCurrent={value:dataUpdate.idProvince,label:dataUpdate.provinceName}
            this.changeData('cityList','getCity','');
        }

        this.state.cityCurrent=null
        if(dataUpdate.idCity!=null){
            this.state.cityCurrent={value:dataUpdate.idCity,label:dataUpdate.cityName}
        }

        this.state.coverageCurrent=[]
        if(dataUpdate.coverage!=null){
            this.state.coverageCurrent=this.state.coverageList.filter((cover)=>dataUpdate?.coverage.includes(cover.value.toString()))
          console.log('coverage',dataUpdate.coverage)
          console.log('coverage Current',this.state.coverageCurrent)
          console.log('coverage List',this.state.coverageList)
          }
      }
    }
    
    return (
      <form>
        <div className="row">
          <div className="row col-sm-10 mb-1">
            <div className="col-sm-6 mb-1">
              <Select2
                isClearable={true}
                name={"idBranch"}
                required={true}
                onChange={async (e) =>
                  {
                      if(e != this.state.branchCurrent){
                          await this.handlerSelect(e, "idBranch", "branchCurrent")
                          await this.handlerSelect([], "coverage", "coverageCurrent")
                          await this.handlerSelect(null, "idStore", "storeCurrent")
                          await this.changeData('storeList','getStore','');
                          await this.changeData('classSalaryList','getSalary','');
                          if(isNewRecord){
                              await this.changeData('code','getCode','code',null,null,()=>this.setState({
                                  ...this.state,
                                  form: {
                                    ...this.state.form,
                                    code: this.state.code,
                                  },
                                })
                              )
                          }
                          
                      }
                  }
                }
                options={this.state.branchList}
                title={i18n.t("Branch")}
                value={this.state.branchCurrent}
                placeholder={this.state.branchText}
                error={this.renderError("idBranch")}
              />
            </div>
            <div className="col-sm-6 mb-1">
              <Select2
                isClearable={true}
                name={"idStore"}
                required={false}
                onChange={(e) => this.handlerSelect(e, "idStore", "storeCurrent")}
                options={this.state.storeList}
                title={i18n.t("Store")}
                value={this.state.storeCurrent}
                placeholder={this.state.storeText}
                error={this.renderError("idStore")}
              />
            </div>
            <div className="col-sm-6 mb-1">
              <Select2
                isClearable={true}
                name={"idCategory"}
                required={true}
                onChange={(e) =>
                  this.handlerSelect(e, "idCategory", "categoryCurrent")
                }
                options={this.state.categoryList}
                title={i18n.t("Category Employee")}
                value={this.state.categoryCurrent}
                placeholder={this.state.categoryText}
                error={this.renderError("idCategory")}
              />
            </div>
          
            <div className="col-sm-6 mb-1">
              <DatePickerCustom
                name={"startDate"}
                title={i18n.t("Start Date Join")}
                required={true}
                selected={this.state.startDate}
                onSelect={async (e) => {
                  await this.handlerDateTime(e, "startDate");
                  if(isNewRecord){
                      await this.changeData('code','getCode','code',null,null,()=>this.setState({
                          ...this.state,
                          form: {
                            ...this.state.form,
                            code: this.state.code,
                          },
                        })
                      )
                  }
                }}
                onChange={(e) => {}}
                startDay={0}
                autoComplete={"off"}
                dateFormat="dd MMM yyyy"
                maxDate={new Date()}
                className="form-control form-control-sm"
              />
            </div>
          </div>
          <div className="col-sm-2 mb-1 text-center">
                <InputImage
                  defaultValue={this.state.form.avatar}
                  onChange={(e)=>this.setState({...this.state,
                    form:{
                      ...this.state.form,
                      avatar:e,
                      avatarUpdate:1
                    }})}
                ></InputImage>
          </div>
          
          <div className="col-sm-4 mb-1">
            <Select2
              isClearable={true}
              name={"idPosition"}
              required={true}
              onChange={(e) =>
                this.handlerSelect(e, "idPosition", "positionCurrent")
              }
              options={this.state.positionList}
              title={i18n.t("Position")}
              value={this.state.positionCurrent}
              placeholder={this.state.positionText}
              error={this.renderError("idPosition")}
            />
          </div>
          <div className="col-sm-4 mb-1">
            <Select2
              isClearable={true}
              name={"idSupervisor"}
              required={false}
              onChange={(e) =>
                this.handlerSelect(e, "idSupervisor", "supervisorCurrent")
              }
              options={this.state.supervisorList}
              title={i18n.t("Supervisor")}
              value={this.state.supervisorCurrent}
              placeholder={this.state.supervisorText}
              error={this.renderError("idSupervisor")}
            />
          </div>
          <div className="col-sm-4 mb-1">
            <Select2
              isMulti
              isClearable={true}
              name={"coverage"}
              required={false}
              onChange={(e) =>{
                  const hasZeroValue = e.some(item => item.value === 0);
                  if(hasZeroValue){
                    this.handlerSelect([{value:0,label:'ALL'}], "coverage", "coverageCurrent")
                  }else{
                    this.handlerSelect(e, "coverage", "coverageCurrent")
                  }
                }
              }
              options={this.optionCoverage(this.state.coverageList)}
              title={i18n.t("Extra Coverage Area")}
              value={this.state.coverageCurrent}
              placeholder={this.state.coverageText}
              error={this.renderError("coverage")}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"code"}
              title={i18n.t("Code Employee")}
              required
              value={this.state.form.code}
              handler={this.handler}
              error={this.renderError("code")}
              disabled={(!isNewRecord)?true:false}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"name"}
              title={i18n.t("Name")}
              required
              value={this.state.form.name}
              handler={this.handler}
              error={this.renderError("name")}
            />
          </div>
          <div className="col-sm-12 mb-1">
            <TextArea
              name={"address"}
              title={i18n.t("Address")}
              value={this.state.form.address}
              handler={this.handler}
              error={this.renderError("address")}
              required
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Select2
              isClearable={true}
              name={"idProvince"}
              required={true}
              onChange={async (e) =>{
                await this.handlerSelect(e, "idProvince", "provinceCurrent")
                await this.changeData('cityList','getCity','');
            }
              }
              options={this.state.provinceList}
              title={i18n.t("Province")}
              value={this.state.provinceCurrent}
              placeholder={this.state.provinceText}
              error={this.renderError("idProvince")}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Select2
              isClearable={true}
              name={"idCity"}
              required={true}
              onChange={(e) => this.handlerSelect(e, "idCity", "cityCurrent")}
              options={this.state.cityList}
              title={i18n.t("City")}
              value={this.state.cityCurrent}
              placeholder={this.state.cityText}
              error={this.renderError("idCity")}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"phone"}
              title={i18n.t("Phone")}
              required
              value={this.state.form.phone}
              handler={this.handler}
              error={this.renderError("phone")}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"email"}
              title={i18n.t("Email")}
              required
              value={this.state.form.email}
              handler={this.handler}
              error={this.checkErrorEmail()}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"idNumber"}
              title={i18n.t("ID Number")}
              required
              value={this.state.form.idNumber}
              handler={this.handler}
              error={this.renderError("idNumber")}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"npwp"}
              title={i18n.t("Npwp")}
              value={this.state.form.npwp}
              handler={this.handler}
              error={this.renderError("npwp")}
            />
          </div>
          
          <div className="col-sm-6 mb-1">
            <DatePickerCustom
              name={"startContract"}
              title={i18n.t("Start Contract")}
              required={true}
              selected={this.state.startContract}
              onSelect={async (e) => {
                await this.handlerDateTime(e, "startContract");
              }}
              onChange={(e) => {}}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              maxDate={new Date()}
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-6 mb-1">
            <DatePickerCustom
              name={"endContract"}
              title={i18n.t("End Contract")}
              required={true}
              selected={this.state.endContract}
              onSelect={async (e) => {
                await this.handlerDateTime(e, "endContract");
              }}
              onChange={(e) => {}}
              startDay={0}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              minDate={new Date()}
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Select2
              isClearable={true}
              name={"idClass"}
              required={true}
              onChange={(e) =>
                this.handlerSelect(e, "idClass", "classSalaryCurrent")
              }
              options={this.state.classSalaryList}
              title={i18n.t("Salary Class")}
              value={this.state.classSalaryCurrent}
              placeholder={this.state.classSalaryText}
              error={this.renderError("idClass")}
            />
          </div>
          <div className="col-sm-6 mb-1">
            <Input
              type={"input"}
              name={"bpjsNo"}
              title={i18n.t("BPJS No.")}
              value={this.state.form.bpjsNo}
              handler={this.handler}
              error={this.renderError("bpjsNo")}
            />
          </div>
          <div className="col-sm-3 mb-1">
            <Radio
                list={StatusForm}
                name={"status"}
                title={i18n.t("Status")}
                inline={false}
                value={this.state.form.status}
                handler={this.handler}
            />
            </div>

            {(isNewRecord)?
                <div className="col-sm-3 mb-1">
                  <label className="fw-semibold fs-6 mb-5">Create User</label>
                  <SwitchButton
                      labelfield={'false'} 
                      onChange={(e)=>this.createUser(e)}
                      name={'createUser'}
                      value={this.state.createUser}
                      checked={this.state.createUser?true:false}
                      className={'h-20px w-30px'}
                      position={'justify-content-left'}
                  ></SwitchButton>
              </div>
            :null}
        </div>
        {(this.state.createUser)?
          <div className="row">
            <div className="col-sm-6 mb-1">
              <Input
                type={"input"}
                name={"username"}
                title={i18n.t("Username")}
                value={this.state.form.username}
                handler={this.handler}
                error={this.renderError("username")}
              />
            </div>
            <div className="col-sm-6 mb-1">
              <Input
                type={"input"}
                name={"password"}
                title={i18n.t("Password")}
                value={this.state.form.password}
                handler={this.handler}
                error={this.renderError("password")}
              />
            </div>
          </div>
        :null}
        
        

      </form>
    );
  }
}

export default Form;
