import React, { Component } from 'react';
import Table from './table';
import Breadcrumbs from '../../../utilities/breadcrumbs';
import { Button } from 'react-bootstrap';
import FormContainer from '../../../../containers/modules/sales/payment-selling/form'
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckToSlot, faPrint, faSync } from '@fortawesome/free-solid-svg-icons';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../../i18n';

const MySwal = withReactContent(Swal)
export class PaymentSelling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: {
                limit: 10
            },
            titleModal: '',
            listData: [],
            breadcrumbs: [
                { link: process.env.APP_URL, title: i18n.t('Home'), class: ' text-gray-600' },
                { link: null, title: i18n.t('Sales'), class: ' text-gray-600' },
                { link: null, title: i18n.t('Payment Selling'), class: ' text-gray-500' },
            ],
            columns: [
                { key: 'customer', filter: { active: true, type: 'Text', data: null }, title: i18n.t('Customer Name'), type: 'text', tHclass: 'min-w-125px', tdClass: '' },
                { key: 'remaining', filter: { active: false, type: 'Text', data: null }, title: i18n.t('Remaining Payment'), type: 'text', tHclass: 'min-w-125px', tdClass: '' },
                {
                    key: 'id', filter: { active: false, type: 'Text', data: null }, title: i18n.t('Action'), type: 'component', tHclass: 'min-w-125px', tdClass: '', renderFunc: (data) =>
                        <React.Fragment>
                            <Button variant='success' className='btn btn-sm m-2' onClick={() => this.multiplePaymentSelling(data)}>
                                <FontAwesomeIcon icon={faCheckToSlot}></FontAwesomeIcon>
                                {i18n.t("Mutiple Payment")}
                            </Button>
                        </React.Fragment>
                },
            ],
            expandItem: {
                key: "details",
                columns: [
                    { label: i18n.t("No Invoice"), key: "invoiceNo" },
                    { label: i18n.t("Branch"), key: "branch" },
                    { label: i18n.t("Store"), key: "store" },
                    { label: i18n.t("Total"), key: "totalDisp", thClass: "text-end", tdClass: "text-end" },
                    { label: i18n.t("Payment"), key: "paymentDisp", thClass: "text-end", tdClass: "text-end" },
                    { label: i18n.t("Remaining"), key: "remainingDisp", thClass: "text-end", tdClass: "text-end" },
                    {
                        label: '', type: 'action', key: 'invoiceID', renderFunc: (data) =>
                            <React.Fragment>
                                <Button variant='success' className='btn btn-sm' onClick={() => this.payment(data)}>
                                    <FontAwesomeIcon icon={faCheckToSlot}></FontAwesomeIcon>
                                    {i18n.t("Payment")}
                                </Button>
                            </React.Fragment>
                    }
                ],
            }
        };
        this.tablePaymentSelling = React.createRef()
    }
    multiplePaymentSelling(data) {
        this.props.payment(data, 'multiple')
    }
    payment(data) {
        this.props.payment(data, 'single')
    }
    runSearch() {
        let search = this.tablePaymentSelling.current.getStateSearch();
        this.props.search(search);
    }
    render() {
        const { showModal } = this.props
        return (
            <div id="invoice-page">
                <Breadcrumbs
                    url={this.state.breadcrumbs}
                    tools={false}
                />
                <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
                    <div className="content flex-row-fluid" id="kt_content">
                        <div className="card">
                            <div className="card-header d-flex justify-content-end border-0 pt-6">
                                <div className="card-toolbar">
                                    <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                        <Button type="button" className="btn btn-warning m-1" onClick={(e) => this.runSearch()}><FontAwesomeIcon icon={faSync}></FontAwesomeIcon></Button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <Table {...this.props} runSearch={() => { this.runSearch() }} columns={this.state.columns} theExpand={this.state.expandItem} ref={this.tablePaymentSelling}></Table>
                            </div>
                        </div>
                    </div>
                </div>
                <FormContainer
                    showModal={showModal}
                    search={this.state.search}
                    titleModal={this.state.titleModal}
                />
            </div>
        );
    }
}

export default PaymentSelling;
