import React, { Component, Fragment } from "react";
import TableStockCard from "./table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import TableSettlementSales from "./table";
import FormSettlementSales from "./form";
import { Button } from "react-bootstrap";
import cookieManager from "../../../../utils/cookieManager";
import DatePickerCustom from "../../../utilities/Form/datepicker";
import Select2 from "../../../utilities/Form/Select2";
import DetailJournal from "../../accounting/journal/detail";

const MySwal = withReactContent(Swal);
export class DataSettlementSales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form:{
        List:[],
        Header:{},
      },
      click: 0,
      button:false,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      columns: [
        {
          key: "transaction_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. Transaction"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "name_branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Name Branch"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "name_sales",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Name Sales"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "name_customer",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Name Customer"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "payment_type",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Payment Type"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
        {
          key: "total_format",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Total"),
          type: "text",
          tHclass: "",
          tdClass: "",
        },
       
      ],
      // date: new Date('2025-01-13'),
      date: new Date(),
      endDate: new Date(),
      dateRange: new Date(),
      branchCurrent: null,
      branchText: i18n.t("Select Branch"),
      branchList: [],
      loadingItem:false,
      headeTable:false,
      transactionList : [
        {value : 'PJ' , label:'Penjualan'},
        {value : 'SO' , label:'Sales Order'}
      ],
      itemCurrent: null,
      itemText: i18n.t("Select Transaction"),
      itemList: [],
      loadingSupplier:false,
      warehouseCurrent: null,
      warehouseList: [],
      warehouseText: i18n.t("Select Warehouse"),
      data: [],
    };
    this.handler = this.handler.bind(this);
    this.handlerSelect = this.handlerSelect.bind(this);
    this.handlerDateTime = this.handlerDateTime.bind(this);
    this.show = this.show.bind(this);
  }

  handler(e) {
    var key = e.target.name;
    var value = e.target.value;
    this.setState({
      [key]: value,
    });
  }
  handlerSelect(e, name, currentState) {
    var key = name;
    var value = null;
    if (e != null) {
      value = e.value;
    }
    this.setState({
      ...this.state,
      [key]: value,
    });
    this.setState({ [currentState]: e });
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.setState({
      [key]: value,
    });
  }

  renderError(name){
    const {isError,errorMessage}=this.props
    var value=''
    if(isError){
        if(errorMessage[name] !=undefined){
            value=errorMessage[name][0]
        }
    }
    return i18n.t(value);
}

changeData({keyFill, type = null, key="", array = null, arraykey = null,callback=()=>{}}=props){
  const token=cookieManager.getUserToken()
  let params=null
  switch(type){
    case "getItemBranch":
          var url = process.env.REST_URL + "helper/get-item-good-stock";
          var branch =
              this.state.branchCurrent != undefined
                  ? this.state.branchCurrent.value
                  : null;
          params = {
              branch: branch,
          };
          break;
  }

  if (type != null) {
      axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: url,
        responseType: "json",
        params: params,
      })
        .then((response) => {
          if (response && response.status === 200 && response.data) {
            let dataResponse=null
            if (array != null) {
              var arr = [...this.state[array]];
              
              const objectss = arr[arraykey];
              if (key == "") {
                dataResponse=response.data.result
                objectss[keyFill] = response.data.result;
              } else {
                dataResponse=response.data.result[key]
                objectss[keyFill] = response.data.result[key];
              }
            } else {
              if (key == "") {
                dataResponse=response.data.result
                var arr = { [keyFill]: response.data.result };
              } else {
                dataResponse=response.data.result[key]
                var arr = { [keyFill]: response.data.result[key] };
              }
            }
            this.setState(arr, async () =>callback(dataResponse));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

}

  show() {
    const params = {
      idBranch : this.state.branchCurrent != null ? this.state.branchCurrent.value : null,
      date : moment(this.state.date).format("YYYY-MM-DD"),
      // endDate : moment(this.state.endDate).format("YYYY-MM-DD"),
      idItem : "PJ",
      limit : 10,
      page : 1
    }
    this.props.getData(params);
  }

  renderTable() {
    if(this.state.listData != null && this.state.listData != [] && this.state.listData != undefined){
      let data = this.state.listData.data;

      if(data != null && data != [] && data != undefined){
        return(
          <Fragment >
            <TableSettlementSales 
              {...this.props} 
              checkAble={true}
              data={this.state.listData}
              columns={this.state.columns} 
              expandAble={true}
              theExpand={{
                key: "selling_detail",
                columns: [
                  { label: i18n.t("Name Item"), key: "name_item" },
                  { label: i18n.t("Unit"), key: "name_unit" },
                  { label: i18n.t("Qty"), key: "qty_format" },
                  { label: i18n.t("Nett Price"), key: "nett_price_format" },
                  { label: i18n.t("Total"), key: "total_format" },
                  
                ],
              }}
              >

            </TableSettlementSales>
          </Fragment>
        );
      }else{
        // return (
        //   <div className="text-center">{ i18n.t("Data Not Found")} </div>
        // );
      }
    }
  }

  saveSettlement() {
    const Header = {
      idBranch: this.state.branchCurrent != null ? this.state.branchCurrent.value : null,
      date : moment(this.state.date).format("YYYY-MM-DD"),
    }
    const detail = this.state.listData.data.filter((item) => item.check == true);
        let idInvoice = []
        let totalInvoice = []
        let idBranch = []
        let idCustomer = []

        for (var i = 0; i < detail.length; i++) {
            idInvoice.push(detail[i].id_invoice);
            totalInvoice.push(detail[i].total);
            idBranch.push(detail[i].id_branch);
            idCustomer.push(detail[i].id_customer);
        }
        this.state.form.Header = Header;
        this.state.form.List = {
            idInvoice: idInvoice,
            totalInvoice: totalInvoice,
            idBranch: idBranch,
            idCustomer: idCustomer,
        }
      if(detail.length == 0){
        MySwal.fire({
          icon: "error",
          title: i18n.t("Error"),
          text: i18n.t("Please select at least one data")
        });
        return;
      }    
    const html = (
      <>
        <div>{i18n.t("Are you sure to save this Settlement")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.settlement(this.state.form);
          }}
        >
          {i18n.t("Save")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  
  render() {
    const { isProcessing, data,helperList } = this.props;
    if(data != null && data != undefined){
      this.state.listData = data;
    }
    if(helperList != null){
      this.state.branchList = (helperList.branch != undefined)?helperList.branch:[];
      this.state.supplierList = (helperList.supplier != undefined)?helperList.supplier:[];
    }
    console.log('PROPS',this.props)
    console.log('STATE',this.state)

    return (
      <div id="invoice-page">
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-body pt-8">
                  {/* <FormSettlementSales
                    {...this.props}
                    data={data}
                  /> */}
                    <React.Fragment>
                      <form>
                        <div className="row">
                          <div className="col-md-3 mt-3">
                              <DatePickerCustom
                                  name={"date"}
                                  title={i18n.t("Date Period")}
                                  selected={this.state.date}
                                  onSelect={async (e) => {
                                    await this.handlerDateTime(e, 'date');
                                  }}
                                  showYearDropdown
                                  yearDropdownItemNumber={15}
                                  scrollableYearDropdown
                                  peekNextMonth
                                  showMonthDropdown
                                  onChange={(e) => { }}
                                  startDay={0}
                                  autoComplete={"off"}
                                  maxDate={new Date()}
                                  dateFormat="dd MMMM yyyy"
                                  className="form-control form-control-sm"
                                  required

                                />
                          </div>
                          <div className="col-md-4 mt-3">
                            <Select2
                                isClearable={true}
                                name={"idBranch"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "idBranch", "branchCurrent");
                                    await this.setState({
                                      loadingItem: true,
                                    });
                                    this.changeData({
                                      keyFill: "itemList",
                                      type: "getItemBranch",
                                      key: "",
                                      callback: () => { this.setState({ loadingItem: false }) }
                                    })
                                }}
                                options={this.state.branchList}
                                title={i18n.t("Branch")}
                                value={this.state.branchCurrent}
                                placeholder={this.state.branchText}
                                labelfield={"true"}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError('idBranch')}
                            </div>
                          </div>
                          {/* <div className="col-md-4 mt-3">
                            <Select2
                                isClearable={true}
                                name={"idItem"}
                                required={true}
                                size={4}
                                onChange={async (e) => {
                                    await this.handlerSelect(e, "idItem", "itemCurrent");
                                }}
                                options={this.state.transactionList}
                                title={i18n.t("Transaction")}
                                value={this.state.itemCurrent}
                                placeholder={this.state.itemText}
                                labelfield={"true"}
                                isLoading={this.state.loadingItem}
                            />
                            <div className="fv-plugins-message-container invalid-feedback">
                                {this.renderError('idItem')}
                            </div>
                          </div> */}
                        </div>
                        <div className="row">
                          <div className="col-md-4 mt-3">
                            <Button
                              variant="success"
                              className="btn btn-sm"
                              onClick={async () => {
                                await this.show();
                                // await this.setState({ headeTable: true })
                              }}
                            >
                              {" "}
                              {i18n.t("Show")}
                            </Button>
                          </div>
                        </div>
                        <hr />
                        {/* {this.state.headeTable == true ?
                        <React.Fragment>
                          <div className={isProcessing ? " table-loading" : ""}>
                          {!isProcessing ? (
                          <Fragment>
                            <table className="table table-responsive table-bordered table-hover" style={{ borderBottom: '1px solid #eff2f5'}}>
                              <thead>
                                <tr className="table-header">
                                  <th width={"3%"} className="text-center">{i18n.t("No.")}</th>
                                  <th width={"12%"}>{i18n.t("Date")}</th>
                                  <th width={"13%"}>{i18n.t("Supplier Name")}</th>
                                  <th width={"12%"}>{i18n.t("No. Journal")}</th>
                                  <th width={"19%"} >{i18n.t("Remark")}</th>
                                  <th width={"12%"}>{i18n.t("Debt")}</th>
                                  <th width={"12%"}>{i18n.t("Credit")}</th>
                                  <th width={"12%"}>{i18n.t("Balance")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.renderList()}
                              </tbody>
                            </table>
                            {this.renderPagination()}
                            </Fragment>
                            ):(
                              <div className="table-loading-message">
                                <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                                <div className="text-center">{i18n.t("Loading")}</div>
                              </div>
                            )
                          }
                          </div>
                        </React.Fragment> :
                        ''} */}
                      </form>
                    </React.Fragment>
                 {isProcessing ?<div className="text-center">
                    {/* <div className="table-loading-message">   */}
                      <div className="spinner-border  text-primary text-center  clearfix"></div>
                      <div className="text-center">{i18n.t("Loading")} </div>
                    {/* </div> */}
                
                  </div>:null}
              </div>
             
            </div>
            { data != null && data != undefined? this.renderTable() : ""}
            <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base" style={{paddingRight:'10px'}}>
            { data.data != null && data.data != []?
              <Fragment>
                <Button
                  type="button"
                  className="btn btn-primary mr-50"
                  onClick={(e) => this.saveSettlement()}
                >
                  {i18n.t("Settlement")}
              </Button>
            </Fragment>:""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataSettlementSales;
