import React, { Component, Fragment, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/master/setup-hierarchy/form";
import Swal from "sweetalert2";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withReactContent from "sweetalert2-react-content";
import LabelActive from "../../../utilities/Status/active";
import i18n from "../../../../i18n";
const MySwal = withReactContent(Swal);

export class SetupHierarchy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      swal: "",
      count: 0,
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Master"), class: " text-gray-600" },
        { link: null, title: i18n.t("Setup Hierarchy"), class: " text-gray-500" },
      ],
      column: [
        
        {
            key: "type",
            filter: { active: true, type: "Text", data: null },
            title: i18n.t("Customer Type"),
            type: "text",
            tHclass: "min-w-200px",
            tdClass: "",
          },
          // {
          //   key: "hierarchy",
          //   filter: { active: true, type: "Text", data: null },
          //   title: i18n.t("Customer Hierarchy"),
          //   type: "text",
          //   tHclass: "min-w-200px",
          //   tdClass: "",
          // },
        {
            key: "status",
            filter: {
              active: true,
              type: "Select",
              data: [
                  { key: '', label: i18n.t("All") },
                  { key: 1, label: i18n.t("Active") },
                  { key: 0, label: i18n.t("Non Active") },
              ],
            },
            title: i18n.t("Status"),
            type: "component",
            tHclass: "min-w-125px",
            tdClass: "",
            component:(data,column)=>{
                  return <LabelActive value={data[column.key]} />
            }
          },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "type_id",
          filter: {
            active: false,
          },
          title: i18n.t("Action"),
          type: "action",
          tHclass: "text-center min-w-150px",
          tdClass: "text-center",
          form:"icon",
          buttons: [
            {
              key: "type_id",
              btnText: i18n.t("Edit"),
              visible: {
                key: "status",
                params: [],
                function: (idType, params) => {
                  const {authUser}=this.props
                  if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
                    let route=authUser.userAccess.route
                    if(route.includes('Customer Setup Hierarchy Update')){
                      return true
                    }
                  }
                  return false;
                },
              },
              btnFunction: (data, column) => {
                this.updateData(data);
              },
              bthClass: "btn btn-warning",
            },
            {
              key: "type_id",
              btnText: i18n.t("Delete"),
              visible: {
                key: "status",
                params: [],
                function: (idType, params) => {
                  const {authUser}=this.props
                  if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
                    let route=authUser.userAccess.route
                    if(route.includes('Customer Setup Heirarchy Delete')){
                      return true
                    }
                  }
                  return false;
                },
              },
              btnFunction: (data, column) => {
                this.deleteData(data);
              },
              bthClass: "btn btn-danger",
            },
          ],
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.createData = this.createData.bind(this);
  }
  runSearch(value) {
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = i18n.t("Add Setup Hierarchy");
    this.props.createData();
  }
  updateData(idType) {
    this.state.titleModal = i18n.t("Edit Setup Hierarchy");
    this.props.updateItem(idType);
  }
  deleteData(idType) {
    const html = (
      <>
        <div>{i18n.t('You will delete this Item')}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(idType, this.state.search);
            MySwal.update({
              title: i18n.t("Deleting Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Delete")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }

  renderCreateButton(){
    const {authUser}=this.props
    console.log('data',authUser)
    if(Object.keys(authUser).length >0 && authUser.userAccess!= undefined){
      let route=authUser.userAccess.route
      // console.log(route.includes('Customer Setup Heirarchy Create'))
      if(route.includes('Customer Setup Hierarchy Create')){
        return (<Button
          type="button"
          className="btn btn-primary m-1"
          onClick={(e) => this.createData()}
        >
          {i18n.t("Add Setup Heirarchy")}
        </Button>)
      }
    }
    return null;
  }

  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-xxl"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Fragment>
                      {this.renderCreateButton()}
                    </Fragment>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <Table
                  // checkAble={true}
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "detail",
                    columns: [
                      { label: i18n.t("Name"), key: "name" },
                      { label: i18n.t("Value"), key:"value"},
                      { label: i18n.t("Label"), key: "label" },
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default SetupHierarchy;
