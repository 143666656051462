import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
// middlewares
import ItemMiddleware from "../../../../middleware/modules/master/item";
import ItemView from "../../../../components/modules/master/item/view";

const mapStateToProps = ({ auth, item, unit, itemType ,itemBrand,itemCategory}) => ({
  isAuthenticated: auth.isAuthenticated,
  isProcessing: item.isProcessing,
  isError: item.isError,
  errorMessage: item.errorMessage,
  dataUpdate: item.single,
  isSuccess: item.isSuccess,
  isReset: item.isReset,
  isNewRecord: item.isNewRecord,
  onSubmit: item.onSubmit,
  unitList: unit.helperUnit,
  itemTypeList: itemType.helperItemType,
  itemBrandList: itemBrand.helperItemBrand,
  itemCategoryList: itemCategory.helperItemCategory,
  itemParentList: item.helperItem,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      closeModal: () => ItemMiddleware.reset(),
      getData: (id) => ItemMiddleware.getViewItem(id),
    },
    dispatch
  );
};
class ItemViewContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
    dataUpdate: PropTypes.object,
    errorMessage: PropTypes.any,
    onSubmit: PropTypes.bool,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isSuccess: false,
    isError: false,
    errorMessage: [],
    dataUpdate: null,
    onSubmit: false,
  };
  constructor(props) {
    super(props);
    this.state = {
      modal: {
        title: "",
        show: false,
      },
    };
    this.form = React.createRef();
  }

  // getData() {
  //   return this.props.showOne(this.props.dataID);
  // }

  // close() {
  //   this.props.closeModal();
  // }
  // submitForm() {
  //   const { search, isNewRecord, dataUpdate } = this.props;
  //   var form = this.form.current.runSubmit();
  //   if (isNewRecord) {
  //     this.props.new(form, search);
  //   } else {
  //     this.props.update(dataUpdate.id, form, search);
  //   }
  // }

  UNSAFE_componentWillMount() {
    this.props.getData(this.props.id);
    // this.props.getDetail(this.props.id);
  }

  render() {
    const {
      onSubmit,
      showModal,
      titleModal,
      dataUpdate,
    } = this.props;
    return (
      <>
       <ItemView 
          {...this.props}
       />
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemViewContainer);
