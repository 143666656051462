import React, { Component, Fragment } from "react";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { faSync,faEllipsis} from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormBeginningBalance from "../../../../containers/modules/accounting/beginning-balance/form";
import Table from "../../../utilities/table";
import { transactionTypeDP } from "../../../utilities/Const/TranscationCode";
import { statusMemoIn } from "../../../utilities/Const/StatusJournal";
import LabelDP from "../../../utilities/Status/down-payment";

const MySwal = withReactContent(Swal);
export class BeginningBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      modal: 1,
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Accounting"), class: " text-gray-600" },
        { link: null, title: i18n.t("Beginning Balance"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "date",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Date"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
        },
        {
          key: "branch_name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "coa3_name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("COA 3"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "coa4_name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("COA 4"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "coa5_name",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("COA 5"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "beginning_balance",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Balance"),
          type: "text",
          tHclass: "min-w-175px",
          tdClass: "",
        },
        // {
        //   key: "status",
        //   filter: {
        //     active: true,
        //     type: "Select",
        //     data: transactionTypeDP,
        //   },
        //   title: i18n.t("Status"),
        //   type: "component",
        //   tHclass: "min-w-125px",
        //   tdClass: "",
        //   component: (data, column) => {
        //     return <LabelDP value={data[column.key]} />;
        //   },
        // },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Action"),
          type: "action",
          tHclass: "text-center min-w-125px",
          tdClass: "text-center",
          form:'icon',
          buttons: [
            {
              key: "id",
              btnText: i18n.t("Cancel"),
              visible: {
                key: "status",
                params: [1],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                this.voidData(data);
              },
              bthClass: "btn btn-warning",
            },
          ],
          // component: (data, column) => {
          //   return (
          //     <Fragment>
                
          //       <Dropdown id="dropdown-btnaction">
          //         <Dropdown.Toggle
          //           variant="none"
          //           className="btn btn-sm btn-light btn-active-light-primary"
          //           id="dropdown-basic"
          //         >
          //           {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
          //         </Dropdown.Toggle>
          //         <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4 m-0">
          //           {/* Edit Button */}
          //           {data.status == 1?(
          //             <>
          //               <div className="menu-item px-3">
          //                 <Dropdown.Item
          //                   href="#"
          //                   className="menu-link px-3"
          //                   onClick={() => this.voidData(data.id)}
          //                 >
          //                   {i18n.t("Cancel")}
          //                 </Dropdown.Item>
          //               </div>
          //             </>
          //            ):null}
          //         </Dropdown.Menu>
          //       </Dropdown>
               
          //     </Fragment>
          //   );
          // },
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.createData = this.createData.bind(this);

  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }

  createData() {
    this.state.titleModal = i18n.t("Add Beginning Balance");
    this.props.createData();
  }

  voidData(data){
    const html = (
      <>
        <div>{i18n.t("You Will Cancel This Data")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.voidItem(data, this.state.search);
            MySwal.update({
              title: i18n.t("Cancel Data"),
              html: i18n.t("Please Wait ...."),
              icon: i18n.t("info"),
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Accept")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: i18n.t("question"),
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  render() {
    const { isProcessing, datalist, showModal } = this.props;

    this.state.listData = datalist;
    return (
      <React.Fragment>
        <div id="customer-type">
          <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start container-fluid"
          >
            <div className="content flex-row-fluid" id="kt_content">
              <div className="card">
                <div className="card-header d-flex justify-content-end border-0 pt-6">
                  <div className="card-toolbar">
                    <div
                      className="d-flex justify-content-end"
                      data-kt-customer-table-toolbar="base"
                    >
                      <Button
                        type="button"
                        className="btn btn-warning m-1"
                        onClick={(e) => this.runSearch(this.state.search)}
                      >
                        <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                      </Button>
                      <Button
                        type="button"
                        className="btn btn-primary m-1"
                        onClick={(e) => this.createData()}
                      >
                        {i18n.t("Add Beginning Balance")}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <Table
                    // expandAble={true}
                    // theExpand={{
                    //   key: "detail",
                    //   columns: 
                    //   [
                    //     { label: i18n.t("Source"), key: "source" },
                    //     { label: i18n.t("No PO/SO"), key: "code" },
                    //     { label: i18n.t("Total DP"), key: "nominal_format"},
                    //     { label: i18n.t("Total Used"), key: "used_format"},
                    //   ],
                    // }}
                    data={this.state.listData}
                    tools={false}
                    filterSearch={true}
                    column={this.state.column}
                    searchData={this.state.search}
                    runSearch={this.runSearch}
                    deleteData={this.deleteData}
                    updateData={this.updateData}
                    isProcessing={isProcessing}
                  />
                </div>
              </div>
            </div>
          </div>
          <FormBeginningBalance
            showModal={showModal}
            search={this.state.search}
            titleModal={this.state.titleModal}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default BeginningBalance;
