import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Fade, Collapse, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faTrash,
  faCheckDouble,
  faPrint,
  faCheck,
  faTimes,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";
import i18n from "../../../../i18n";

class TableDetailJournal extends Component {
  static propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    data: PropTypes.any,
    searchData: PropTypes.object,
    expandAble: PropTypes.bool,
    checkAble: PropTypes.bool,
    numberAble: PropTypes.bool,
  };

  static defaultProps = {
    isProcessing: false,
    data: [],
    searchData: {},
    expandAble: false,
    checkAble: true,
    numberAble: true,
  };
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      link: null,
      detail: [],
      columns: this.props.column,
      columnsDetail: this.props.columnDetail,
      columnTotal: this.props.columnTotal,
      tools: this.props.tools,
      filterSearch: this.props.filterSearch,
      search: {},
      page: null,
      expandTr: [],
      currentPage: 1,
      totalData: 0,
    };
    this._onBlur = this._onBlur.bind(this);
    this._onEnter = this._onEnter.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeLimit = this.changeLimit.bind(this);
  }

  tdAction(data, i, column) { }

  tdActionFull(data, i, column) {
    return (
      <td key={i.toString()} className="text-end">
        <Dropdown id="dropdown-btnaction">
          <Dropdown.Toggle
            variant="none"
            className="btn btn-sm btn-light btn-active-light-primary"
            id="dropdown-basic"
          >
            {<FontAwesomeIcon icon={faEllipsis}></FontAwesomeIcon>}
          </Dropdown.Toggle>
          <Dropdown.Menu className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4">
            {column.buttons.map((button, i) => {
              let show = button.visible.function(
                data[button.visible.key],
                button.visible.params
              );
              let isLink = button.btnLink != null ? true : false;
              let result = button.key != null ? data[column.key] : data;
              return show == true ? (
                <div className="menu-item px-3" key={"btn-action" + i}>
                  {isLink == true ? (
                    <Dropdown.Item
                      href={button.btnLink.url + "/" + data[column.key]}
                      className="menu-link px-3"
                      target={button.btnLink.target}
                    >
                      {button.btnText}
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      href="#"
                      className="menu-link px-3"
                      onClick={(e) => {
                        button.btnFunction(result, column);
                      }}
                    >
                      {button.btnText}
                    </Dropdown.Item>
                  )}
                </div>
              ) : (
                ""
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    );
  }

  tdActive(data, i) {
    switch (data) {
      case "Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center ">
              <FontAwesomeIcon icon={faCheck} />
            </div>
          </td>
        );
      case "Non Active":
        return (
          <td key={i.toString()}>
            <div className="col-md-12 text-center">
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </td>
        );
      case "Waiting":
        return (
          <td key={i.toString()}>
            <div className="badge badge-light-warning">{i18n.t("Waiting")}</div>
          </td>
        );
      case "Approved":
        return (
          <td key={i.toString()}>
            <div className="badge badge-light-success">{i18n.t("Approved")}</div>
          </td>
        );
      case "Rejected":
        return (
          <td key={i.toString()}>
            <div className="badge badge-light-danger">{i18n.t("Rejected")}</div>
          </td>
        );
    }
  }
  tdCustom(data, i, type) {
    switch (type) {
      case "createdAt":
        return (
          <td key={i.toString()} className="text-center">
            <div className="badge badge-light-success">{data.created_by}</div>{" "}
            <br />
            <div className="badge badge-light-info">{data.created_at}</div>
          </td>
        );
        break;
      case "updatedAt":
        return (
          <td key={i.toString()} className="text-center">
            <div className="badge badge-light-success">{data.updated_by}</div>{" "}
            <br />
            <div className="badge badge-light-info">{data.updated_at}</div>
          </td>
        );
        break;
      default:
        <td key={i.toString()} className="text-center"></td>;
        break;
    }
  }
  tdNumber(dataRow, i) {
    const { data } = this.props;
    let page = 1;
    let limit = 10;
    if (data.current_page != undefined) {
      page = data.current_page;
    }
    if (this.state.search.limit != undefined) {
      if (this.state.search.limit != -1) {
        limit = this.state.search.limit;
      } else {
        limit = 1;
      }
    }
    let plus = 0;
    if (page > 1) {
      plus = limit * (page - 1);
    }
    return <td key={i.toString()}>{i + 1 + plus}</td>;
  }
  renderTd(column, data, i = 0) {
    var value = data[column.key];
    var type = column.type;
    switch (type) {
      case "text":
        return <td key={i.toString()}>{value}</td>;
      case "action":
        return this.tdAction(data, i, column);
      case "actionFull":
        return this.tdActionFull(data, i, column);
      case "active":
        return this.tdActive(value, i);
      case "balance":
        return this.tdActive(value, i);
      case "check":
        return this.tdCheck(value, i);
      case "numberTd":
        return this.tdNumber(value, i);
      case "expand":
        return this.tdExpand(data, i);
      case "custom":
        return this.tdCustom(data, i, column.key);
      default:
        return <td key={i.toString()}>{value}</td>;
    }
  }

  tdCheck(data, i) {
    return (
      <td>
        <div className="form-check form-check-sm form-check-custom form-check-solid">
          <input
            onChange={(e) => {
              console.log(data);
            }}
            className="form-check-input"
            type="checkbox"
            value="1"
          />
        </div>
      </td>
    );
  }

  tdSumTotal() {
    const { detailData } = this.props;

    const value = detailData.journal_detail;
    var val = value.reduce(function (previousValue, currentValue) {
      return {
        debt: (parseFloat(previousValue.debt) || 0) + (parseFloat(currentValue.debt) || 0),
        credit: (parseFloat(currentValue.credit) || 0) + (parseFloat(previousValue.credit) || 0),
      };
    });
    return (
      <React.Fragment>
        <tr style={{fontWeight:'bold'}}>
          <td colSpan={3} style={{textAlign:'center'}}>Total</td>
          <td>
            {"Rp. " +
              (val.debt != 0
                ? parseFloat(val.debt)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                : "0.00")}
          </td>
          <td>
            {"Rp. " +
              (val.credit != 0
                ? parseFloat(val.credit)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                : "0.00")}
          </td>
          <td></td>
        </tr>
      </React.Fragment>
    );
  }

  tdExpand(i) {
    return (
      <td className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <Button
            variant="none"
            onClick={(e) => this.setExpand(i)}
            aria-controls={"table" + i}
            aria-expanded={this.state.expandTr[i]}
          >
            {" "}
            {this.state.expandTr[i] ? "-" : "+"}
          </Button>
        </div>
      </td>
    );
  }
  setExpand(i) {
    this.setState({
      ...this.state,
      expandTr: {
        ...this.state.expandTr,
        [i]: !this.state.expandTr[i],
      },
    });
  }
  updateSearch(value, key) {
    if (value == "") {
      value = null;
    }
    this.state.search[key] = value;
  }

  _onBlur() {
    this.state.search["page"] = 1;
    this.runSearch();
  }

  _onEnter(event) {
    if (event.key === "Enter") {
      this.state.search["page"] = 1;
      this.runSearch();
    }
  }
  changePage(event, url) {
    if (url != null && this.state.search.limit != -1) {
      var urlParams = new URLSearchParams(url);
      this.state.search["page"] = urlParams.get("page");
      this.runSearch();
    }
  }
  changeLimit(event) {
    if (event.target.value == -1) {
      this.state.search["page"] = 1;
    } else {
      var pageAvailable = this.state.totalData / event.target.value;
      if (this.state.search["page"] > pageAvailable) {
        this.state.search["page"] = Math.ceil(pageAvailable);
      }
    }
    this.state.search["limit"] = event.target.value;
    this.runSearch();
  }
  changeSelect(e, name) {
    let value = e.target.value;

    if (value == "") {
      value = null;
    }
    this.state.search[name] = value;
    this.runSearch();
  }

  handlerDateTime(e, name) {
    var key = name;
    var value = e;
    this.state[key] = value;
    if (value != null) {
      value = moment(value).format("YYYY-MM-DD");
    }
    this.state.search[key] = value;
    this.runSearch();
  }
  runSearch() {
    this.props.runSearch(this.state.search);
  }
  renderFilter(column, filter) {
    if (filter.active) {
      switch (filter.type) {
        case "Text":
          return (
            <input
              onKeyPress={this._onEnter}
              onBlur={this._onBlur}
              onChange={(e) => {
                this.updateSearch(e.target.value, column.key);
              }}
              type="text"
              className="form-control form-control-solid form-control-sm"
              placeholder={column.title}
              name={column.key}
            />
          );
          break;
        case "Select":
          return (
            <select
              onChange={(e) => this.changeSelect(e, column.key)}
              className="form-select form-select-sm form-select-solid"
            >
              {filter.data.map((list, i) => (
                <option key={column.key + i} value={list.key}>
                  {list.label}
                </option>
              ))}
            </select>
          );
          break;
        case "Date":
          return (
            <DatePickerCustom
              name={column.key}
              title={column.title}
              required={false}
              selected={this.state[column.key]}
              onChange={(e) => this.handlerDateTime(e, column.key)}
              startDay={0}
              isClearable={this.state[column.key] != null ? true : false}
              autoComplete={"off"}
              dateFormat="dd MMM yyyy"
              endDate={new Date()}
              className="form-control form-control-sm form-control-solid"
              labelfield={"false"}
            />
          );
          break;
      }
    } else {
      return "";
    }
  }
  renderCheck() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          <input
            onChange={(e) => {
              console.log("check All");
            }}
            className="form-check-input"
            type="checkbox"
            value="1"
          />
        </div>
      </th>
    );
  }
  renderNumber() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          #
        </div>
      </th>
    );
  }
  renderExpand() {
    return (
      <th className="w-10px pe-2">
        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
          +
        </div>
      </th>
    );
  }
  renderTrExpand(data, column, totalColumn) {
    const { checkAble, numberAble, expandAble } = this.props;
    const dataExpand = data[column.key];
    const columnExpand = column.columns;

    if (dataExpand != undefined) {
      return (
        <tr
          key={"expand" + data.id}
          className={!this.state.expandTr[data["id"]] ? "d-none" : ""}
        >
          {numberAble ? <td></td> : ""}
        </tr>
      );
    }
  }
  render() {
    const {
      theExpand,
      isProcessing,
      data,
      searchData,
      checkAble,
      numberAble,
      expandAble,
      detailData,
    } = this.props;
    if (detailData != undefined) {
      this.state.link = data.link;
      this.state.data = detailData;
      this.state.detail = detailData.journal_detail;
      this.state.currentPage = data.currentPage;
      this.state.totalData = data.total;
      this.state.search = searchData;
    }

    return (
      <div className={isProcessing ? " table-loading" : ""}>
        {isProcessing ? (
          <div className="table-loading-message">
            <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
            <div className="text-center">Loading</div>
          </div>
        ) : (
          ""
        )}

        <table className="table align-middle table-index fs-6 gy-5 ">
          <thead>
            <tr>
              <td>
                <h2>Journal Transaction</h2>
              </td>
            </tr>
            <tr
              key={"head"}
              // className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
              id="head"
            >
              {/* {numberAble?this.renderNumber():''} */}
              {this.state.columns.map((column, i) => (
                <th key={i.toString()} className={column.tHclass}>
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="fw-semibold text-gray-600 main-body">
            {this.state.data != undefined ? (
              <React.Fragment key={"empty"}>
                <tr>
                  {this.state.columns.map((column, i) =>
                    this.renderTd(column, this.state.data, i)
                  )}
                </tr>
              </React.Fragment>
            ) : (
              <tr key={"notfound"}>
                <td
                  className="text-center"
                  colSpan={
                    this.state.columns.length > 0
                      ? this.state.columns.length +
                      (expandAble ? 1 : 0) +
                      (numberAble ? 1 : 0) +
                      (checkAble ? 1 : 0)
                      : 1
                  }
                >
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <table className="table align-middle table-index fs-6 gy-5 ">
          <thead>
            <tr>
              <td>
                <h2>Detail</h2>
              </td>
            </tr>
            <tr
              key={"head"}
              // className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
              id="head"
            >
              {numberAble ? this.renderNumber() : ""}

              {this.state.columnsDetail.map((columnDetail, i) => (
                <th key={i.toString()} className={columnDetail.tHclass}>
                  {columnDetail.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="fw-semibold text-gray-600 main-body">
            {this.state.detail != undefined && this.state.detail.length > 0 ? (
              <React.Fragment>
                {this.state.detail.map((detail, p) => (
                  <React.Fragment key={"empty" + p}>
                    <tr key={p}>
                      {numberAble
                        ? this.renderTd({ type: "numberTd" }, detail["id"], p)
                        : ""}

                      {this.state.columnsDetail.map((columnDetail, i) =>
                        this.renderTd(columnDetail, detail, i)
                      )}
                    </tr>
                  </React.Fragment>
                ))}
                {this.tdSumTotal()}
              </React.Fragment>
            ) : (
              <tr key={"notfound"}>
                <td
                  className="text-center"
                  colSpan={
                    this.state.columnsDetail.length > 0
                      ? this.state.columnsDetail.length +
                      (expandAble ? 1 : 0) +
                      (numberAble ? 1 : 0) +
                      (checkAble ? 1 : 0)
                      : 1
                  }
                >
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default TableDetailJournal;
