import ChartForm from "../../utilities/chart";
import React from "react";
import i18n from "../../../i18n";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionSalesDiscount(props) {
    const arr = props.data;
    return (
        <div className="col-xl-6 mb-5 mb-xl-10">

            <div className="card card-flush overflow-hidden h-md-100">

                <div className="card-header py-5">

                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-900">{i18n.t("Discount Sales")}</span>
                        {/* <span className="text-gray-500 mt-1 fw-semibold fs-6">Users from all channels</span> */}
                    </h3>


                    <div className="card-toolbar">

                        <button className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                            <i className="ki-duotone ki-dots-square fs-1">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                            </i>
                        </button>
                    </div>

                </div>


                <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">

                    <div className="px-9 mb-5">

                        <div className="d-flex align-items-center mb-2">

                            <div className="d-flex mb-2">
                                <span className="fs-4 fw-semibold text-gray-500 me-1">Rp.</span>
                                <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{(arr.salesDiscount != null ? formatNumber(arr.salesDiscount.discount) : 0)}</span>
                            </div>


                            {/* <span className="badge badge-light-success fs-base">
                                <i className="ki-duotone ki-arrow-down fs-5 text-success ms-n1">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                </i>4.5%</span> */}

                        </div>


                        <span className="fs-6 fw-semibold text-gray-500">Total Discounted Sales This Month</span>

                    </div>
                    <div className="min-h-auto ps-4 pe-6" style={{ height: "200", minHeight: "315px" }}>

                        <ChartForm
                            type={'area'}
                            dataList={arr.salesDiscount}
                            height={"350px"}
                        />

                    </div>
                </div>

            </div>

        </div>

    );
}


