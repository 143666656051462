import { faCamera, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

export default function InputImage(props){
    const {onChange,defaultValue,label,size}=props
    const inputFile = useRef(null) 
    const[file,setFile]=useState()
    useEffect(()=>{
        if(defaultValue){
            console.log(defaultValue)
            const base64Data = defaultValue.split(',')[1];
            // Convert Base64 string to byte array
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create a Blob from the byte array
            const blob = new Blob([byteArray], { type: 'image/png' });
            setFile(URL.createObjectURL(blob))
            onChange(blob)
        }
    },[])
    function handleChange(e) {
        if(e.target.files.length !== 0){
            setFile(URL.createObjectURL(e.target.files[0]));
            onChange(e.target.files[0])
        }
    }
    function remove() {
        setFile(null);
        onChange(null)
    }

    return (
        <div>
            <label className='label-control'>{label||'Avatar'}</label>
            <div className="flex items-center justify-between">
                <input type="file" accept="image/*" ref={inputFile} style={{display: "none"}} onChange={handleChange} />
                <div className="image-input" style={{width:size||150,height:size||150,borderRadius:10}}>
                    {file&&<div style={{position: 'absolute',right: 0,color:'white',cursor:'pointer',backgroundColor:(file)?"#f1416c":"rgba(30, 33, 41, .2)",borderRadius:'100%',textAlign:'center',width:20,height:20}} onClick={remove}>
                        <FontAwesomeIcon icon={faClose}></FontAwesomeIcon>
                    </div>}
                    <div  className="image-input-placeholder border-2 border-success image-input-empty:border-gray-300" style={(file)?{}:{backgroundImage: "url('/assets/media/avatars/blank.png')",overflow: "hidden",width: "100%",height: "100%",backgroundSize: "cover",backgroundRepeat: "no-repeat",borderRadius:10}} >
                        <img src={file} style={{maxWidth:size||150,maxHeight:size||150,height: "auto",margin: "0 auto",display: "block"}}></img>
                        {!file&&<div className="flex items-center justify-center cursor-pointer h-5 left-0 right-0 bottom-0 bg-dark-clarity absolute" style={{position: "absolute",backgroundColor: "rgba(30, 33, 41, .2)",width:'100%',height:"25px",borderBottomLeftRadius:10,borderBottomRightRadius:10}} onClick={()=>inputFile.current.click()}>
                            <div className='text-center m-1'>
                                <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
       
    )
}