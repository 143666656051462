import React from "react"
import { Table } from "react-bootstrap"

export default function StatusSelling(props){
    return (
        <div className="text-center">
                 <Table
                className="table-responsive bordered"
               >
                <thead className="bg-success text-white" >
                    <th>No</th>
                    <th>Item Name</th>
                    {
                        props?.info.map((info,index)=>{
                            return <th key={"info"+index}>{info.code}</th>
                        })
                    }
                </thead>
                <tbody>
                    {props?.item.map((item,indexItem)=>{
                        return (
                            <tr key={"item"+indexItem}>
                                <td >{indexItem+1}</td>
                                <td align="left">{item.label}</td>
                                {
                                   props?.info.map((info,index)=>{
                                        return <td key={"infoItem"+indexItem}>{item?.info[info.code]}</td>
                                    })
                                }
                            </tr>
                        )
                    })}
                </tbody>
               </Table>
            </div>
    )
} 