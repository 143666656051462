import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Table } from "react-bootstrap";
import { faWeight } from "@fortawesome/free-solid-svg-icons";
import PhotoGallery from "../../../utilities/PhotoGallery";
const token = cookieManager.getUserToken();

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headeTable:false
    }
  }

  renderList(){
    const { data } = this.props
    if(data != undefined){
      let row = data.details
      let html=[]
      let no = 1;
      for(var i=0; i < row.length; i++){
        html.push(
              <tr key={i}>
              <td className="text-center">{no++}</td>
              <td>{row[i].code_item}</td>
              <td>{row[i].name_item}</td>
              <td>{row[i].minimum_order}</td>
              <td>{row[i].name_unit}</td>
              <td>{(row[i].program_name)?row[i].program_name:'-'}</td>
              <td>{(row[i].discount_format != 0)?row[i].discount_format:'-'}</td>
              <td>{row[i].name_item_promo}</td>
              <td>{(row[i].qty_format != 0) ? row[i].qty_format : '-'}</td>
              </tr>
        );
      }
      return html;
    }
    
  }
  render() {
    const { data ,isProcessing} = this.props
    return (
      <form>
        {(data != null) ? <React.Fragment>
          <div className="row">
            <div className="col-12">
              <Table >
                <thead>
                </thead>
                <tbody>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td style={{width:'10%'}}>{i18n.t("Name Event")}</td>
                  <td style={{width:'1%'}}>:</td><td>{(data.name)?data.name:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Branch Name")}</td>
                  <td>:</td><td>{(data.name_branch)?data.name_branch:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Start Date")}</td>
                  <td>:</td><td>{(data.start_date_format)?data.start_date_format:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("End Date")}</td>
                  <td>:</td><td>{(data.end_date_format)?data.end_date_format:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("District")}</td>
                  <td>:</td><td>{(data.district_name)?data.district_name:'-'}</td></tr>
                  <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Type Customer")}</td>
                  <td>:</td>
                  
                 <td>
                  <Fragment>
                  {data.customer_name.map((item,i)=>(
                  <tr key={item+i}>
                  <td>{(i+1)+'. '}</td>
                  <td>{item.type_customer}</td>
                  </tr>
                  )
                )}
                </Fragment>
                </td>
                </tr>
                <tr style={{fontSize:'15px', fontWeight:'bold'}}><td>{i18n.t("Description")}</td>
                  <td>:</td><td>{(data.description)?data.description:'-'}</td></tr>
                </tbody>
              </Table>
              {this.state.headeTable == false ?
                  <React.Fragment>
                    <table className="table table-responsive table-bordered table-hover">
                      <thead>
                        <tr className="">
                          <th width={"3%"} className="text-center">{i18n.t("No.")}</th>
                          <th width={"12%"}>{i18n.t("Item Code")}</th>
                          <th width={"15%"}>{i18n.t("Item")}</th>
                          <th width={"12%"}>{i18n.t("Minimum Order")}</th>
                          <th width={"12%"}>{i18n.t("Unit")}</th>
                          <th width={"13%"} >{i18n.t("Program Type")}</th>
                          <th width={"10%"} >{i18n.t("Diskon (Rp)")}</th>
                          <th width={"13%"} >{i18n.t("Free Item")}</th>
                          <th width={"10%"} >{i18n.t("Qty Free")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderList()}
                      </tbody>
                    </table>
                  </React.Fragment>:
                  ''}
                 
            </div>
          </div>
          <div className="row">
            <div className="d-flex flex-end">
              <div className="col-4">
              </div>
            </div>
          </div>
        </React.Fragment> : ''}
      </form>
    );
  }
}

export default Form;
