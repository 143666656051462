import React, { Component, useState } from 'react';
import Table from '../../../utilities/table';
import Breadcrumbs from '../../../utilities/breadcrumbs'; 
// import Modal from '../../../utilities/modal';
import { Button, Modal} from 'react-bootstrap';
import FormContainer from'../../../../containers/modules/pembelian/purchase-return/form'
import Swal from "sweetalert2";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import withReactContent from 'sweetalert2-react-content';
import i18n from '../../../../i18n';
const MySwal = withReactContent(Swal)
export class PurchaseReturn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search:{
                limit:10
            },
            titleModal:'',
            listData:[],
            breadcrumbs:[
                {link:process.env.APP_URL,title:i18n.t('Home'),class:' text-gray-600'},
                {link:null,title:i18n.t('Transaction'),class:' text-gray-600'},
                {link:null,title:i18n.t('Purchase Return'),class:' text-gray-500'},
            ],
            column:[
                {key:'transaction_no',filter:{active:true,type:'Text',data:null},title:i18n.t('No. Purchase Return'),type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'date_format',filter:{active:true,type:'Date',data:null},title:i18n.t('Date'),type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'name_branch',filter:{active:true,type:'Text',data:null},title:i18n.t('Branch Name'),type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'name_supplier',filter:{active:true,type:'Text',data:null},title:i18n.t('Supplier Name'),type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'description',filter:{active:true,type:'Text',data:null},title:i18n.t('Description'),type:'text',tHclass:'min-w-125px',tdClass:''},
                {key:'createdAt',filter:{active:false,type:'Select',data:null},title:i18n.t('Created At'),type:'custom',tHclass:'text-center min-w-125px',tdClass:''},
                {key:'updatedAt',filter:{active:false,type:'Select',data:null},title:i18n.t('Updated At'),type:'custom',tHclass:'text-center min-w-125px',tdClass:''},
                {
                    key:'id',
                    filter:{
                        active:false
                    },
                    title:i18n.t('Action'),
                    type:'action',
                    tHclass:'text-center min-w-150px',
                    tdClass:'',
                    form:'icon',
                    buttons:[
                                {
                                    key:'id',
                                    btnText:i18n.t('Cancel'),
                                    visible:{
                                        key:'status',
                                        params:[1],
                                        function:(id,params)=>{
                                            return params.includes(id);
                                        }
                                    },
                                    btnFunction:(data,column)=>{
                                       this.cancelData(data)
                                    },
                                    bthClass:'btn btn-warning',
                                },{
                                    key:'id',
                                    btnText:i18n.t('Print'),
                                    visible:{
                                        key:'status',
                                        params:[5,7],
                                        function:(id,params)=>{
                                            return params.includes(id);
                                        }
                                    },
                                    btnFunction:(data,column)=>{
                                       return false
                                    },
                                    btnLink:{
                                        url:'/pembelian/purchase-order/print',
                                        target:'_blank'
                                    },
                                    bthClass:'btn btn-warning',
                                },
                        ]
                },
            ]
        };
        this.runSearch=this.runSearch.bind(this)
        this.updateData=this.updateData.bind(this)
        this.cancelData=this.cancelData.bind(this)
        this.createData=this.createData.bind(this)
    }
    runSearch(value){
        this.state.submit=false
        this.props.search(value);
    }
    createData(){
        this.state.titleModal=i18n.t('Add Purchase Return')
        this.props.createData()
    }
    updateData(id){
        this.state.titleModal=i18n.t('Edit Purchase Return')
        this.props.updateItem(id)
    }
    // cancelData(id){
    //     this.props.cancelItem(id)
    // }
    
    cancelData(id){
        const html=<><div>{i18n.t("You will cancel this Item")}</div>
                <Button variant='warning' onClick={()=>MySwal.close()}>{i18n.t("Cancel")}</Button>
                <Button variant='danger' onClick={()=>{
                        this.props.cancelItem(id,this.state.search); 
                        MySwal.update({
                            title:i18n.t('Canceling Data'),
                            html:i18n.t('Please Wait ....'),
                            icon:'info',
                        })
                        MySwal.showLoading()
                        }}>{i18n.t("Accept")}</Button>
                    </>
        MySwal.fire({  
            title: i18n.t('Are you sure?'),  
            html: html,  
            icon: 'question',  
            showCancelButton: false,  
            showConfirmButton: false,  
            allowOutsideClick: false,
            allowEscapeKey: false,
          });  
    }
    submitForm(status){
        this.setState({submit:status});
    }
    render() {
        const {isProcessing,data,showModal}=this.props
        this.state.listData=data
        return (
        <div id="customer-type">
            <Breadcrumbs 
                url={this.state.breadcrumbs}
                tools={false}
            />
            <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-fluid">
                <div className="content flex-row-fluid" id="kt_content">
                    <div className="card">
                        <div className="card-header d-flex justify-content-end border-0 pt-6">
                            <div className="card-toolbar">
                                <div className="d-flex justify-content-end" data-kt-customer-table-toolbar="base">
                                <Button type="button" className="btn btn-warning m-1"  onClick={(e)=> this.runSearch(this.state.search)}><FontAwesomeIcon icon={faSync}></FontAwesomeIcon></Button>
                                    <Button type="button" className="btn btn-primary m-1"  onClick={(e)=>this.createData()}>{i18n.t("Add Purchase Return")}</Button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <Table 
                                // checkAble={true}
                                numberAble={true}
                                expandAble={true}
                                theExpand={
                                    {
                                        key:'purchase_return_detail',
                                        columns:[
                                            {label:i18n.t('Item'), key:'item_name'},                                        
                                            {label:i18n.t('Unit'), key:'unit_name'},                                        
                                            {label:i18n.t('No. Band'), key:'band_no'},                                        
                                            {label:i18n.t('Qty return'),key:'qty_format_return'},                                        
                                            {label:i18n.t('Total'),key:'total_format'},                                        
                                            {label:i18n.t('Status'),key:'active'},                                        
                                                                               
                                        ]
                                    }
                                }
                                data={this.state.listData}
                                tools={false}
                                filterSearch={true}
                                column={this.state.column}
                                searchData={this.state.search}
                                runSearch={this.runSearch}
                                deleteData={this.deleteData}
                                updateData={this.updateData}
                                isProcessing={isProcessing}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FormContainer 
                showModal={showModal}
                search={this.state.search}
                titleModal={this.state.titleModal}
            />
        </div>
        );
    }
}

export default PurchaseReturn;
