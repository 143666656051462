import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
// components
import { Journal } from "../../../../components/modules/accounting/journal";
import JournalMiddleware from "../../../../middleware/modules/accounting/journal";
import { Helmet } from "react-helmet";
import i18n from "../../../../i18n";
// middlewares

const mapStateToProps = ({ auth, journal }) => ({
  isProcessing: journal.isProcessing,
  isError: journal.isError,
  errorMessage: journal.errorMessage,
  datalist: journal.data,
  getHelper: journal.getHelper,
  showModal: journal.showModal,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      create: () => JournalMiddleware.create(),
      getHelper: () => JournalMiddleware.getHelper(),
      changeStatus: (data) => JournalMiddleware.beforeChangeStatus(data),
      reset: () => JournalMiddleware.resetList(),
      search: (data) => JournalMiddleware.list(data),
      update: (data) => JournalMiddleware.update(data),
      delete: (id, search) => JournalMiddleware.beforeDelete(id, search),
    },
    dispatch
  );
};
class JournalContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isRegistered: PropTypes.bool,
    isError: PropTypes.bool,
    errorMessage: PropTypes.any,
    datalist: PropTypes.object,
    search: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isAuthenticated: false,
    isProcessing: false,
    isRegistered: false,
    isError: false,
    errorMessage: [],
  };

  constructor(props) {
    super(props);
    this.deleteItem = this.deleteItem.bind(this);
    this.createItem = this.createItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.changeItem = this.changeItem.bind(this);
  }

  componentWillMount() {
    const search = {
      limit: 10,
    };
    this.props.reset();
    this.props.search(search);
  }

  deleteItem(id, search) {
    this.props.delete(id, search);
  }

  updateItem(id) {
    this.props.getHelper();
    this.props.update(id);
  }

  changeItem(data) {
    this.props.changeStatus(data);
  }
  createItem() {
    this.props.getHelper();
    this.props.create();
  }
  render() {
    const { isProcessing, search, datalist, showModal } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <title>{i18n.t("Journal List")}</title>
        </Helmet>
      <Journal
       {...this.props}
        search={search}
        data={datalist}
        isProcessing={isProcessing}
        showModal={showModal}
        deleteItem={this.deleteItem}
        updateItem={this.updateItem}
        createData={this.createItem}
        changeItem={this.changeItem}
      />
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(JournalContainer);
