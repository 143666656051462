import React, { Component, Fragment, useState } from "react";
import Table from "../../../utilities/table";
import { Button, Dropdown } from "react-bootstrap";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCross, faEllipsis, faEye, faPencil, faSync, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import i18n from "../../../../i18n";
import { transactionTypeDP } from "../../../utilities/Const/TranscationCode";
import LabelVoid from "../../../utilities/Status/void";
const MySwal = withReactContent(Swal);
export class ListSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      listData:[],
      column: [
        {
          key: "date_faktur",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Invoice Date"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:true
        },
        {
          key: "branch",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Branch"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
        {
          key: "invoice_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. Invoice"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
        {
          key: "supplier",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("Supplier"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
        {
          key: "total_bill",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Total Bill"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
        {
          key: "date_payment",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Payment Date"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:true
        },
        {
          key: "payment_no",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. Payment"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
        {
          key: "total_payment",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Total Payment"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
        {
          key: "remaining",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Remaining"),
          type: "text",
          tHclass: "min-w-100px",
          tdClass: "",
          sortable:false
        },
       {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: transactionTypeDP,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-100px",
          tdClass: "",
          component:(data,column)=>{
                return <LabelVoid value={data[column.key]} />
          }
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-100px",
          tdClass: "",
        },
        {
          key: "id",
          filter: {
            active: false,
          },
          title: i18n.t("Action"),
          type: "action",
          // type: "component",
          tHclass: "text-center min-w-150px",
          tdClass: "text-center",
          form:'icon',
          buttons: [
            {
              key: "id",
              btnText: i18n.t("Void"),
              visible: {
                key: "status",
                params: [],
                function: (id, params) => {
                  return true;
                },
              },
              btnFunction: (data, column) => {
                this.voidData(data);
              },
              bthClass: "btn btn-warning",
            },
          ],
          // component:(data)=>{
          //   return (
          //   <Fragment>
          //     {data.status==1 && 
          //       <Button className="btn btn-sm btn-danger ml-10" 
          //       onClick={()=>this.voidData(data.id)}
          //       data-toggle="tooltip" data-placement="top" title="Void"
          //       ><FontAwesomeIcon icon={faBan}></FontAwesomeIcon></Button>
          //     }
          //   </Fragment>)
          // }
        },
      ],
      titleModal:""
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.voidData = this.voidData.bind(this);
  }

  
  // componentWillMount() {

  //   this.props.reset();
  //   this.props.search(this.state.search);
  
  // }
  viewInfo(data){
    var text=""
    switch(data.status){
      case 3:
        text="Change Schedule"
        break;
      case 4:
        text="Change Sales"
        break;
    }
    MySwal.fire({
      title: i18n.t(text),
      html: data.remark,
      icon: "info",
      showConfirmButton: true,
    });
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }


  updateData(id) {
    this.state.titleModal = i18n.t("Edit Visit Schedule");
    this.props.update(id);
  }
  changeData() {
    this.state.titleModal = i18n.t("Change Schedule");
    this.props.change();
  }
  voidData(id) {
    const html = (
      <>
        <div>{i18n.t('You will void this transaction')}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t('Cancel')}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.voidItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Process Data"),
              html: i18n.t("Please Wait ...."),
              icon: "info",
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Void")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  submitForm(status) {
    this.setState({ submit: status });
  }

  render() {
    const { isProcessing, data, showModal, showModalChange  } = this.props;
    // console.log('PROPS',this.props)
    // console.log('STATE',this.state)
    if(data!=null && data != undefined){
      this.state.listData = data;
      console.log(this.state.listData)
      return (
        <div id="List-Schedule">
          
          <div className="card-header d-flex justify-content-end border-0 pt-6">
            <div className="card-toolbar">
              <div
                className="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <Button
                  type="button"
                  className="btn btn-warning m-1"
                  onClick={(e) => this.runSearch(this.state.search)}
                >
                  <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                </Button>
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
          <Table
                  checkAble={false}
                  numberAble={true}
                  // expandAble={true}
                  // theExpand={{
                  //   key: "detail",
                  //   columns: [
                  //     { label: i18n.t("Invoice No."), key: "invoice_no" },
                  //     { label: i18n.t("Payment Type"), key: "payment_type" },
                  //     // { label: i18n.t("Down Payment"), key: "down_payment_format" },
                  //     { label: i18n.t("BG No."), key: "bg_no" },
                  //     { label: i18n.t("Total Payment"), key: "payment_format" },
                  //   ],
                  // }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
          </div>
        </div>
        
      );
    }
  }
}

export default ListSupplier;
