import cookieManager from '../../../../utils/cookieManager';
import axios from 'axios';
import SettlementSalesActions from '../../../../reducers/modules/mutation/settlement-sales/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Navigate } from 'react-router-dom';

const MySwal = withReactContent(Swal)
const url=process.env.REST_URL +'settlement-sales'

export default class SettlementSalesMiddleware {

  static create(params){
    return (dispatch) => {
      dispatch(SettlementSalesActions.create());
    };
  }

  static update(params){
    return (dispatch) => {
      SettlementSalesMiddleware.showOne(dispatch, params);
      dispatch(SettlementSalesActions.update());
    };
  }
  static resetList(){
    return (dispatch) => {
        dispatch(SettlementSalesActions.resetList());
      };
  }
  static getData(params){
      return (dispatch) => {
          dispatch(SettlementSalesActions.process());
          SettlementSalesMiddleware.showList(dispatch, params);
        };
  }

  static beforeSaveSettlement(post, search) {
      return (dispatch) => {
        dispatch(SettlementSalesActions.submit());
        SettlementSalesMiddleware.saveSettlement(dispatch, post, search);
      };
    }

  static getData(params){
      return (dispatch) => {
          dispatch(SettlementSalesActions.process());
          SettlementSalesMiddleware.showList(dispatch, params);
        };
  }
  static showExcel(params){
    return (dispatch) => {
        dispatch(SettlementSalesActions.process());
        SettlementSalesMiddleware.beforeShowExcel(dispatch, params);
      };
  }

  static listing(params){
    return (dispatch) => {
        dispatch(SettlementSalesActions.process());
        SettlementSalesMiddleware.showlisting(dispatch, params);
      };
  }
  static reset(){
    return (dispatch) => {
        dispatch(SettlementSalesActions.reset());
      };
}
  static getOne(params){
    return (dispatch) => {
        dispatch(SettlementSalesActions.view());
        SettlementSalesMiddleware.showOne(dispatch, params);
      };
  }

  static getToken(){
      return cookieManager.getUserToken();
  }

  static getHelper(params=null){
    return (dispatch) => {
      SettlementSalesMiddleware.showHelper(dispatch,params);
      };
  }

  static getPrint(params) {
    return (dispatch) => {
      dispatch(SettlementSalesActions.beforePrint());
      SettlementSalesMiddleware.showPrint(dispatch, params);
    };
  }

  static saveSettlement(dispatch, post, search) {
    let show = {
      idBranch : post.Header.idBranch,
      date : post.Header.date,
      idItem : "PJ"
    };
    
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`,
      },
      url: url + '/store',
      data: post,
      responseType: "json",
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(
            SettlementSalesActions.process(),
            SettlementSalesMiddleware.showList(dispatch, show)
          );
          dispatch(SettlementSalesActions.success());
          SettlementSalesMiddleware.alertHendler(
            dispatch,
            response,
            true,
            "Process Data Successfully",
            "success"
          );
        }
      })
      .catch((error) => {
        SettlementSalesMiddleware.alertHendler(dispatch, error);
      });
  }

  static showPrint(dispatch, params) {
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`,
      },
      url: process.env.REST_URL +'report/cash-flow/print',
      responseType: "json",
      data:params
      // method: 'POST', 
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`
      // },
      // url: process.env.REST_URL +'report/cash-flow/print',
      // responseType: 'json',
      // params:params
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SettlementSalesActions.print(response.data.result));
          console.log('response',response.data.result);
        }
      })
      .catch((error) => {
        SettlementSalesMiddleware.alertHendler(dispatch, error);
      });
  }

 
  static showHelper(dispatch,params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`
        },
        url:process.env.REST_URL+'helper/get-helper-work-sheet' ,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(SettlementSalesActions.helper(response.data.result));
        }
      })
      .catch((error) => {
        SettlementSalesMiddleware.alertHendler(dispatch,error)
      });
  }

  static showList(dispatch, params){
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`,
      },
      url: url + '/show',
      responseType: "json",
      data: params,
    })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SettlementSalesActions.list(response.data.result));
        }
      })
      .catch((error) => {
        SettlementSalesMiddleware.alertHendler(dispatch, error);
      });
  }

  static showlisting(dispatch, params){
    axios({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`
        },
        url: url,
        responseType: 'json',
        params:params
      })
      .then((response) => {
        
        if (response && response.status === 200 && response.data) {
          dispatch(SettlementSalesActions.list(response.data.result));
        }
      })
      .catch((error) => {
        SettlementSalesMiddleware.alertHendler(dispatch,error)
      });
}

  static showOne(dispatch, params){
    axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${SettlementSalesMiddleware.getToken()}`
        },
        url: url +'/'+params,
        responseType: 'json'
      })
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          dispatch(SettlementSalesActions.view(response.data.result));
        }
      })
      .catch((error) => {
        SettlementSalesMiddleware.alertHendler(dispatch,error)
      });
  }

  static alertHendler(dispatch,result,isSwal=false,extraMessage='',type='error'){
    if(type=='error'){
      if (result.response) {
        const status=result.response.status
        switch(status){
          
          case 500:
            if(isSwal){
              const data=result.response.data
              MySwal.hideLoading()
              MySwal.update({
                title:'Delete Error',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              })
            }else{
              const data=result.response.data
              dispatch(SettlementSalesActions.error(data.error));
            }
          break;  
          case 403:
            console.log('Error 403');
          break;     
          case 404:
            console.log('Error 404');
          break;   
          case 401:
            const data=result.response.data
              MySwal.fire({
                title:'Login Invalid',
                html:data.error,
                icon:'error',
                showConfirmButton:true,
              }).then((result)=>{
                  if(result.isConfirmed){
                    <Navigate to="/login"></Navigate>
                  }
              })
          break;   
        }
      } else if (result.request) {
        MySwal.fire({
          title:'Internal Server Error',
          html:'Please info your Developer to fix this',
          icon:'error',
          showConfirmButton:true,
        })
        dispatch(SettlementSalesActions.reset());
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', result.message);
      }
    }else{
      MySwal.fire({
        title:'Success',
        html:extraMessage,
        icon:'success',
        showConfirmButton:true,
      })
    }
  }
}
