import ItemActions from "./actions";

const INITIAL_STATE = {
  data: {},
  isProcessing: false,
  isError: false,
  errorMessage: [],
  form: {},
  isNewRecord: false,
  showModal: false,
  onSubmit: false,
  single: null,
  helperItem: [],
  errorInternal: false,
};

function ItemReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ItemActions.RESET_LIST:
      return INITIAL_STATE;
    case ItemActions.NEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        form: INITIAL_STATE.form,
        isNewRecord: true,
        showModal: true,
      };
    case ItemActions.UPDATE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: null,
        isNewRecord: false,
      };
    case ItemActions.VIEW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: action.payload,
        isNewRecord: false,
        showModal: true,
      };
      case ItemActions.VIEW_DATA_ITEM:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        single: action.payload,
      };
    case ItemActions.RESET_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case ItemActions.DELETE_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isProcessing: false,
        form: INITIAL_STATE.form,
        showModal: false,
      };
    case ItemActions.ON_SUBMIT:
      return { ...state, errorInternal: false, onSubmit: true };
    case ItemActions.SHOW_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        data: action.payload,
        errorMessage: [],
        isProcessing: false,
      };
    case ItemActions.SUCCESS_DATA:
      return {
        ...state,
        errorInternal: false,
        onSubmit: false,
        isError: false,
        showModal: false,
        form: INITIAL_STATE.form,
        onSubmit: false,
      };
    case ItemActions.PROCESS_DATA:
      return {
        ...state,
        isProcessing: true,
        onSubmit: false,
        isError: false,
        isSuccess: false,
      };
    case ItemActions.ERROR_DATA:
      return {
        ...state,
        onSubmit: false,
        errorInternal: false,
        isError: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case ItemActions.ERROR_SERVER:
      return {
        ...state,
        isError: false,
        errorInternal: true,
        onSubmit: false,
        errorMessage: action.payload,
        isProcessing: false,
      };
    case ItemActions.GET_HELPER_ITEM:
      return {
        ...state,
        errorInternal: false,
        isError: false,
        isSuccess: false,
        onSubmit: false,
        helperItem: action.payload,
      };
    default:
      return state;
  }
}

export default ItemReducer;
