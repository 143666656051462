import React, { Component, Fragment } from "react";
import cookieManager from "../../../../utils/cookieManager";
import i18n from "../../../../i18n";
import { Button, Table } from "react-bootstrap";
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import SellingDetail from "./selling-detail";
import Customer from "./customer";
import { formatCurrency } from "../../../utilities/Class/FormatNumber";
import LabelSelling from "../../../utilities/Status/selling";
const token = cookieManager.getUserToken();

export class SellingView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
  }
}

render() {
    const {dataUpdate,isProcessing}=this.props
    let arr = [];
    if(dataUpdate!=null && dataUpdate!=undefined){
      arr = dataUpdate;
    }
    return (
     
      <React.Fragment>
        
          <div id="Item">
            {/* <Breadcrumbs url={this.state.breadcrumbs} tools={false} /> */}
            <div
              id="kt_content_container"
              className="d-flex flex-column-fluid align-items-start container-fluid"
            >
              <div className="content flex-row-fluid" id="kt_content">
                <div className="card">
                  <div className="card-header d-flex justify-content-start border-0 pt-6">
                    <div className="card-toolbar">
                      <div
                        className="d-flex justify-content-start"
                        data-kt-customer-table-toolbar="base"
                      >
                        <Link
                            to={"/sales/sales-invoice"}
                            onClick={() => changeLocation("/sales/sales-invoice")}
                            >
                        <Button
                          type="button"
                          className="btn btn-danger btn-sm m-1"
                          // onClick={(e) => this.runSearch(this.state.search)}
                        ><FontAwesomeIcon icon={faLongArrowAltLeft}></FontAwesomeIcon>{i18n.t('  Back')}</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className={isProcessing ? " table-loading" : ""}>
                    {!isProcessing ? (
                      <Fragment>
                      <div className="card-body pt10 pb0 d-flex flex-row" >  
                        <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-325px">
                          <h3>{i18n.t("Sales Invoice")}</h3>
                        </div>
                      </div>
                      <div className="card-body pt5 pb0 d-flex flex-row" >  
                        <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-500px">
                        <table >
                            <thead>
                            </thead>
                            <tbody style={{fontSize:'16px', fontWeight:'bold'}}>
                                <tr >
                                  <td>{i18n.t("Branch Name")}</td>
                                  <td>:</td><td>{arr?.name_branch||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Transation No.")}</td>
                                  <td>:</td><td>{arr?.transaction_no||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Date")}</td>
                                  <td>:</td><td>{arr?.date_format||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Payment Type")}</td>
                                  <td>:</td><td>{i18n.t(arr?.payment_type||'')}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Total")}</td>
                                  <td>:</td><td>{"Rp. " + formatCurrency(arr?.sub_total||0)}</td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-lg-flex flex-column flex-lg-row-auto w-lg-500px">
                        <table >
                            <thead>
                            </thead>
                            <tbody style={{fontSize:'16px', fontWeight:'bold'}}>
                                <tr >
                                  <td>{i18n.t("Sales Order No.")}</td>
                                  <td>:</td><td>{arr?.id_so||'-'}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Sales Name")}</td>
                                  <td>:</td><td>{arr?.name_sales||''}</td>
                                </tr>
                                <tr >
                                  <td>{i18n.t("Status")}</td>
                                  <td>:</td><td><LabelSelling value={arr?.status||''} /></td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <hr/>
                      <div className="card-body pt5 pb0 d-flex flex-row">  
                        <div  className="d-lg-flex flex-column flex-lg-row-auto ">
                          <div className="card-toolbar m-0">
                            <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
                              <li className="nav-item" role="presentation">
                                <a className="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#detail">{i18n.t('Detail')}</a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a  className="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#customer">{i18n.t('Customer')}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="" style={{paddingLeft:'2.5rem',paddingRight:'2.5rem'}}> 
                        <div className="">
                          <div className="tab-content">
                            <div id="detail" className=" p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="timeline">
                              <SellingDetail data={dataUpdate?.selling_detail}>

                              </SellingDetail>
                            </div>
                            <div id="customer" className=" p-0 tab-pane fade show" role="tabpanel" aria-labelledby="status">
                              <Customer data={dataUpdate?.data_customer}>

                              </Customer>
                            </div>
											  </div>
										  </div> 
                      </div>
                      <div className="card-body pt10 pb0">  
                      </div>
                      </Fragment>
                        ):(
                          <div className="table-loading-message">
                            <div className="spinner-border  text-primary text-center ms-3 clearfix"></div>
                            <div className="text-center">{i18n.t("Loading")}</div>
                          </div>
                        )}
                      </div>
                </div>
              </div>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default SellingView;
