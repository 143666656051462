const ID ={
    "Home":"Beranda",

"Information":"Informasi",
"Dashboard":"Dasbor",
"Information Dashboard":"Dasbor Informasi",
"Expected Earnings":"Pendapatan yang diharapkan",
"Daily":"Harian",
"Week":"Minggu",
"Month":"Bulan",
"Daily Sales":"Penjualan Harian",
"Sales Name":"Nama Sales",
"Sales This Months":"Penjualan bulan ini",
"Orders":"Pesanan",
"Orders This Week":"Pesanan minggu ini",
"to":"ke",
"Goal":"Target",
"to Goal":"ke Target",
"New":"Baru",
"New Customers This Month":"Pelanggan baru minggu ini",
"Active":"Aktif",
"Active Sales":"Sales Aktif",
"Recent Orders":"Pesanan Terbaru",
"Item Name":"Nama Barang",
"Discount Sales":"Diskon Penjualan",
"Total Discounted Sales This Month":"Total Diskon Penjualan Bulan Ini",

"Utility":"Utilitas",
"List":"Daftar",
"Hierarchy":"Hirarki",
"Setup":"Pengaturan",
"Warehouse":"Gudang",
"Warehouse Mutation":"Mutasi Gudang",
"Setup Warehouse":"Pengaturan Gudang",
"Sub Warehouse":"Sub Gudang",
"SubWarehouse":"Sub Gudang",
"Supplier":"Pemasok",
"Supplier List":"Daftar Pemasok",
"Supplier Name":"Nama Pemasok",
"Taking":"Pengambilan",

"Customer":"Pelanggan",
"Customers":"Pelanggan",
"Type":"Tipe",
"Customer Type":"Tipe Pelanggan",
"Customer List":"Daftar Pelanggan",
"Customer Hierarchy":"Hirarki Pelanggan",
"Setup Hierarchy":"Pengaturan Hirarki",
"Code":"Kode",
"Code Customer":"Kode Pelanggan",
"Code Branch":"Kode ASMO",
"Customer Code":"Kode Pelanggan",
"Branch":"ASMO",
"Branch Name":"ASMO",
"Branch List":"Daftar ASMO",
"Store":"Depo",
"Store List":"Daftar Depo",
"Payment":"Pembayaran",
"Payment Type":"Tipe Pembayaran",
"Address":"Alamat",
"Province":"Provinsi",
"City":"Kota",
"District":"Kecamatan",
"Sub District":"Kelurahan",
"Long Business":"Lama Usaha",
"Phone":"Telepon",
"ID Number":"No KTP",
"Contact Person":"Kontak Person",
"Contact Person Phone":"Telepon CP",
"Tax":"Pajak",
"Tax Status":"Status Pajak",
"Tax List":"Datar Pajak",
"Competitors":"Kompetitor",
"Item":"Barang",
"Selling (Pack)":"Penjualan (Pack)",
"Selling Price":"Harga Jual",
"Turnover/Week":"Omzet/Minggu",
"Invoice Limit":"Limit Nota",
"Credit Limit":"Limit Kredit",
"Note":"Catatan",

"Value":"Nilai",
"Parent":"Induk",
"Warehouse Parent":"Gudang Utama",
"Warehouse List":"Daftar Gudang",

"Unit":"Satuan",
"Unit List":"Daftar Satuan",
"Brand":"Merk",
"Item Brand List":"Daftar Merk",
"Brand Item":"Merk",
"Category":"Kategori",
"Item Category List":"Daftar Kategori",
"Category Item":"Kategori",
"Item Type":"Tipe",
"Type Item":"Tipe",
"Account":"Akun",
"Inventory":"Persediaan",
"Inventory Account":"Akun Persediaan",

"Item List":"Daftar Barang",
"User":"Pengguna",
"User Assignment":"Penugasan Pengguna",
"Assignment":"Penugasan",
"Route":"Rute",
"Roles":"Peran",
"Role":"Peran",
"Name User":"Nama Pengguna",
"Set Assignment":"Pengaturan",
"Update User":"Edit",
"Delete User":"Hapus",
"Block User":"Blokir",
"Assignment Choosed":"Penugasan yang Dipilih",

"Approval Transaction":"Persertujuan Transaksi",
"Type Transaction":"Tipe Transaksi",
"Approval Goods Offer":"Penawaran",
"Approval Memo In":"Memo In",
"Approval Memo Out":"Memo Out",
"Approval Purchase Order":"Pesanan Pembelian",
"Approval Return Price":"Harga Retur",
"Approval Return Selling":"Retur Penjualan",
"Approval Selling Price":"Harga Jual",
"Approval Taking Goods":"Pengambilan Barang",

"Name":"Nama",
"Created":"Dibuat",
"at":"Oleh",
"Created at":"Dibuat Oleh",
"Updated at":"Diperbarui Oleh",
"Updated":"Diperbarui",

"All":"Semua",
"Active":"Aktif",
"Non":"Tidak",
"Non Active":"Tidak Aktif",

"Add":"Tambah",
"Add Customer Type":"Tambah Tipe",
"Add Customer":"Tambah Pelanggan",
"Add Competitors":"Tambah Komeptitor",
"Add Setup Hierarchy":"Tambah Pengaturan",
"Add Warehouse":"Tambah Gudang",
"Add Supplier":"Tambah Pemasok",
"Add Unit":"Tambah Satuan",
"Add Brand Item":"Tambah Merk",
"Add Category Item":"Tambah Kategori",
"Add Type Item":"Tambah Tipe",
"Add Item":"Tambah Barang",
"Add Role":"Tambah Peran",
"Add Branch":"Tambah ASMO",
"Add Store":"Tambah Depo",
"Add Tax":"Tambah Pajak",
"Add Offering":"Tambah Penawaran",
"Add Purchase Request":"Tambah Permintaan",
"Add Details":"Tambah Barang",
"Add Quotation":"Tambah Penawaran",
"Add Purchase Order":"Tambah Pesanan",
"Add Taking Goods":"Tambah Pengambilan",
"Save":"Simpan",
"Close":"Tutup",
"Action":"Aksi",
"Edit":"Ubah",
"Edit Config Branch":"Ubah Konfigurasi",
"Delete":"Hapus",
"View":"Lihat",
"Choose File":"Pilih Dokumen",
"Select":"Pilih",
"Select...":"Pilih",
"Select Branch":"Pilih ASMO",
"Select Store":"Pilih Depo",
"Select Type Customer":"Pilih Tipe",
"Select Type":"Pilih Tipe",
"Select Province":"Pilih Provinsi",
"Select City":"Pilih Kota",
"Select District":"Pilih Kecamatan",
"Select Sub District":"Pilih Kelurahan",
"Select Customer Type":"Pilih Tipe",
"Select Warehouse":"Pilih Gudang",
"Select Sub Warehouse":"Pilih Sub Gudang",
"Select Parent":"Pilih Satuan Utama",
"Select Account":"Pilih Akun",
"Select Account COA":"Pilih Akun",
"Select Supplier":"Pilih Pemasok",
"Select Item":"Pilih Barang",
"Select Item Type":"Pilih Tipe Barang",
"Select Unit":"Pilih Satuan",
"Select Sales":"Pilih Sales",
"Select Sales Order":"Pilih Pesanan",
"Select/Create The Supplier":"Pilih/Tambah Pemasok",
"Upload Files (max 3 items)":"Unggah Dokumen (Maks 3 item)",
"Cash":"Tunai",
"Credit":"Kredit",
"Supervisior":"Supervisor",

"Sales Order":"Pesanan Penjualan",
"Quotation Request":"Pengajuan Penawaran",
"No. Quotation":" No Penawaran",
"Quote No.":"No Penawaran",
"you can create the temporary supplier with type the name supplier":"Nama pemasok sementara dapat dibuat dengan langsung mengetik nama pemasok",
"Request List":"Daftar Permintaan",
"Recap PR":"Rekap Permintaan",
"Qty Order":"Jumlah Permintaan",
"Qty Mutation":"Jumlah Mutasi",
"Qty Request":"Jumlah Permintaan",
"Set Supplier Request":"Mengatur Pemasok",
"Mutation Items":"Mutasi Barang",

"Purchase Order":"Pesanan Pembelian",
"No Order":"No Pesanan",
"Tanggal Order":"Tanggal Pesan",
"Grand Total":"Total",
"Remaining":"Sisa",
"Nett Price":"Harga Nett",

"Duration Rest Up (Minute)":"Durasi Istirahat Max (Menit)",
"Duration Rest Down (Minute)":"Durasi Istirahat Min (Menit)",
"Tolerance Rest (Minute)":"Toleransi Istirahat (Menit)",
"Tolerance Checkin (Minute)":"Toleransi Jam Masuk",
"Tolerance Checkout (Minute)":"Toleransi Jam Pulang",
"Tolerance Distance (Minute)":"Toleransi Jarak Absen",
"Check In Time":"Jam Masuk",
"Check Out Time":"Jam Pulang",
"Timezone":"Zona Waktu",

"Language":"Bahasa",
"Select Status":'Pilih Status',

"Offering":"Penawaran",
"Receiving":"Penerimaan Barang",
"Mutation":"Mutasi Barang",

"Purchase Request":"Permintaan Pembelian",
"Date":"Tanggal",
"Purchase Request No.":"No Permintaan",
"No. Request":"No Permintaan",

"Waiting For Approval":"Menunggu Persetujuan",
"Procurement":"Pengadaan",
"PR Rejected":"Ditolak",
"PO Rejected":"Order Ditolak",
"PO Closed":"Order Ditutup",
"Waiting Center Approval":"Menunggu Persetujuan HO",
"Waiting Receiving Item":"Menunggu Penerimaan Barang",
"Waiting For Payment":"Menunggu Pembayaran",
"Done":"Selesai",
"Warehouse Transfer":"Pindah Gudang",
"Approved":"Disetujui",
"Rejected":"Ditolak",
"Waiting HO Approval":"Menunggu Persetujuan HO",

"Taking Goods":"Pengambilan Barang",
"Taking Goods No.":"No Pengambilan",

"PO No":"No Order",
"Date PO":"Tanggal Order",
"Date Delivery":"Tanggal Kirim",
"Discount Percent":"Diskon (%)",
"Discount (Rp)":"Diskon (Rp)",
"Price":"Harga",
"Price Nett":"Harga",
"Price Total":"Total Harga",
"Total":"Total Harga",
"Total Price":"Total Harga",
"Total Discount":"Total Diskon",
"Total Tax":"Total Pajak",
"Code Item":"Kode Barang",
"Item Code":"Kode Barang",

"No PO":"No Order",
"Band No":"Banderol",
"Reamining":"Remaining Qty",
"Qty":"Jumlah",
"Receiving (PB)":"Goods Receipt",
"No. PB":"Receiving No.",
"No. Band": "Banderol",
"Description":"Keterangan",
"Document No":"No Dokumen",
"Delivery Date":"Tanggal Pengiriman",

"Config Name":"Nama Konfigurasi",
"Config Branch":"Konfigurasi ASMO",
"Configuration" : "Konfigurasi",
"Persentage":"Persentase",
"accSetup":"Pilih Akun",
"Effective Date":"Tanggal Efektif",
"Purchasing":"Pembelian",
"Transaction":"Transaksi",
"Transaction No.":"No Transaksi",
"Order":"Pesanan",
"Goods Offer":"Penawaran",
"Return":"Kembali",
"Return Date":"Tanggal Kembali",

"January":"Januari",
"February":"Februari",
"March":"Maret",
"April":"April",
"May":"Mei",
"June":"Juni",
"July":"Juli",
"August":"Agustus",
"September":"September",
"October":"Oktober",
"November":"November",
"December":"Desember",

"Monday":"Senin",
"Tuesday":"Selasa",
"Wednesday":"Rabu",
"Thursday":"Kamis",
"Friday":"Jumat",
"Saturday":"Sabtu",
"Sunday":"Minggu",

}
export default ID;
