import ChartForm from "../../utilities/chart";
import React from "react";
import i18n from "../../../i18n";
import { formatNumber } from "../../utilities/Class/FormatNumber";

export default function SectionSalesThisMonth(props) {
    const arr = props.data;
    return (
        <div className="card card-flush overflow-hidden h-md-100">
            <div className="card-header py-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-900">{i18n.t("Sales This Months")}</span>
                    {/* // <span className="text-gray-500 mt-1 fw-semibold fs-6">Users from all channels</span> */}
                </h3>
            </div>

            <div className="card-body d-flex  flex-column pb-1 px-0">
                <div className="px-9 mb-5">
                    <div className="d-flex mb-2">
                        <span className="fs-4 fw-semibold text-gray-500 me-1">Rp.</span>
                        <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{(arr.salesMonth != null ? formatNumber(arr.salesMonth.total) : 0)}</span>
                    </div>
                </div>

                <div className="min-h-auto ps-4 pe-6" style={{ height: "300px", minHeight: "315px" }}>

                    <ChartForm
                        type={'area'}
                        dataList={arr.salesMonth}
                        height={"350px"}
                    />

                </div>

            </div>

        </div>
    );
}


