import {  faPersonCircleCheck, faPersonPraying, faPersonWalkingLuggage, faStore, faWalking } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React,{ useState } from "react"
// import ModalCustom from "./modal"
import { Button, Table } from "react-bootstrap"
import TypeLogTx from "../../../utilities/Const/typeLogTx"
import cookieManager from "../../../../utils/cookieManager"
import i18n from "../../../../i18n"
import { formatCurrency, formatNumber } from "../../../utilities/Class/FormatNumber"

export default function SellingDetail(props){
    const [showDetail,setShowDetails]=useState(false)
    const [details,setDetails]=useState(null)
    const token=cookieManager.getUserToken()
    return (
      <div className="timeline timeline-border-dashed">
          <div className="text-center">
               <Table
                className="table-responsive bordered"
               >
                <thead className="bg-success text-white" >
                  <tr>
                    <th>{i18n.t('No.')}</th>
                    <th>{i18n.t('Item Code')}</th>
                    <th>{i18n.t('Item Name')}</th>
                    <th>{i18n.t('Unit Name')}</th>
                    <th>{i18n.t('Band No.')}</th>
                    <th>{i18n.t('Qty')}</th>
                    <th>{i18n.t('Price')}</th>
                    <th>{i18n.t('Discount')}</th>
                    <th>{i18n.t('Total Price')}</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data != null ? 
                    props.data.map((read,index)=>{
                      return (
                        <tr key={'data'+index}>
                          <td>
                            {index+1}
                          </td>
                          <td>
                            {read?.code_item||''}
                          </td>
                          <td>
                            {read?.name_item||''}
                          </td>
                          <td>
                            {read?.name_unit||''}
                          </td>
                          <td>
                            {read?.band_no||''}
                          </td>
                          <td>
                            {formatNumber(read?.qty||0)}
                          </td>
                          <td>
                            {"Rp. "+ formatCurrency(read?.nett_price||0)}
                          </td>
                          <td>
                            {"Rp. "+ formatCurrency(read?.discount_price||0)}
                          </td>
                          <td>
                            {"Rp. "+ formatCurrency(read?.total||0)}
                          </td>
                        </tr>
                      );
                  }):null}
                </tbody>
                </Table>
            </div>
      </div>
    )
}