
import React from "react";
import i18n from "../../../i18n";
import { stockReport } from "../Const/TranscationCode";

const LabelStock=(props)=>{
     const filter=stockReport.filter((item)=>item.key===props.value);
        if(filter.length==1){
            return <div className={"badge badge-light-" + (filter[0].color)}>{(filter[0].label)}</div>
        }   
        return ''
}
export default LabelStock;