import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import i18n from "../../../../i18n";

Font.register({
    family: 'Times-Roman',
    fonts: [
        { src: 'assets/fonts/times.ttf', fontWeight: 400 },
        { src: 'assets/fonts/timesbd.ttf', fontWeight: 900 }
    ]
});
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    page: {
        marginTop: '10px'
    },
    logo: {
        width: 80,
        height: 80,
    },
    body: {
        // paddingTop: "10px",
        // border:'1px',
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "20px",
    },
    row: {
        flex: 1,
        flexDirection: 'row',
    },
    rowHeader: {
        flex: 1,
        flexDirection: 'row',
    },
    col: {
        flex: 1,
        flexDirection: 'column',
    },
    colBorder: {
        flex: 1,
        borderLeft: 1,
        flexDirection: 'column',
    },
    block: {
        flexGrow: 1,
    },
    tr: {
        borderBottom: '1px',
        flexDirection: 'row',
    },
    trHeader: {
        backgroundColor: 'rgba(255, 255, 128)',
        borderBottom: '1px',
        flexDirection: 'row',
    },
    td: {
        borderRight: '1px',
        borderLeft: '1px',
        flexDirection: 'column',
    },
    tdcol: {
        colspan: '2',
        flexDirection: 'column',
    },

    //Header
    titleContainer: {
        flexDirection: 'row',
    },
    titleHeader: {
        flexDirection: 'row',
        marginBottom: '10px'
    },
    header: {
        fontSize: "18px",
        fontFamily: 'Times-Roman'
    },
    address: {
        fontSize: "12px",
        fontFamily: 'Times-Roman'
    },

    titleTranx: {
        fontSize: "12px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'left',
        marginBottom: '5px'
    },
    title: {
        fontSize: "15px",
        fontWeight: 700,
        fontFamily: 'Times-Bold',
        textAlign: 'center',
        marginBottom: '5px'
    },

    div: {
        width: '100%',
        height: 400,
        border: '1 solid black'
        // fontSize: "15px",
        // fontWeight: 700,
        // fontFamily: 'Times-Bold',
        // textAlign: 'center',
        // marginBottom:'5px'
    },

    tableTitle: {
        borderTop: '1px solid black',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        // padding: '5px',
    },

    tableTotal: {
        fontSize: "12px",
        fontFamily: 'Times-Roman',
        padding: '5px',
        marginTop: '2px'
    },
    //End Header
    //Table Item
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#bff0fd',
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: 'Times-Roman',
        border: '2px'
    },

    tableName: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        textAlign: 'center',
        fontSize: "12px",
        fontWeight: 400,
        borderRight: 1,
        borderBottom: 1,
        fontFamily: 'Times-Roman',
    },
    //Dalam Table
    menuHeader: {
        textAlign: 'center',
        fontFamily: 'Times-Bold',
        fontSize: "10px",
    },

    totalBottom: {
        textAlign: 'right',
        fontFamily: 'Times-Bold',
        paddingRight: '1px',
        paddingTop: '2px',
        fontSize: "8px",
    },

    fill: {
        textAlign: 'center',
        // fontWeight: 500,
        fontSize: "9px",
    },

    fillText: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "6px",
        paddingTop: '1px'

    },
    fillTextType: {
        textAlign: 'left',
        paddingLeft: '1px',
        fontSize: "8px",
        fontFamily: 'Times-Bold',
    },
    fillTextUnit: {
        textAlign: 'center',
        paddingLeft: '1px',
        fontSize: "7px",
    },

    fillNumber: {
        textAlign: 'right',
        paddingRight: '1px',
        fontSize: "8px",
    },

    tableRemark: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        fontSize: "12px",
        fontWeight: 400,
        textAlign: 'center',
        borderBottom: 1,
        borderRight: 1,
        borderTop: 1,
        fontFamily: 'Times-Roman',
    },

    tableSign: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        fontSize: "12px",
        height: '15%',
        fontWeight: 400,
        textAlign: 'center',
        borderBottom: 1,
        borderRight: 1,
        fontFamily: 'Times-Roman',
    },

    containerItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        // borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        border: '2px',
        borderRight: '1px'

    },
    rowItem: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,

    },
    rowTableFooter: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        // height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    descriptionTableFooter: {
        width: '86%',
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        paddingRight: 8,
    },
    totalTableFooter: {
        width: '14%',
        textAlign: 'right',
        paddingRight: 8,
    },

    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 10,
        top: 100,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },

    //end Table

    //Footer
    footer: {
        position: 'absolute',
        bottom: 0
    }
    //end Footer
});
const tableRowsCount = 11;
class PrintStockCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: i18n.t("Laporan Persediaan Barang"),
            merchant: {
                email: null,
                fax: null,
                logo: '/assets/media/logos/logo.jpg',
                name: "CV. CELEBES PUTERA SEJAHTERA",
                phone: null,
                website: null,
                address: []
            },
            type: [],
            logo: '/assets/media/logos/logo.jpg',
            data: [],
            fix: 0,
            total: 0,
            totalPOSM: 0
        };
    }
    renderMessage() {
        const { isProcessing, requestPrint } = this.props
        switch (true) {
            case isProcessing:
                return (
                    <div style={loader}>
                        <div className="spinner-border text-primary" style={{ width: '10rem', height: '10rem' }} role="status"></div><br />
                        <div style={{ fontSize: 36 }}>Getting Data From Server</div>
                    </div>)
            case requestPrint:
                return (
                    <div style={loader}>
                        {/* <div className="spinner-border text-primary" style={{width: '10rem', height: '10rem'}} role="status"></div><br/> */}
                        <div style={{ fontSize: 36 }}>Please Wait Response from Head Office </div>
                        <div><Button variant="primary" onClick={() => { this.props.getPrint(this.props.poid) }}>Reload Page</Button></div>
                    </div>)
        }
    }

    renderPOSM() {
        const { dataPrint } = this.props
        let html = [];
        if (dataPrint != null) {
            let posm = dataPrint.dataPOSM;
            for (var i = 0; i < posm.length; i++) {
                html.push(
                    <Fragment key={'dataBarangPOSM' + i}>
                        <View style={styles.tr} break={((i / 25) === 1)} >
                            <Fragment>
                                <View style={[styles.td, { width: '150px' }]} >
                                    <Text style={styles.fillText}>{posm[i].name_item}</Text>
                                </View>
                                <View style={[styles.td, { width: '50px' }]} >
                                    <Text style={styles.fillTextUnit}>{posm[i].name_unit}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].stock_awal_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].pembelian_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].total_stock_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].penjualan_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{''}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].wh_in_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].wh_out_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].memo_in_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].memo_out_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].retur_format}</Text>
                                </View>
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].stock_akhir_format}</Text>
                                </View>
                                {/* <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].stock_cover_format}</Text>
                                </View> */}
                                <View style={[styles.td, { width: '75px' }]} >
                                    <Text style={styles.fillNumber}>{posm[i].transit_format}</Text>
                                </View>
                            </Fragment>
                        </View>
                    </Fragment>
                );
            }
        }
        return html;
    }

    renderPage(id) {
        const { dataPrint } = this.props
        let html = [];
        if (dataPrint != null) {
            let item = this.state.data;
            let type = dataPrint.type;

            for (var p = 0; p < item.length; p++) {
                if (id == item[p].id_category) {
                    html.push(
                        <Fragment key={'dataBarang' + p}>
                            <View style={styles.tr} break={((p / 25) === 1)} >
                                <Fragment>
                                    <View style={[styles.td, { width: '150px' }]} >
                                        <Text style={styles.fillText}>{item[p].name_item}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '50px' }]} >
                                        <Text style={styles.fillTextUnit}>{item[p].name_unit}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].stock_awal_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].pembelian_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].total_stock_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].penjualan_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].taking_good_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].wh_in_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].wh_out_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].memo_in_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].memo_out_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].retur_format}</Text>
                                    </View>
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].stock_akhir_format}</Text>
                                    </View>
                                    {/* <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].stock_cover_format}</Text>
                                    </View> */}
                                    <View style={[styles.td, { width: '75px' }]} >
                                        <Text style={styles.fillNumber}>{item[p].transit_format}</Text>
                                    </View>
                                </Fragment>
                            </View>
                        </Fragment>
                    );
                }

            }

            return html;
        }
    }

    renderTotal() {
        const { dataPrint } = this.props
        let submenu = [];
        let html = [];
        let total = [];

        if (dataPrint != null) {
            let row = dataPrint.data
            let totalSaldoAwal = 0;
            let totalPembelian = 0;
            let totalStokAwal = 0;
            let totalPenjualan = 0;
            let totalBppb = 0;
            let totalWHIn = 0;
            let totalWHOut = 0;
            let totalMemoIn = 0;
            let totalMemoOut = 0;
            let totalRetur = 0;
            let totalStokAkhir = 0;
            let totalCover = 0;
            let totalTransit = 0;

            if (row.length > 0) {
                for (var i = 0; i < row.length; i++) {
                    totalSaldoAwal += row[i].stock_awal;
                    totalPembelian += row[i].pembelian;
                    totalStokAwal += row[i].total_stock;
                    totalPenjualan += row[i].penjualan;
                    totalBppb += row[i].taking_good;
                    totalWHIn += row[i].wh_in;
                    totalWHOut += row[i].wh_out;
                    totalMemoIn += row[i].memo_in;
                    totalMemoOut += row[i].memo_out;
                    totalRetur += row[i].retur;
                    totalStokAkhir += row[i].stock_akhir;
                    totalCover += row[i].stock_cover;
                    totalTransit += row[i].transit;
                }
            }
            total.push('Total', 'Unit', totalSaldoAwal,
                totalPembelian, totalStokAwal, totalPenjualan, totalBppb, totalWHIn, totalWHOut,
                totalMemoIn, totalMemoOut, totalRetur, totalStokAkhir, totalTransit);
        }
        submenu.push('Item', 'Unit', '', '', '', '', '', 'Masuk', 'Keluar', 'Masuk', 'Keluar', '',  '', '');

        html.push(
            <View style={styles.tableTitle} >
                <View style={styles.trHeader}>
                    {submenu.length > 0 ? submenu.map((dataSubMenu, i) =>
                        <Fragment key={'menuHeader' + i}>
                            <View style={[styles.td, { width: dataSubMenu == 'Unit' ? '50px' : dataSubMenu == 'Item' ? '150px' : '75px' }]} >
                                <Text style={total[i] == 'Total' || total[i] == 'Unit' ? styles.menuHeader : styles.totalBottom}>{total[i] == 'Total' ? 'Total' : total[i] == 'Unit' ? '' : parseFloat(total[i]).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                            </View>
                        </Fragment>
                    )
                        : null}
                </View>
            </View>
        );
        return html;
    }

    renderTotalPOSM() {
        const { dataPrint } = this.props
        let submenu = [];
        let html = [];
        let totalPOSM = []
        if (dataPrint != null) {
            let rowPOSM = dataPrint.dataPOSM;

            let totalSaldoAwalPOSM = 0;
            let totalPembelianPOSM = 0;
            let totalStokAwalPOSM = 0;
            let totalPenjualanPOSM = 0;
            let totalBppbPOSM = 0;
            let totalWHInPOSM = 0;
            let totalWHOutPOSM = 0;
            let totalMemoInPOSM = 0;
            let totalMemoOutPOSM = 0;
            let totalReturPOSM = 0;
            let totalStokAkhirPOSM = 0;
            let totalCoverPOSM = 0;
            let totalTransitPOSM = 0;

            if (rowPOSM.length > 0) {
                for (var i = 0; i < rowPOSM.length; i++) {
                    totalSaldoAwalPOSM += rowPOSM[i].stock_awal;
                    totalPembelianPOSM += rowPOSM[i].pembelian;
                    totalStokAwalPOSM += rowPOSM[i].total_stock;
                    totalPenjualanPOSM += rowPOSM[i].penjualan;
                    totalBppbPOSM += rowPOSM[i].taking_good;
                    totalWHInPOSM += rowPOSM[i].wh_in;
                    totalWHOutPOSM += rowPOSM[i].wh_out;
                    totalMemoInPOSM += rowPOSM[i].memo_in;
                    totalMemoOutPOSM += rowPOSM[i].memo_out;
                    totalReturPOSM += rowPOSM[i].retur;
                    totalStokAkhirPOSM += rowPOSM[i].stock_akhir;
                    totalCoverPOSM += rowPOSM[i].stock_cover;
                    totalTransitPOSM += rowPOSM[i].transit;
                }
            }

            submenu.push('Item', 'Unit', '', '', '', '', '', 'Masuk', 'Keluar', 'Masuk', 'Keluar',  '', '', '');

            totalPOSM.push('Total', 'Unit', totalSaldoAwalPOSM,
                totalPembelianPOSM, totalStokAwalPOSM, totalPenjualanPOSM, totalBppbPOSM, totalWHInPOSM, totalWHOutPOSM,
                totalMemoInPOSM, totalMemoOutPOSM, totalReturPOSM, totalStokAkhirPOSM, totalTransitPOSM);
        }

        html.push(
            <View style={styles.tableTitle} >
                <View style={styles.trHeader}>
                    {submenu.length > 0 ? submenu.map((dataSubMenu, i) =>
                        <Fragment key={'menuHeader' + i}>
                            <View style={[styles.td, { width: dataSubMenu == 'Unit' ? '50px' : dataSubMenu == 'Item' ? '150px' : '75px' }]} >
                                <Text style={totalPOSM[i] == 'Total' || totalPOSM[i] == 'Unit' ? styles.menuHeader : styles.totalBottom}>{totalPOSM[i] == 'Total' ? 'Total' : totalPOSM[i] == 'Unit' ? '' : parseFloat(totalPOSM[i]).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                            </View>
                        </Fragment>
                    )
                        : null}
                </View>
            </View>
        );
        return html;
    }

    renderMenu() {
        let header = [];
        let menu = [];
        let submenu = [];
        menu.push('Item', 'Unit', 'Awal', 'Beli', 'Total', 'Jual', 'BPPB', 'Warehouse', 'Memo', 'Retur', 'Akhir', 'In Transit');
        submenu.push('Item', 'Unit', '', '', '', '', '', 'Masuk', 'Keluar', 'Masuk', 'Keluar', '', '', '');
        header.push(
            <View fixed style={styles.tableTitle}  >
                <View style={styles.trHeader}>
                    {menu.length > 0 ? menu.map((dataMenu, i) =>
                        <Fragment key={'menuHeader' + i}>
                            <View style={[styles.td, { width: dataMenu == 'Warehouse' || dataMenu == 'Memo' ? '150px' : dataMenu == 'Unit' ? '50px' : dataMenu == 'Item' ? '150px' : '75px' }]} >
                                <Text style={styles.menuHeader}>{dataMenu}</Text>
                            </View>
                        </Fragment>
                    )
                        : null}
                </View>
                <View style={styles.trHeader}>
                    {submenu.length > 0 ? submenu.map((dataSubMenu, i) =>
                        <Fragment key={'menuSub' + i}>
                            <View style={[styles.td, { width: dataSubMenu == 'Unit' ? '50px' : dataSubMenu == 'Item' ? '150px' : '75px' }]} >
                                <Text style={styles.menuHeader}>{dataSubMenu == 'Unit' || dataSubMenu == 'Item' ? '' : dataSubMenu}</Text>
                            </View>
                        </Fragment>
                    )
                        : null}
                </View>
                <View style={styles.trHeader}>
                    {submenu.length > 0 ? submenu.map((dataSubMenu, i) =>
                        <Fragment key={'menuCount' + i}>
                            <View style={[styles.td, { width: dataSubMenu == 'Unit' ? '50px' : dataSubMenu == 'Item' ? '150px' : '75px' }]} >
                                <Text style={styles.menuHeader}>{i + 1}</Text>
                            </View>
                        </Fragment>
                    )
                        : null}
                </View>
            </View>
        );
        return header;
    }

    renderHeader() {
        const { dataPrint } = this.props
        let html = [];
        let address = [];
        if (dataPrint != null && dataPrint != '') {
            this.state.merchant = dataPrint.merchant
            address = this.state.merchant?.address.split(/\r?\n/)||null
        }

        html.push(
            <Fragment>
                <View fixed style={styles.titleHeader}>
                    <View style={styles.row}>
                        <View style={styles.col, { width: '90px' }} >
                            <Image src={(this.state.merchant != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                        </View>
                        <View style={styles.col} >
                            <Text style={styles.header}>{this.state.merchant?.name || ''}</Text>
                            {address?address.map((add, i) =>
                                <Text key={'address' + i} style={styles.address}>{add}</Text>
                            ):null}
                        </View>
                    </View>
                </View>
            </Fragment>
        );
        return html;
    }

    render() {
        const { dataPrint } = this.props
        if (dataPrint != null && this.state.fix == 0) {
            let row = dataPrint.data;
            this.state.data = row
            this.state.type = dataPrint.type
            this.state.header = dataPrint.header
            this.state.fix = 1;
        }
        return (
            dataPrint != null ?
                <React.Fragment >
                    <PDFViewer width={"100%"} height={"100%"} >
                        <Document title={this.state.title + ((dataPrint != undefined) ? '' : '')}>
                            <Page key={'itemStock'} wrap size={"A4"} orientation={"landscape"} style={styles.page}>
                                <View style={styles.body}>
                                    {this.renderHeader()}

                                    <View fixed style={styles.titleContainer}>
                                        <View style={styles.row}>
                                            <View style={styles.col} >
                                                <View style={styles.title}>
                                                    <Text>{i18n.t("Laporan Persediaan Barang (LAPE)")}</Text>
                                                </View>
                                                <View style={styles.title}>
                                                    <Text>{this.state.header.name_branch}</Text>
                                                </View>
                                                <View style={styles.title}>
                                                    <Text>{this.state.header.start_date + ' - ' + this.state.header.end_date}</Text>
                                                </View>
                                                <View style={styles.titleTranx}>
                                                    <Text>{i18n.t("Item Stock Good/TUD/ EXP ")}</Text>
                                                </View>
                                            </View>

                                        </View>
                                    </View>

                                    {this.renderMenu()}

                                    <View style={styles.tableTitle} >
                                        {this.state.type.length > 0 ? this.state.type.map((unit, o) =>
                                            <Fragment key={'dataType' + o}>
                                                <View style={styles.tr}  >
                                                    <View style={[styles.td, { width: '150px' }]} >
                                                        <Text style={styles.fillTextType}>{unit.type_name}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '50px' }]} >
                                                        <Text style={styles.fillTextUnit}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                    {/* <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View> */}
                                                    <View style={[styles.td, { width: '75px' }]} >
                                                        <Text style={styles.fillNumber}>{''}</Text>
                                                    </View>
                                                </View>

                                                {this.renderPage(unit.type_id)}


                                            </Fragment>)
                                            : null}
                                    </View>
                                    {this.renderTotal()}

                                </View>
                            </Page>


                            {dataPrint.dataPOSM.length > 0 ?
                                <Page key={'itemPOSM'} wrap size={"A4"} orientation={"landscape"} style={styles.page}>
                                    <View style={styles.body}>
                                        {this.renderHeader()}
                                        {/* <View fixed style={styles.titleHeader}>
                                 <View style={styles.row}>
                                     <View style={styles.col, { width: '90px' }} >
                                         <Image src={(this.state.merchant.logo != null) ? this.state.merchant.logo : "/assets/media/logos/logo.jpg"} style={styles.logo} />
                                    </View>
                                    <View style={styles.col, { width: '300px' }} >
                                         <Text style={styles.header}>{this.state.merchant.name}</Text>
                                        {address.map(a =>
                                             <Text style={styles.address}>{a}</Text>
                                         )}
                                    </View>
                                 </View>
                             </View> */}
                                        <View fixed style={styles.titleContainer}>
                                            <View style={styles.row}>
                                                <View style={styles.col} >
                                                    <View style={styles.title}>
                                                        <Text>{i18n.t("Laporan Persediaan Barang (LAPE)")}</Text>
                                                    </View>
                                                    <View style={styles.title}>
                                                        <Text>{this.state.header.name_branch}</Text>
                                                    </View>
                                                    <View style={styles.title}>
                                                        <Text>{this.state.header.start_date + ' - ' + this.state.header.end_date}</Text>
                                                    </View>
                                                    <View style={styles.titleTranx}>
                                                        <Text>{i18n.t("Item Stock POSM ")}</Text>
                                                    </View>
                                                </View>

                                            </View>
                                        </View>

                                        {this.renderMenu()}

                                        {/* <View fixed style={styles.tableTitle}  >
                                    <View style={styles.trHeader}>
                                        {menu.length > 0 ? menu.map((dataMenu, i) =>
                                            <Fragment key={'menuHeader' + i}>
                                                <View style={[styles.td, { width: dataMenu == 'Warehouse' || dataMenu == 'Memo' ? '150px' : dataMenu =='Unit'?'50px': dataMenu =='Item'?'150px':'75px' }]} >
                                                    <Text style={styles.menuHeader}>{dataMenu}</Text>
                                                </View>
                                            </Fragment>
                                        )
                                            : null}
                                    </View>
                                    <View style={styles.trHeader}>
                                        {submenu.length > 0 ? submenu.map((dataSubMenu, i) =>
                                            <Fragment key={'menuSub' + i}>
                                                <View style={[styles.td, { width: dataSubMenu=='Unit'?'50px':dataSubMenu=='Item'?'150px':'75px' }]} >
                                                    <Text style={styles.menuHeader}>{dataSubMenu=='Unit'||dataSubMenu=='Item'?'':dataSubMenu}</Text>
                                                </View>
                                            </Fragment>
                                        )
                                            : null}
                                    </View>
                                    <View style={styles.trHeader}>
                                        {submenu.length > 0 ? submenu.map((dataSubMenu, i) =>
                                            <Fragment key={'menuCount' + i}>
                                                <View style={[styles.td, { width: dataSubMenu=='Unit'?'50px':dataSubMenu=='Item'?'150px':'75px' }]} >
                                                    <Text style={styles.menuHeader}>{i + 1}</Text>
                                                </View>
                                            </Fragment>
                                        )
                                            : null}
                                    </View>
                                </View> */}

                                        <View style={styles.tableTitle} >
                                            {this.renderPOSM()}
                                        </View>
                                        {this.renderTotalPOSM()}

                                    </View>
                                </Page>
                                : null}
                        </Document>
                    </PDFViewer>

                </React.Fragment>
                :
                this.renderMessage()

        )
    }
}

export default PrintStockCard;

const loader = {
    alignItems: 'center',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    top: 0,
    transition: 'opacity 0.2s linear',
    width: '100%',
    opacity: 1,
    transform: 'opacity 1s linear',
}