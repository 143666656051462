import { useParams } from "react-router-dom";
import React from "react";
import ItemViewContainer from "../../../containers/modules/master/item/view";
export const ViewItem = (props)=>{
    let params=useParams();
    let id=params.id;
    // document.getElementById("root").setAttribute("style","overflow:hidden");
    return (
        <ItemViewContainer id={id}/>
        
    )
}

export default ViewItem;