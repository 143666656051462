import React, { Component, useState } from "react";
import Table from "../../../utilities/table";
import Breadcrumbs from "../../../utilities/breadcrumbs";
import { Button, Modal } from "react-bootstrap";
import FormContainer from "../../../../containers/modules/accounting/memo-in/form";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import withReactContent from "sweetalert2-react-content";
import TableMemoIn from "./table";
import LabelActive from "../../../utilities/Status/active";
import LabelActiveJournal from "../../../utilities/Status/journal";
import i18n from "../../../../i18n";
import { statusMemoIn } from "../../../utilities/Const/StatusJournal";
import TableJournal from "../journal/table";
const MySwal = withReactContent(Swal);
export class MemoIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        limit: 10,
      },
      titleModal: "",
      listData: [],
      breadcrumbs: [
        { link: process.env.APP_URL, title: i18n.t("Home"), class: " text-gray-600" },
        { link: null, title: i18n.t("Accounting"), class: " text-gray-600" },
        { link: null, title: i18n.t("Memo In"), class: " text-gray-500" },
      ],
      column: [
        {
          key: "no_journal",
          filter: { active: true, type: "Text", data: null },
          title: i18n.t("No. Journal"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "date_format",
          filter: { active: true, type: "Date", data: null },
          title: i18n.t("Journal Date"),
          type: "text",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "debt_format",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Debt"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "credit_format",
          filter: { active: false, type: "Text", data: null },
          title: i18n.t("Credit"),
          type: "text",
          tHclass: "min-w-150px",
          tdClass: "",
        },
        {
          key: "balance_name",
          filter: {
            active: false,
            type: "Select",
            data: [
              { key: "", label: i18n.t("Select Status") },
              { key: 1, label: i18n.t("Active") },
              { key: 0, label: i18n.t("Non Active") },
            ],
          },
          title: i18n.t("Balance"),
          type: "active",
          tHclass: "min-w-125px",
          tdClass: "",
        },
        {
          key: "status",
          filter: {
            active: true,
            type: "Select",
            data: statusMemoIn,
          },
          title: i18n.t("Status"),
          type: "component",
          tHclass: "min-w-125px",
          tdClass: "",
          component: (data, column) => {
            return <LabelActiveJournal value={data[column.key]} />;
          },
        },
        {
          key: "createdAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Created At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "updatedAt",
          filter: { active: false, type: "Select", data: null },
          title: i18n.t("Updated At"),
          type: "custom",
          tHclass: "text-center min-w-125px",
          tdClass: "",
        },
        {
          key: "id",
          filter: {
            active: false,
          },
          title: i18n.t("Action"),
          type: "action",
          tHclass: "text-center min-w-150px",
          tdClass: "text-center",
          form:'icon',
          buttons: [
            {
              key: "id",
              btnText: i18n.t("Approve"),
              visible: {
                key: "status",
                params: [0],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                this.approveData(data);
              },
              bthClass: "btn btn-warning",
            },
            {
              key: "id",
              btnText: i18n.t("Reject"),
              visible: {
                key: "status",
                params: [0],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                this.rejectData(data,'reject');
              },
              bthClass: "btn btn-warning",
            },
            {
              key: "id",
              btnText: i18n.t("Void"),
              visible: {
                key: "status",
                params: [1],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                this.rejectData(data,'void');
              },
              bthClass: "btn btn-warning",
            },
            {
              key: "id",
              btnText: i18n.t("Print"),
              visible: {
                key: "status",
                params: [1],
                function: (id, params) => {
                  return params.includes(id);
                },
              },
              btnFunction: (data, column) => {
                return false;
              },
              btnLink:"/accounting/memo-in/print/",
              bthClass: "btn btn-warning",
            },
          ],
        },
      ],
    };
    this.runSearch = this.runSearch.bind(this);
    this.updateData = this.updateData.bind(this);
    this.createData = this.createData.bind(this);
    this.cancelData = this.cancelData.bind(this);
    this.approveData = this.approveData.bind(this);
    this.rejectData = this.rejectData.bind(this);
  }
  runSearch(value) {
    this.state.submit = false;
    this.props.search(value);
  }
  createData() {
    this.state.titleModal = "Add Memo In";
    this.props.createData();
  }
  updateData(id) {
    this.state.titleModal = "Edit Memo In";
    this.props.updateItem(id);
  }
  cancelData(id) {
    const html = (
      <>
        <div>{i18n.t("You will void this Item")}</div>
        <Button variant="warning" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            this.props.deleteItem(id, this.state.search);
            MySwal.update({
              title: i18n.t("Void Data"),
              html: i18n.t("Please Wait ...."),
              icon: i18n.t("info"),
            });
            MySwal.showLoading();
          }}
        >
          {i18n.t("Accept")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: i18n.t("question"),
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  rejectData(id,type) {
    const html = (
      <>
        <div>{type=='reject'?i18n.t("Reject This Data"):i18n.t("Void This Data")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.rejectItem(id, value,type, this.state.search);
              }
            });
          }}
        >
          {i18n.t(type=='reject'?"Reject":"Void")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
  
  approveData(id) {
    const html = (
      <>
        <div>{i18n.t("Approve This Memo In")}</div>
        <Button variant="secondary" onClick={() => MySwal.close()}>
          {i18n.t("Cancel")}
        </Button>
        <Button
          variant="success"
          onClick={() => {
            MySwal.fire({
              title: i18n.t("Enter your message"),
              icon: false,
              input: "textarea",
              inputValue: "",
              showCancelButton: true,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              inputValidator: (value) => {
                if (!value) {
                  return i18n.t("You need to write something!");
                }
              },
            }).then((result) => {
              if (result.isConfirmed) {
                const value = result.value;
                MySwal.fire({
                  title: i18n.t("Proccessing Data"),
                  html: i18n.t("Please Wait ...."),
                  icon: "info",
                });
                MySwal.showLoading();
                this.props.approveItem(id, value, this.state.search);
              }
            });
          }}
        >
          {i18n.t("Approve")}
        </Button>
      </>
    );
    MySwal.fire({
      title: i18n.t("Are you sure?"),
      html: html,
      icon: "question",
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }

  submitForm(status) {
    this.setState({ submit: status });
  }
  render() {
    const { isProcessing, data, showModal } = this.props;
    this.state.listData = data;
    return (
      <div id="customer-type">
        <Breadcrumbs url={this.state.breadcrumbs} tools={false} />
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start container-fluid"
        >
          <div className="content flex-row-fluid" id="kt_content">
            <div className="card">
              <div className="card-header d-flex justify-content-end border-0 pt-6">
                <div className="card-toolbar">
                  <div
                    className="d-flex justify-content-end"
                    data-kt-customer-table-toolbar="base"
                  >
                    <Button
                      type="button"
                      className="btn btn-warning m-1"
                      onClick={(e) => this.runSearch(this.state.search)}
                    >
                      <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
                    </Button>
                    <Button
                      type="button"
                      className="btn btn-primary m-1"
                      onClick={(e) => this.createData()}
                    >
                      {i18n.t("Add Memo In")}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body pt-0">
                <TableJournal
                  // checkAble={false}
                  numberAble={true}
                  expandAble={true}
                  theExpand={{
                    key: "journal_detail",
                    columns: [
                      { label: i18n.t("Ledger"), key: "name_coa3" },
                      { label: i18n.t("Helper Book"), key: "name_coa_help" },
                      {
                        label: i18n.t("Debt"),
                        key: "debt_format",
                        key2: "total_debt_format",
                      },
                      {
                        label: i18n.t("Credit"),
                        key: "credit_format",
                        key2: "total_credit_format",
                      },
                      { label: i18n.t("Description"), key: "description" },
                    ],
                  }}
                  data={this.state.listData}
                  tools={false}
                  filterSearch={true}
                  column={this.state.column}
                  searchData={this.state.search}
                  runSearch={this.runSearch}
                  deleteData={this.deleteData}
                  updateData={this.updateData}
                  isProcessing={isProcessing}
                />
              </div>
            </div>
          </div>
        </div>
        <FormContainer
          showModal={showModal}
          search={this.state.search}
          titleModal={this.state.titleModal}
        />
      </div>
    );
  }
}

export default MemoIn;
